import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paragraph, Text } from '../../../assets/Text'
import GetImg from '../../../assets/GetImg'
import { Divider } from '../../../assets/UIElements'
import { TextInput } from '../../../assets/Form'
import { Icon } from '../../../assets/Buttons'
import ProfileCard from './ProfileCard'
import SupportiveMessages from './SupportiveMessages'
import UserMatchHelper from '../../../helpers/UserMatchHelper'
import UserInteractionHelper from '../../../helpers/UserInteractionHelper'
import AuthenticationHelper from '../../../helpers/AuthenticationHelper'
// import GH from '../../../helpers/GeneralHelper'

class Stories extends Component {
    _isMounted = false

    constructor(props)
    {
        super(props)
        this.state = {
            initComplete: false
        }
    }

    /*
    add parameter connected, hugged, supportMsgTime, supportMsg
    */
    getProcessedInteractionList(recUserList,contactList,userHugsList,supportMsgList){
        //loop through each recUser
        let processedList=[]
        // console.log(contactList)
        recUserList.recUser.forEach( (item,index) =>
        {
            let tempObj= item
            //by default, put everything as false first, in case no data for contact list, huglist, supportmsg list exists
            tempObj.hugged =false
            tempObj.connected =false
            tempObj.supportMsgTime = new Date()
            tempObj.supportMsg = ""

            if(userHugsList.hasOwnProperty('data') && userHugsList.data !== null && userHugsList.data.length > 0){
                userHugsList.data.findIndex(x => x.reciever_id ===item.id) === -1? 
                tempObj.hugged =false :  tempObj.hugged =true
            }

            if(contactList.hasOwnProperty('data') && contactList.data !== null  && contactList.data.length > 0 ) {
                contactList.data.findIndex(x => x.reciever_id ===item.id) === -1? 
                tempObj.connected =false :  tempObj.connected =true
            }

            if(supportMsgList.hasOwnProperty('data') && supportMsgList.data !== null && supportMsgList.data.length > 0){
                let supportMsgIndex=supportMsgList.data.findIndex(x => x.reciever_id ===item.id)   
                if(supportMsgIndex !==-1)
                { 
                  tempObj.supportMsgTime = new Date(supportMsgList.data[supportMsgIndex].sent_time)
                  tempObj.supportMsg = supportMsgList.data[supportMsgIndex].message 
                }
                else
                {
                  tempObj.supportMsgTime = new Date()
                  tempObj.supportMsg = ""
                }
            

            }
            processedList[index]=tempObj
            
        })

        return processedList

    }

    async componentWillMount()
    {
        this._isMounted = true
        //optimized solution
        const recUserListOriginal = await UserMatchHelper.getRecProfile(AuthenticationHelper.uuid, 5) 
        const userHugsList= await UserInteractionHelper.getHugsSentByUserId(AuthenticationHelper.uuid)
        const contactList= await UserInteractionHelper.getContactSentByUserId(AuthenticationHelper.uuid)
        const supportMsgList= await UserInteractionHelper.getSupportMsgSentByUserId(AuthenticationHelper.uuid)      

        //console.log(userHugsList)
        //console.log(contactList)
        //console.log(supportMsgList)

        
        if(recUserListOriginal.hasOwnProperty('recUser'))
        {
            let newCards= this.getProcessedInteractionList(recUserListOriginal,contactList,userHugsList,supportMsgList)
            //console.log(newCards)
            await this.props.updateMatches(newCards)
            if(this._isMounted)
            {
                return this.setState({
                    initComplete: true
                })
            }
            // return
        }
        
        // console.log(AuthenticationHelper.uuid)
        /*const recUserList = await UserMatchHelper.getRecProfile(AuthenticationHelper.uuid, 10)
        let recUser = recUserList.recUser
        if(recUserList.hasOwnProperty('recUser'))
        {
            // loop through the 10 recommended users and get if they have been hugged or connected before
            let newCards = []
            const loopCards = await GH.asyncForEach(recUser, async (card, idx) => {
                const cardId = card.id
                let hugged = false, supportMsg = '', connected = false, supportMsgTime = new Date()
                const userHugs= await UserInteractionHelper.getHugsRecievedByUserId(cardId)
                // console.log(userHugs)
                if(userHugs.hasOwnProperty('data') && userHugs.data !== null && userHugs.data.length > 0)
                {
                    const checkHugged = await GH.asyncForEach(userHugs.data, async h => {
                        // console.log(h)
                        if(h.sender_id === AuthenticationHelper.uuid)
                        {
                            // console.log("sender id found")
                            hugged = true
                        }
                        return 
                    })
                }

                const userSupportMsg= await UserInteractionHelper.getSupportMsgRecievedByUserId(cardId)
                if(userSupportMsg.hasOwnProperty('data') && userSupportMsg.data !== null && userSupportMsg.data.length > 0)
                {
                    const checkSupportMsg = await GH.asyncForEach(userSupportMsg.data, async m => {
    
                        if(m.sender_id === AuthenticationHelper.uuid)
                        {
                            supportMsg = m.message
                            supportMsgTime = new Date(m.sent_time)
                            // console.log(m)
                        }
                        return
                    })
                }

                const userContacts= await UserInteractionHelper.getContactByUserId(cardId)
                if(userContacts.hasOwnProperty('data') && userContacts.data !== null  && userContacts.data.length > 0)
                {
                    const checkConnected = await GH.asyncForEach(userContacts.data, async c => {
    
                        if(c.sender_id === AuthenticationHelper.uuid)
                        {
                            connected = true
                        }
                        return
                    })
                }

                recUser[idx]['hugged'] = hugged
                recUser[idx]['supportMsg'] = supportMsg
                recUser[idx]['connected'] = connected
                recUser[idx]['supportMsgTime'] = supportMsgTime

                newCards[idx] = recUser[idx]
                return 
            })
            console.log(newCards)
            await this.props.updateMatches(newCards)
            return this.setState({
                initComplete: true
            })
        }
        */

        if(this._isMounted)
        {
            return this.setState({
                initComplete: true
            })
        }
        // return
        // this.props.updateMatches([])
        // console.log(recUserList)
        // console.log(this.props.profile)
    }

    componentWillUnmount()
    {
        this._isMounted = false
    }

    render()
    {
        let lang = this.props.lang.support
        if(!this.props.hasOwnProperty('matches') || this.props.matches.length <= 0)
        {
            return <Grid item className='my-4'>
                <Paragraph className='fyw-font-dark'>{lang.noStories}</Paragraph>
            </Grid>
        }
        let profiles = []
        if(!this.state.initComplete)
        {
            this.props.matches.map((match, idx) => {
                profiles.push(<ProfileCard 
                    key={`match-stories-${idx}`}
                    profile={match}
                    className='mb-4'
                    component={<CircularProgress />}
                    lang={this.props.lang.profile}
                />)
            })

        }
        else
        {
            this.props.matches.map((match, idx) => {
                profiles.push(<ProfileCard 
                    key={`match-stories-${idx}`}
                    profile={match}
                    className='mb-4'
                    component={<SupportInteractionPanel 
                        profile={this.props.profile}
                        match={match}
                        initComplete={this.state.initComplete}
                        lang={this.props.lang.support}
                    />}
                    lang={this.props.lang.profile}
                />)
            })
        }

        // console.log(this.props)
        return (<Grid item className='my-4'>
            <Paragraph className='fyw-font-dark'>{lang.storyIntro}</Paragraph>
            {profiles}
        </Grid>)
    }
}

// manage the interaction panel to the level of component to enhance the performance of the website
class SupportInteractionPanel extends Component {

    constructor(props)
    {
        super(props)

        this.state = {
            hugged: this.props.match.hugged,
            connected: this.props.match.connected,
            supportMsg: this.props.match.supportMsg,
            supportMsgTime: new Date(this.props.supportMsgTime)
        }
        
        this.updateHug = this.updateHug.bind(this)
        this.updateConnect = this.updateConnect.bind(this)
        this.updateSupportMsg = this.updateSupportMsg.bind(this)
    }

    async updateHug()
    {
        try
        {
            const matchId = this.props.match.id
            // console.log(`hugged ${this.state.hugged}`)
            if(!this.state.hugged)
            {
                const addHugresult = await UserInteractionHelper.addHug(AuthenticationHelper.uuid, matchId)
                // console.log(addHugresult)
                if(addHugresult.hasOwnProperty('status') && addHugresult.status === 'success')
                {
                    this.setState({
                        hugged: true
                    })
                    return true
                }
                return false
            }

            const removeHugresult = await UserInteractionHelper.removeHug(AuthenticationHelper.uuid, matchId)
            // console.log(removeHugresult)
            if(removeHugresult.hasOwnProperty('status') && removeHugresult.status === 'success')
            {
                this.setState({
                    hugged: false
                })
                return true
            }
            return false

        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    async updateConnect()
    {
        try
        {
            const matchId = this.props.match.id

            if(!this.state.connected)
            {
                const addContactResult = await UserInteractionHelper.addContact(AuthenticationHelper.uuid, matchId)
                const addChatResult = await UserInteractionHelper.addChatRoom(AuthenticationHelper.uuid, matchId) // also add the chatroom
                if(addContactResult.hasOwnProperty('status') && addContactResult.status === 'success') //note, I didn't check the error control for the chat room as you can have more than one 
                {
                    this.setState({
                        connected: true
                    })
                    return true
                }
                return false
            }
            
            const removeContactResult = await UserInteractionHelper.removeContact(AuthenticationHelper.uuid, matchId)
            if(removeContactResult.hasOwnProperty('status') && removeContactResult.status === 'success')
            {
                this.setState({
                    connected: false
                })
                return true
            }
            return false

        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    async updateSupportMsg(comment)
    {
        try
        {
            const matchId = this.props.match.id

            if(comment !== '')
            {
                const addSupportMsgResult = await UserInteractionHelper.addSupportMsg(AuthenticationHelper.uuid, matchId, comment)
                // console.log(addSupportMsgResult)
                
                if(addSupportMsgResult.hasOwnProperty('status') && addSupportMsgResult.status === 'success')
                {
                    this.setState({
                        supportMsg: comment,
                        supportMsgTime: new Date()
                    })
                }
                return
            }

            const removeSupportMsgResult = await UserInteractionHelper.removeSupportMsg(AuthenticationHelper.uuid, matchId)
            // console.log(addSupportMsgResult)
            
            if(removeSupportMsgResult.hasOwnProperty('status') && removeSupportMsgResult.status === 'success')
            {
                this.setState({
                    supportMsg: '',
                    supportMsgTime: new Date()
                })
            }
            return

        }
        catch(e)
        {
            console.log(e)
        }
        // const addSupportMsgResult = await UserInteractionHelper.addSupportMsg(AuthenticationHelper.uuid, targetId, comment)
        // console.log(addSupportMsgResult)
    }

    render()
    {
        // console.log(this.props)
        // console.log("rendered")
        // console.log(this.state.hugged)
        return (<>
        <InteractionPanel 
            name={this.props.match.display_name}
            sendHug={this.updateHug}
            connect={this.updateConnect}
            hugged={this.state.hugged || false}
            connected={this.state.connected || false}
            lang={this.props.lang}
        />
        <Grid item xs={10} className='mx-auto'><Divider /></Grid>
        <LeaveComment 
            name={this.props.match.display_name}
            user={this.props.profile.display_name}
            supportMsg={this.props.match.supportMsg}
            supportMsgTime={this.props.match.supportMsgTime || new Date()}
            sendSupportMsg={this.updateSupportMsg}
            lang={this.props.lang}
        />
        </>)
    }
}

const InteractionPanel = props => {
    //console.log(props)
    const [hugged, updateHug] = useState(props.hugged)
    const [connected, updateConnect] = useState(props.connected)

    return (<Grid item xs={10} className='d-flex justify-content-between mx-auto'>
        <div className='match-interaction-btn cursor-pointer p-2' tabIndex={0} role="button" onClick={async (e) => {
            e.preventDefault()
            updateHug(!hugged)
            const result = await props.sendHug()
            if(!result)
            {
                updateHug(!hugged)
            }
        }}>
            <Paragraph className='text-left'>
                <GetImg 
                    className={['match-icon-sm']}
                    onClick={null}
                    imageUrl={hugged ? 'match-hug-clicked.svg' : 'match-hug.svg'} // "name-of-the-img.jpg"
                    alt={'hug'}
                />

                {hugged ? <span className='ml-2'>{props.name !== null || props.name !== '' ? `${props.lang.hugged} ${props.name}`: props.lang.hugSent}</span>
                :<span className='ml-2'>{props.name !== null || props.name !== '' ? `${props.lang.hug} ${props.name}`: props.lang.sendHug}</span>}

            </Paragraph>
        </div>
        
        <div className='match-interaction-btn cursor-pointer text-center p-2' tabIndex={0} role="button" onClick={async (e) => {
            // console.log('test')
            e.preventDefault()
            updateConnect(!connected)
            const result = await props.connect()
            if(!result)
            {
                updateConnect(!connected)
            }
        }}>
            <Paragraph className='text-right'>
                <GetImg 
                    onClick={null}
                    className={['match-icon-sm', 'cursor-pointer']}
                    imageUrl={connected ? 'match-confirm-connect-clicked.svg':'match-confirm-connect.svg'} // "name-of-the-img.jpg"
                    alt={'confirm connect'}
                />

                {connected ? <span className='ml-2'>{props.name !== null || props.name !== '' ? `${props.lang.connectedTo} ${props.name}` : props.lang.connected}</span>
                :<span className='ml-2'>{props.name !== null || props.name !== '' ? `${props.lang.connectTo} ${props.name}` : props.lang.connect}</span>}
                
            </Paragraph>
        </div>
    </Grid>)
}

const LeaveComment = props => {
    // console.log(props)
    const initMsg = props.supportMsg !== '' ? props.supportMsg : ''
    const [comment, updateComment] = useState(initMsg)
    const [commentError, updateCommentError] = useState('')
    const [msgSent, updateMsgSent] = useState(initMsg !== '')

    if(!msgSent)
    {
        return (<>
            <Grid item xs={10} className='d-flex mx-auto my-3'>
    
            <Grid item xs={2}>
                <div className='match-avatar-sm mx-auto'>
                    <Text className='p-0 m-0'>{props.user.length > 1 ? `${props.user[0].toUpperCase()}${props.user[1].toUpperCase()}`:`${props.user[0].toUpperCase()}`}</Text>
                </div>
            </Grid>
    
            {/*<Grid item xs={10} className='supportive-message-bubble p-3'>
                <Text className='p-0 m-0'>{props.message}</Text>
            </Grid>*/}
    
            <Grid item xs={10}>
                <TextInput 
                    // ref={password => this._password = password}
                    type={"text"}
                    label={props.name !== null ? `${props.lang.leaveMessageTo} ${props.name}`: props.lang.leaveMessage}
                    ariaLabel='Supportive message'
                    placeholder={props.name !== null ? `${props.lang.supportMessageFor} ${props.name}`: props.lang.supportMessage}
                    // icon='envelope'
                    withAdornment
                    endAdornmentIcon={'paper-plane'}
                    endAdornmentLabel='send'
                    handleEndAdornmentClick={() => {
                        props.sendSupportMsg(comment)
                        updateMsgSent(true)
                    }} 
                    error={commentError}
                    helperText={`${comment.length}/250 ${props.lang.words}`}
                    onChange={e => {
                      // console.log(e.target.value.length)
                      if(e.target.value.length > 250)
                      {
                        updateCommentError(`${props.lang.exceeds} ${comment.length}/250 ${props.lang.words}`)
                        updateComment(e.target.value)
                        return
                      }
                      updateCommentError('')
                      updateComment(e.target.value)
                    }}
                />
            </Grid>
    
            </Grid>
            
        </>)
    }
    return (<Grid item xs={12}>
        <SupportiveMessages display_name={props.user} message={comment} time={props.supportMsgTime} enableDelete handleDelete={() => {
            props.sendSupportMsg('')
            updateMsgSent(false)
        }} />
    </Grid>)
}

export default Stories