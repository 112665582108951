import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Text, Title, Paragraph } from '../../../assets/Text'
import GH from '../../../helpers/GeneralHelper'
import CircularProgress from '@material-ui/core/CircularProgress'


/**
 * usage:
    <ProfileCard 
        display_name=''
        age=''
        gender=''
        state_address=''
        relationship=''
        loss_description=''
        component={}
    />
 */
const ProfileCard = props => {
    if(!props.hasOwnProperty('profile') || !props.profile.hasOwnProperty('display_name') 
    || !props.profile.hasOwnProperty('age') || !props.profile.hasOwnProperty('gender')
    || !props.profile.hasOwnProperty('state_address') || !props.profile.hasOwnProperty('relationship')
    || !props.profile.hasOwnProperty('loss_description'))
    {
        return <CircularProgress />
    }
    return (
    <Grid container className={props.className ? `py-4 py-sm-0 match-paper ${props.className}`:'py-2 py-sm-0 match-paper'}>
        {props.topComponent !== null ? props.topComponent:null}
        <Grid item xs={12} sm={4} className='d-flex justify-content-center flex-column text-center py-md-3'>
        
            <div className='match-avatar mx-auto'>
                <Title className='p-0 m-0'>{props.profile.display_name.length > 1 ? `${props.profile.display_name[0].toUpperCase()}${props.profile.display_name[1].toUpperCase()}`:`${props.profile.display_name[0].toUpperCase()}`}</Title>
            </div>
            <div className='mt-3 px-5'>
                <Paragraph className='fyw-font-grey mx-auto'>{props.profile.display_name}</Paragraph>
            </div>
        </Grid>

        <Grid item xs={12} sm={8} className='py-md-5 px-2 px-sm-0 pr-md-5'>
            <Grid>
                <Text className='fyw-font-grey m-0'>{`${props.profile.age}, ${GH.capitalizeFirstLetter(props.profile.gender)}, ${props.profile.state_address}`}</Text>
                <Text className='fyw-font-grey m-0'>{`${props.lang.lostRelationship}: ${props.profile.relationship}`}</Text>
            </Grid>
            <Grid><Text className='fyw-font-dark fyw-font-bold m-0 mt-4'>{props.lang.myStory}</Text></Grid>
            <Grid><Paragraph className='fyw-font-dark'>{`${props.profile.loss_description}`}</Paragraph></Grid>
        </Grid>
        
        {props.component ? props.component : null}

    </Grid>)
}

export default ProfileCard