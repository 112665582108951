import React from 'react'
import GetImg, { GameSVGImage } from './GetImg'
import { Paragraph } from './Text'

/**
 * usage:
    import { Figure } from '../assets/Figure'
    <Figure src="map-background-md.jpeg" srcSet={{
        sm: "map-background-sm.jpeg",
        md: "map-background-md.jpeg",
        lg: "map-background-lg.jpeg",
        xl: "map-background-xl.jpeg"
    }} alt="" className="" />
 */
const Figure = (props) => {
    if(!props.noCaption)
    {
        return(
            <div className={`card ${props.className}`} style={props.style}>
                <GetImg className={["card-img-top"]} imageUrl={props.src} alt={props.alt} srcSet={props.srcSet} />
                <div className="card-body">
                    <Paragraph className="card-text fyw-font-dark">
                        {props.children}
                    </Paragraph>
                </div>
            </div>
        )
    }
    return(
        <div className={`card ${props.className}`} style={props.style}>
            <GetImg className={["card-img-top"]} imageUrl={props.src} alt={props.alt} srcSet={props.srcSet} />
        </div>
    )
}

const SVGFigure = (props) => {
    return(
        <GameSVGImage 
            className={["svg-img"]} 
            imageUrl={props.src}
            alt={props.alt} 
            coord={props.coord} 
            width={props.width}
            height={props.height}
        />
    )
}

export { Figure, SVGFigure }