import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const initState = (localStorage['redux-store']) ? JSON.parse(localStorage.getItem('redux-store')) : {}
export default () => {
  let store = createStore(rootReducer, initState, composeEnhancers(applyMiddleware(thunk)))
  return { store }
}