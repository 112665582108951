import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { TextInput } from '../../../assets/Form'
import { Paragraph, Text } from '../../../assets/Text'
import { Button } from '../../../assets/Buttons'
import { Alert } from '../../../assets/UIElements'
import GetImg from '../../../assets/GetImg'
import GH from '../../../helpers/GeneralHelper'

const Login = props => {
    // console.log(props)
    const [hidePass, changeHide] = useState(true)
    const [email, changeEmail] = useState('')
    const [password, changePassword] = useState('')
    const [loginError, updateLoginError] = useState('')

    // use React hook to add event listener for enter event.
    // useEffect(() => {
    //     // if(document.getElementById('match-login-form') !== null)
    //     // {
    //     //     document.getElementById('match-login-form').addEventListener('keydown', handleSubmit)
    //     // }

    //     return () => {
    //         // if(document.getElementById('match-login-form') !== null && document.getElementById('match-login-form').removeEventListener === 'function')
    //         // {
    //         //     document.getElementById('match-login-form').removeEventListener('keydown', handleSubmit)
    //         // }
    //     }
    // })

    const loginFunction = async () => {
        const result = await props.login(GH.removeWhiteSpace(email), GH.removeWhiteSpace(password))
        if(!result) return updateLoginError(props.lang.loginError)
    }

    const handleSubmit = async (e) => {
        // e.preventDefault()
        if(e.key === 'Enter' || e.keyCode === 13)
        {
            return loginFunction()
        }
        // return e.preventDefault()
    }

    const cardItems = {
        writeStory: {
            title: props.lang.cards.write,
            srcSet: {
                sm: 'stories-sm.jpg',
                md: 'stories-md.jpg',
                lg: 'stories-lg.jpg',
                xl: 'stories-xl.jpg'
            },
            alt: props.lang.cards.write
        },
        receiveSupport: {
            title: props.lang.cards.receive,
            srcSet: {
                sm: 'support-sm.jpg',
                md: 'support-md.jpg',
                lg: 'support-lg.jpg',
                xl: 'support-xl.jpg'
            },
            alt: props.lang.cards.receive
        },
        connect: {
            title: props.lang.cards.build,
            srcSet: {
                sm: 'connect-sm.jpg',
                md: 'connect-md.jpg',
                lg: 'connect-lg.jpg',
                xl: 'connect-xl.jpg'
            },
            alt: props.lang.cards.build
        }
    }

    let cards = []
    Object.keys(cardItems).map((k, i) => {
        const targetItem = cardItems[k]
        cards.push(<CardItem key={`match-card-${i}`} {...targetItem} />)
    })
    return <Grid container className='mt-3 mx-auto d-flex'>
        <Grid item xs={12} sm={10} md={8} className='mx-auto d-flex align-items-start'>
            {cards}
        </Grid>

        <Grid item xs={12} sm={6} md={8} className='mx-auto mt-5'>
            <Grid className='mx-auto d-flex justify-content-center'>
                <Paragraph className='fyw-font-dark fyw-font-bold'>{props.lang.login}</Paragraph>
            </Grid>
            <Grid item xs={11} sm={11} md={8} className='mx-auto' id='match-login-form'>
            <form onKeyPress={handleSubmit}>
                <TextInput 
                    id='match-username'
                    // ref={pass => this._password = pass}
                    type={"text"}
                    label={props.lang.emailAddress}
                    ariaLabel='email address'
                    icon='envelope'
                    withAdornment={false}
                    placeholder={props.lang.yourEmail}
                    onChange={e => changeEmail(e.target.value)}
                />
                <TextInput 
                    id='match-password'
                    // ref={pass => this._password = pass}
                    type={hidePass ? "password" : "text"}
                    label={props.lang.passwordLabel}
                    ariaLabel='password'
                    icon='lock'
                    endAdornmentIcon={hidePass ? "eye-slash" : "eye"}
                    endAdornmentLabel={props.lang.togglePassVisibility}
                    withAdornment={true}
                    handleEndAdornmentClick={() => changeHide(!hidePass)}
                    placeholder={props.lang.yourPassword}
                    onChange={e => changePassword(e.target.value)}
                />
                <Button className='mx-auto mt-4' onClick={loginFunction}
                >{props.lang.loginSubmit}</Button>
                {loginError !== '' ? <Grid className='w-100 d-flex justify-content-center'>
                    <Alert><Text className='fyw-font-dark mx-auto'>{loginError}</Text></Alert>
                </Grid>
                :null}
            </form>
            <Grid className='mx-auto d-flex justify-content-center flex-column'>
                <Text className='fyw-font-dark mt-5 mx-auto'><span className='href-link' tabIndex="0" aria-label='forgot password' role='link' onClick={props.switchToForgotPass}>{props.lang.forgotPass}</span></Text>
                <Text className='fyw-font-dark mx-auto'>{props.lang.notMember}
                    <span className='href-link ml-2 fyw-font-bold' tabIndex="0" aria-label='join now' role='link' onClick={() => {
                        props.switchToLogin(false)
                    }}>{props.lang.joinNow}</span>
                </Text>
            </Grid>
            </Grid>

        </Grid>
    </Grid>
}

const CardItem = props => {
    return (<Grid item xs={4} sm={4} className='d-flex justify-content-center'>
        <Card className='match-card shadow'>
            <CardActionArea>
            <CardContent className='d-flex justify-content-center py-2' style={{minHeight: '40px' }}>
                <Paragraph className='fyw-font-dark mx-auto text-center' style={{ position:'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                {props.title.toUpperCase()}
                </Paragraph>
            </CardContent>
            <GetImg
                className={['match-card-img', 'w-100']}
                onClick={() => null}
                imageUrl={props.srcSet.md} // "name-of-the-img.jpg"
                srcSet={props.srcSet}
                alt={props.alt}
            />
            </CardActionArea>
        </Card>
    </Grid>)
}

export default Login