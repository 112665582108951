import AuthRequestHelper from './AuthRequestHelper'


class UserInteractionHelper{

    /*contact interaction functions  */
    async getContactByUserId(id){
        try{
            const requestUrl="interaction/contact/reciever/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }

    }

    async getContactSentByUserId(id){
        try{
            const requestUrl="interaction/contact/sender/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }

    }

    async addContact(senderId,recieverId){
       try{
            const requestUrl="interaction/contact/sender/"+senderId
            const addContactParams={
                "reciever_id": recieverId,
                "contact_state":"1"
            }
            return await AuthRequestHelper.request("put",requestUrl, addContactParams)
        }
        catch(error){
            console.log(error)
            return{}
        }
    }

    //when adding contact, you also need to create a chat room between the two pairs
    //note, when adding a sample user a chatroom will not be created as they do not have an existing profile on rocketchat
    async addChatRoom(senderId,recieverId){
        try{
             const requestUrl="chatroom/"+senderId
             const addContactParams={
                 "contactee_id": recieverId
             }
             return await AuthRequestHelper.request("put",requestUrl, addContactParams)
         }
         catch(error){
             console.log(error)
             return{}
         }
     }    


    async removeContact(senderId,recieverId){
        try{
            const requestUrl="interaction/contact/sender/"+senderId
            const removeContactParams={
                "reciever_id": recieverId
            }
            return await AuthRequestHelper.request("delete",requestUrl, removeContactParams)
        }
        catch(error){
            console.log(error)
            return{}
        }
    }

    /*support message interaction functions  */
    async getSupportMsgRecievedByUserId(id){
        try{
            const requestUrl="interaction/supportmsg/reciever/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }

    }

    async getRecievedSupportMsgNoByUserId(id){
        try{
            let supportMsgs=await this.getSupportMsgRecievedByUserId(id)
            return supportMsgs.data.length
        }
        catch(error){
            console.log(error)
            return null
        }
    }

    async getSupportMsgSentByUserId(id){
        try{
            const requestUrl="interaction/supportmsg/sender/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }

    }

    async addSupportMsg(senderId,recieverId,message){
        try{
            const requestUrl="interaction/supportmsg/sender/"+senderId
            const addContactParams={
                "reciever_id": recieverId,
                "message":message
            }
            return await AuthRequestHelper.request("put",requestUrl, addContactParams)
        }
        catch(error){
            console.log(error)
            return{}
        }     
    }

    async removeSupportMsg(senderId,recieverId){
        try{
            const requestUrl="interaction/supportmsg/sender/"+senderId
            const removeSupportMsgParams={
                "reciever_id": recieverId
            }
            return await AuthRequestHelper.request("delete",requestUrl, removeSupportMsgParams)
        }
        catch(error){
            console.log(error)
            return{}
        }   
    }    

    /*hugs interaction functions  */
    async getHugsRecievedByUserId(id){
        try{
            const requestUrl="interaction/hug/reciever/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }
    }

    async getHuggedNoByUserId(id){
        try{
            let hugs=await this.getHugsRecievedByUserId(id)
            return hugs.data.length
        }
        catch(error){
            console.log(error)
            return null
        }
    }


    async getHugsSentByUserId(id){
        try{
            const requestUrl="interaction/hug/sender/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }
    }

    async addHug(senderId,recieverId){
        try{
            const requestUrl="interaction/hug/sender/"+senderId
            const addContactParams={
                "reciever_id": recieverId
            }
            return await AuthRequestHelper.request("put",requestUrl, addContactParams)
        }
        catch(error){
            console.log(error)
            return{}        
        }
    }

    async removeHug(senderId,recieverId){
        try{
            const requestUrl="interaction/hug/sender/"+senderId
            const removeHugParams={
                "reciever_id": recieverId
            }
            return await AuthRequestHelper.request("delete",requestUrl, removeHugParams)
        }
        catch(error){
            console.log(error)
            return{}        
        }
    }

}

export default new UserInteractionHelper();