import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Divider } from '../../../assets/UIElements'
import { Paragraph, Tip } from '../../../assets/Text'
import { Icon } from '../../../assets/Buttons'
import { ConfirmBox } from '../../../assets/Feedback'
import GetImg from '../../../assets/GetImg'
import ProfileCard from './ProfileCard'
import ResetPass from '../Auth/ResetPass'
import EditProfile from '../Auth/EditProfile'
import SupportiveMessages from './SupportiveMessages'
import AuthenticationHelper from '../../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../../helpers/UserProfileHelper'
import UserInteractionHelper from '../../../helpers/UserInteractionHelper'

class Profile extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            modalComponent: null
        }
        this.initEditProfile = this.initEditProfile.bind(this)
        this.initResetPass = this.initResetPass.bind(this)
    }

    async componentWillMount()
    {
        // console.log()
        try
        {

            const profile = await UserProfileHelper.getProfileById(AuthenticationHelper.uuid)
            const authProfile = await UserProfileHelper.getAuthProfileById(AuthenticationHelper.uuid)
            // console.log(authProfile)
            // console.log(profile)
            if(profile.hasOwnProperty('data'))
            {
                let profileData = profile.data
                if(authProfile.hasOwnProperty('status') && authProfile.status === 'success' && authProfile.hasOwnProperty('data'))
                {
                    profileData['email'] = authProfile.data.username
                }
                await this.props.updateProfile(profileData)
            }

            // check if user is resetting password
            const status= await UserProfileHelper.getRoleById(AuthenticationHelper.uuid)
            // console.log(status)
            if (Object.keys(status).length > 0 && status.hasOwnProperty('data') && status.data.status ===2)
            {
                this.initResetPass()
            }

            // console.log(profile)
            const userHugs= await UserInteractionHelper.getHugsRecievedByUserId(AuthenticationHelper.uuid)
            if(userHugs.hasOwnProperty('data'))
            {
                await this.props.updateHugs(userHugs.data)
            }
            const userSupportMsg= await UserInteractionHelper.getSupportMsgRecievedByUserId(AuthenticationHelper.uuid)
            if(userSupportMsg.hasOwnProperty('data'))
            {
                // console.log(userSupportMsg)
                await this.props.updateSupportMessages(userSupportMsg.data)
            }
        }
        catch(e)
        {
            // console.log(e)
        }
    }

    initEditProfile()
    {
        this.setState({
            modalComponent: <EditProfile updateProfile={this.props.updateProfile} profile={this.props.profile} />
        }, () => {
            this.props.initConfirmMessage({
                title: this.props.lang.profile.editProfile,
                value: this.props.lang.profile.editProfileWarning
            })
        })

    }

    initResetPass()
    {
        this.setState({
            modalComponent: <ResetPass email={this.props.profile.email} lang={this.props.lang.profile} />
        }, () => {
            this.props.initConfirmMessage({
                title: this.props.lang.profile.resetPass,
                value: this.props.lang.profile.resetPassWarning
            })
        })
    }

    render()
    {
        if(!this.props.profile.hasOwnProperty('display_name') || !this.props.profile.hasOwnProperty('age')
        || !this.props.profile.hasOwnProperty('gender') || !this.props.profile.hasOwnProperty('state_address')
        || !this.props.profile.hasOwnProperty('relationship') || !this.props.profile.hasOwnProperty('loss_description')
        )
        {
            return <CircularProgress />
        }
        // console.log(this.props.hugs)
        let lang = this.props.lang.profile
        let supportiveMessages = []
        if(this.props.hasOwnProperty('supportiveMessages') && this.props.supportiveMessages !== null)
        {
            this.props.supportiveMessages.map((msg, idx) => {
                supportiveMessages.push(<SupportiveMessages key={`match-supportive-messages-${idx}`} display_name={msg.sender_display_name} message={msg.message} time={msg.sent_time} />)
            })
        }
        return <Grid item className='my-4'>

            <ProfileCard 
                profile={this.props.profile}
                lang={this.props.lang.profile}
                topComponent={<Grid item xs={12} className='p-2 d-flex justify-content-between'>
                    <Tip className='fyw-font-grey cursor-pointer' onClick={this.initEditProfile}><Icon className='fas fa-edit' />{lang.editProfile}</Tip>
                    <Tip className='fyw-font-grey cursor-pointer' onClick={this.initResetPass}><Icon className='fas fa-edit' />{lang.resetPass}</Tip>
                </Grid>}
                component={<>
                    <Grid item xs={10} className='d-flex justify-content-between mx-auto'>
                    <div>
                        <Paragraph className=''>
                            <GetImg 
                                className={['match-icon-sm']}
                                onClick={null}
                                imageUrl={'match-hug.svg'} // "name-of-the-img.jpg"
                                alt={'hug'}
                            />
                            <span className='ml-2 d-block d-sm-none'>{this.props.hasOwnProperty('hugs') && this.props.hugs !== null ? `${this.props.hugs.length}`: ''}</span>
                            <span className='ml-2 d-none d-sm-block'>{this.props.hasOwnProperty('hugs') && this.props.hugs !== null ? `${this.props.hugs.length} ${lang.hugs}`: lang.hugsComing}</span>
                        </Paragraph>
                    </div>
                    
                    <div>
                        <Paragraph>
                            <GetImg 
                                onClick={null}
                                className={['match-icon-sm']}
                                imageUrl={'match-confirm-connect.svg'} // "name-of-the-img.jpg"
                                alt={'confirm connect'}
                            />
                            <span className='ml-2 d-block d-sm-none'>{this.props.hasOwnProperty('supportiveMessages') && this.props.supportiveMessages !== null ? `${this.props.supportiveMessages.length}`: ''}</span>
                            <span className='ml-2 d-none d-sm-block'>{this.props.hasOwnProperty('supportiveMessages') && this.props.supportiveMessages !== null ? `${this.props.supportiveMessages.length} ${lang.supportMessages}`: lang.supportMessagesComing}</span>
                        </Paragraph>
                    </div>
                </Grid>

                <Grid item xs={10} className='mx-auto'><Divider /></Grid>
                
                {supportiveMessages}    
                </>}
            />
            <ConfirmBox 
                confirmTitle={this.props.confirmMessage.title || ''}
                confirmMsg={this.props.confirmMessage.value || ''}
                close={() => this.props.initConfirmMessage({})} 
                confirm={() => {
                    this.props.initConfirmMessage({})
                    this.props.storePayloadBeforeConfirm({})
                }}
                component={this.state.modalComponent || null}
                cancel={() => {
                    this.props.initConfirmMessage({})
                    this.props.storePayloadBeforeConfirm({})
                }} 
            />
        {/*<Grid container className='match-paper'>
                <Grid item xs={12} sm={4} className='d-flex justify-content-center flex-column text-center py-3'>
                
                    <div className='match-avatar mx-auto'>
                        <Title className='p-0 m-0'>{this.props.profile.display_name.length > 1 ? `${this.props.profile.display_name[0].toUpperCase()}${this.props.profile.display_name[1].toUpperCase()}`:`${this.props.profile.display_name[0].toUpperCase()}`}</Title>
                    </div>
                    <div className='mt-3'>
                        <Paragraph className='fyw-font-grey mx-auto'>{this.props.profile.display_name}</Paragraph>
                    </div>
                </Grid>

                <Grid item xs={12} sm={8} className='py-5 pr-5'>
                    <Grid>
                        <Text className='fyw-font-grey m-0'>{`${this.props.profile.age}, ${GH.capitalizeFirstLetter(this.props.profile.gender)}, ${this.props.profile.state_address}`}</Text>
                        <Text className='fyw-font-grey m-0'>{`Lost ${GH.capitalizeFirstLetter(this.props.profile.relationship)}`}</Text>
                    </Grid>
                    <Grid><Text className='fyw-font-dark fyw-font-bold m-0 mt-4'>My Story</Text></Grid>
                    <Grid><Paragraph className='fyw-font-dark'>{`${this.props.profile.loss_description}`}</Paragraph></Grid>
                </Grid>

                <Grid item xs={10} className='d-flex justify-content-between mx-auto'>
                    <div>
                        <Paragraph>
                            <GetImg 
                                className={['match-icon-sm']}
                                onClick={null}
                                imageUrl={'match-hug.svg'} // "name-of-the-img.jpg"
                                alt={'hug'}
                            />
                            <span className='ml-2'>{`${this.props.hugs.length} hugs`}</span>
                        </Paragraph>
                    </div>
                    
                    <div>
                        <Paragraph>
                            <GetImg 
                                onClick={null}
                                className={['match-icon-sm']}
                                imageUrl={'match-confirm-connect.svg'} // "name-of-the-img.jpg"
                                alt={'confirm connect'}
                            />
                            <span className='ml-2'>{`${this.props.supportiveMessages.length} supportive messages`}</span>
                        </Paragraph>
                    </div>
                </Grid>

                <Grid item xs={10} className='mx-auto'><Divider /></Grid>
                
                {supportiveMessages}

        </Grid>*/}
        </Grid>
        }
}

/*
const SupportiveMessages = props => {
    const date = new Date(props.time)
    return (<><Grid item xs={10} className='d-flex mx-auto my-3'>

        <Grid item xs={2}>
            <div className='match-avatar-sm mx-auto'>
                <Text className='p-0 m-0'>{props.display_name.length > 1 ? `${props.display_name[0].toUpperCase()}${props.display_name[1].toUpperCase()}`:`${props.display_name[0].toUpperCase()}`}</Text>
            </div>
        </Grid>

        <Grid item xs={10} className='supportive-message-bubble p-3'>
            <Text className='p-0 m-0'>{props.message}</Text>
        </Grid>

        </Grid>
        
        <Grid item xs={10} className='mx-auto d-flex justify-content-end'>
            <Tip>{STM.convertTimeToString(date)}</Tip>
        </Grid>
    
    </>)
}
*/
export default Profile