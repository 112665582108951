import { connect } from 'react-redux'
import withAuth from './withAuth'
import Main from '../ui/Main'
import { switchPage } from '../../actions/match'
import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['matchmaking'],
    page: state.matchReducer.page,
    profile: state.matchReducer.profile
})

const mapDispatchToProps = dispatch => ({
    switchPage: page => dispatch(switchPage(page))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(Main)
// export default withAuth(withRouter(Container))
export default withAuth(Container)