import methods from '../methods'
import { combineReducers } from 'redux'

const PG13Answers = (answers = {}, action) => {
    switch(action.type)
    {
        case methods.ADD_PG13_ANSWER:
            let newAnswer = Object.assign({}, answers)
            newAnswer[action.payload.id] = action.payload
            return newAnswer
        case methods.LOAD_PG13_ANSWER:
            // console.log(action)
            return action.payload
        case methods.CLEAR_PG13_ANSWER:
            return {}
        case methods.REMOVE_PG13_ANSWER:
            return {}
        default:
            return answers
    }
}

const DemoAnswers = (answers = {}, action) => {
    switch(action.type)
    {
        case methods.ADD_DEMO_ANSWER:
            let newAnswer = Object.assign({}, answers)
            newAnswer[action.payload.id] = action.payload
            return newAnswer
        case methods.LOAD_DEMO_ANSWER:
            return action.payload
        case methods.CLEAR_DEMO_ANSWER:
            return {}
        default:
            return answers
    }
}

const BCIAnswers = (answers = {}, action) => {
    switch(action.type)
    {
        case methods.ADD_BCI_ANSWER:
            let newAnswer = Object.assign({}, answers)
            newAnswer[action.payload.id] = action.payload
            return newAnswer
        case methods.LOAD_BCI_ANSWER:
            return action.payload
        case methods.CLEAR_BCI_ANSWER:
            return {}
        case methods.REMOVE_BCI_ANSWER:
            return {}
        default:
            return answers
    }
}

const SCFeedbackAnswers = (answers = {}, action) => {
    let newAnswer = Object.assign({}, answers)

    switch(action.type)
    {
        case methods.ADD_SCF_ANSWER:
            newAnswer[action.payload.id] = action.payload
            return newAnswer
        case methods.LOAD_SCF_ANSWER:
            return action.payload
        case methods.CLEAR_SCF_ANSWER:
            return {}
        default: return answers
    }
}

export default combineReducers({
    PG13Answers,
    DemoAnswers,
    BCIAnswers,
    SCFeedbackAnswers
})