import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import MatchNav from './Match/MatchNav'
import Profile from './Match/Profile'
import Stories from './Match/Stories'
import Connect from './Match/Connect'
import matchConfig from '../../config/matchConfig'

class Match extends Component {
    
    constructor(props)
    {
        super(props)
    }

    render()
    {
        // console.log(this.props)
        switch(this.props.page)
        {
            case matchConfig.pages.story:
                return (
                    <MatchNav {...this.props}>
                        <Stories {...this.props}/>
                    </MatchNav>)
            case matchConfig.pages.chat:
                return (
                    <MatchNav {...this.props}>
                        <Connect {...this.props}/>
                    </MatchNav>)
            case matchConfig.pages.profile:
            default:
                return (
                    <MatchNav {...this.props}>
                        <Profile {...this.props}/>
                    </MatchNav>)
        }

    }
}

export default Match