/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import init from '../../config/init'

export const Container = {
	// maxWidth: '600px',
	// backgroundColor: 'teal',
	// borderWidth: '1px',
	// borderStyle: 'solid',
	// borderColor: init.css.colors.lightGrey,
	// display: 'flex',
	// justifyContent: 'center',
	// padding: '20px 10px 20px 10px'
};
export const FormContainer = {
	// position: 'relative',
	// marginBottom: '20px',
	// backgroundColor: init.css.colors.white,
	// padding: '35px 40px',
	// textAlign: 'left',
	// display: 'inline-block',
	// minWidth: '460px',
	// borderRadius: '6px',
	// boxShadow: '1px 1px 4px 0 rgba(0,0,0,0.15)',
	// boxSizing: 'border-box',
};
export const FormSection = {

};
export const FormField = {
};
export const SectionHeader = {
	// fontFamily: init.css.font.subtitle,
	// fontSize: '1.4em'
};
export const SectionBody = {

};
export const SectionFooter = {
	// backgroundColor: 'teal',
	// display: 'flex',
	// width: '100%'
};
export const SectionFooterPrimaryContent = {
	// display: 'flex',
	// width: '100%'
};
export const SectionFooterSecondaryContent = {
	// display: 'flex',
	// width: '100%'
};
export const Input = {

};
export const Button = {
	// width: '40%',
	// padding: '10px',
	// backgroundColor: init.css.colors.yellow,
	// color: init.css.colors.darkGrey
};
export const PhotoPickerButton = {

};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {};
export const Hint = {};
export const Radio = {};
export const Label = {};
export const InputLabel = {};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AmplifyTheme = {
	container: Container,
	formContainer: FormContainer,
	formSection: FormSection,
	formField: FormField,

	sectionHeader: SectionHeader,
	sectionBody: SectionBody,
	sectionFooter: SectionFooter,
	sectionFooterPrimaryContent: SectionFooterPrimaryContent,
	sectionFooterSecondaryContent: SectionFooterSecondaryContent,

	input: Input,
	button: Button,
	photoPickerButton: PhotoPickerButton,
	photoPlaceholder: PhotoPlaceholder,
	signInButton: SignInButton,
	signInButtonIcon: SignInButtonIcon,
	signInButtonContent: SignInButtonContent,
	amazonSignInButton: AmazonSignInButton,
	facebookSignInButton: FacebookSignInButton,
	googleSignInButton: GoogleSignInButton,
	oAuthSignInButton: OAuthSignInButton,

	formRow: FormRow,
	strike: Strike,
	strikeContent: StrikeContent,
	actionRow: ActionRow,
	a: A,

	hint: Hint,
	radio: Radio,
	label: Label,
	inputLabel: InputLabel,
	toast: Toast,

	navBar: NavBar,
	nav: Nav,
	navRight: NavRight,
	navItem: NavItem,
	navButton: NavButton,
};

export default AmplifyTheme;