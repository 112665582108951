import methods from '../methods'
import PH from '../helpers/ProfileHelper'
import initProps from '../config/init'

const mk = Object.keys(initProps.modules)[0]
const initModule = initProps.modules[mk]
const ck = Object.keys(initProps[initModule])[0]
const initComponent = initProps[initModule].components[ck]

export const manageCopingCards = (type = 'load', obj = {}) => dispatch => {
    switch(type)
    {
        case 'clear':
            return dispatch({
                type: methods.CLEAR_COPING_CARD
            })
        case 'add':
            return dispatch({
                type: methods.ADD_COPING_CARD,
                payload: obj
            })
        case 'remove':
            return dispatch({
                type: methods.REMOVE_COPING_CARD,
                payload: obj
            })
        case 'load':
            // console.log('load coping card')
        default:
            return dispatch({
                type: methods.LOAD_COPING_CARD,
                payload: obj
            })
    }
}

export const manageSCContacts = (type = 'load', obj = {}) => dispatch => {
    switch(type)
    {
        case 'remove':
            return dispatch({
                type: methods.REMOVE_SC_CONTACTS,
                payload: obj
            })
        case 'load':
        default:
            return dispatch({
                type: methods.LOAD_SC_CONTACTS,
                payload: obj
            })
    }
}

export const updateCurrentStep = (progress, userId) => async dispatch => {
    // console.log(userId)
    // console.log(details)
    if(!userId)
    {
        const newProgress = {...progress}
        return dispatch({
            type: methods.UPDATE_MODULE_PROGRESS,
            payload: newProgress
        })
    }
    const newProgress = {...progress}
    // console.log(newProgress)
    try
    {
        const updateProgress = await PH.upsertProfile(userId, {
            user_id: userId,
            progress: JSON.stringify(newProgress)
        }).then(r => r).catch(e => false)
        // console.log(updateProgress)
        if(!updateProgress)
        {
            throw updateProgress
        }
        return dispatch({
            type: methods.UPDATE_MODULE_PROGRESS,
            payload: newProgress
        })
    }
    catch(e)
    {
        // console.log('failed')
        // console.log(e)
        return dispatch({
            type: methods.UPDATE_MODULE_PROGRESS,
            payload: newProgress
        })
    }
}

export const loadActiveWheels = (activeWheels = '') => ({
    type: methods.LOAD_ACTIVE_WHEELS,
    payload: activeWheels
})