import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import STM from '../../helpers/TimeManage'
import SH from '../../helpers/SurveyHelper'
import initProps from '../../config/init'
import Questionnaire from '../../assets/Survey'
import { SystemFeedback, ConfirmBox } from '../../assets/Feedback'
import NavSection from '../containers/NavBar'
import { Paragraph, Subtitle } from '../../assets/Text'
import { Button } from '../../assets/Buttons'
import GriefMap from './GriefMap'
import { TitleSecion } from '../../assets/UIElements'
import paths from '../../config/paths'
// import CopingCards from './CopingCards'

const tabsArr = [{
    idx: 2,
    label: 'Bereavement Challenges Inventory',
    value: 2
}]

const BCIArr = SH.getQuestionnaire('BCI')

// deal with questionnaire input and submission
class BCI extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            survey: tabsArr[0]['idx'],
            submissionFeedback: '',
            inComplete: []
        }
        this.storeDataIfNotExist = this.storeDataIfNotExist.bind(this)
        this.storeQuestionnaire = this.storeQuestionnaire.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.handleDateInput = this.handleDateInput.bind(this)
        this.handleRestart = this.handleRestart.bind(this)
    }

    async componentDidMount()
    {
        // check if user has logged in and store the data in the MySQL database
        // if user has not, then display the data from reducer
        // if user has logged in, then display the data from MySQL database
        this.storeDataIfNotExist()
        // if(this.props.auth.hasOwnProperty('accessToken') && this.props.auth.accessToken !== '' && this.props.auth.accessToken !== undefined)
        // {
        //     try
        //     {
        //         let getBCIData = await SH.getLastSurvey(initProps.survey.BCI, this.props.auth.id)
        //         if(getBCIData === undefined)
        //         {
        //             // check if the reducer has answers
        //             if(this.props.BCIAnswers && Object.keys(this.props.BCIAnswers).length > 0)
        //             {
        //                 const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.BCI, this.props.BCIAnswers)
        //                 if(checkComplete)
        //                 {
        //                 let toStore = await SH.cleanDataBeforeStore(initProps.survey.BCI, this.props.BCIAnswers)
        //                 // store the survey in the database
        //                 // console.log(this.props.auth)
        //                 const storeSurvey = await SH.storeSurveyAnswers(this.props.auth.id, initProps.survey.BCI, toStore)
        //                 }
        //             }
        //         }
        //         const scoreBCI = await SH.BCIAnalysis(getBCIData, false)
        //         getBCIData.feedback = scoreBCI
        //         this.props.loadQuestionnaireAnswer(initProps.survey.BCI, getBCIData)
        //     }
        //     catch(e)
        //     {

        //     }
        // }
        // const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.BCI, this.props.BCIAnswers)
        // if(checkComplete)
        // {
        //     // store survey

        // }
        // const completedSurvey = Object.keys(this.props.BCIAnswers).length
        // console.log(completedSurvey)
    }

    async storeDataIfNotExist()
    {
        // check if user has logged in and store the data in the MySQL database
        // if user has not, then display the data from reducer
        // if user has logged in, then display the data from MySQL database
        if(this.props.auth.hasOwnProperty('accessToken') && this.props.auth.accessToken !== '' && this.props.auth.accessToken !== undefined)
        {
            try
            {
                let getBCIData = await SH.getLastSurvey(initProps.survey.BCI, this.props.auth.id)
                if(getBCIData === undefined)
                {
                    // check if the reducer has answers
                    if(this.props.BCIAnswers && Object.keys(this.props.BCIAnswers).length > 0)
                    {
                        const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.BCI, this.props.BCIAnswers)
                        if(checkComplete)
                        {
                        let toStore = await SH.cleanDataBeforeStore(initProps.survey.BCI, this.props.BCIAnswers)
                        // store the survey in the database
                        // console.log(this.props.auth)
                        const storeSurvey = await SH.storeSurveyAnswers(this.props.auth.id, initProps.survey.BCI, toStore)
                        }
                    }
                }
                const scoreBCI = await SH.BCIAnalysis(getBCIData, false)
                getBCIData.feedback = scoreBCI
                this.props.loadQuestionnaireAnswer(initProps.survey.BCI, getBCIData)
            }
            catch(e)
            {

            }
        }
        return
    }

    async storeQuestionnaire(e, qid)
    {
        // console.log('store questionnaire result')
        const self = this
        e.preventDefault()
        const current = STM.getCurrentUTCTimestamp()
        const answerKey = qid + "Answers"
        const answers = this.props[answerKey]
        const inComplete = await SH.checkIfSurveyCompleted(qid, answers, true)
        if(inComplete.length > 0)
        {
            // console.log(inComplete)
            this.props.initConfirmMessage({
                title: this.props.lang.incompleteTitle,
                value: this.props.lang.incompleteNotification,
                onConfirm: () => {
                    self.props.initConfirmMessage({})
                },
                onCancel: () => {
                    self.props.initConfirmMessage({})
                }
            })
            return this.setState({
                inComplete
            })
        }
        const analysisResult = await this.scoreQuestionnaire(qid, answers)
        // console.log(analysisResult)
        var newObj = {
            id: 'feedback',
            answer: analysisResult,
            created_at: current,
            answer_text: this.props.lang.chartIntro
        }
        this.props.addAnswer(qid, newObj)
        await this.storeDataIfNotExist()
        // const storeResult = await SH.storeSurveyAnswers(this.props.user.id, qid, answers)
        // console.log(newObj.answer_text)
        this.setState({
            submissionFeedback: newObj.answer_text || ''
        })

        this.props.initConfirmMessage({
            title: this.props.lang.completeFeedback.replace('[survey]', initProps.questionnaireName[qid]),
            value: newObj.answer_text || this.props.lang.confirmBoxFeedback,
            onConfirm: () => {
                self.props.initConfirmMessage({})
            },
            onCancel: () => {
                self.props.initConfirmMessage({})
            }
        })
    }

    async scoreQuestionnaire(qid, answers)
    {
        switch(qid)
        {
            case 'PG13':
                const PG13Result = await SH.PG13Analysis(answers)
                // console.log(PG13Result)
                return PG13Result.meet ? 'You meet the criteria of PG-13' : 'You do not meet the criteria of PG-13'

            case 'BCI':
                const BCIResult = await SH.BCIAnalysis(answers)
                return BCIResult
            default: return true
        }
    }

    handleInput(e, questionnaire = '', qid = '')
    {
        const value = e.target.value
        const current = STM.getCurrentUTCTimestamp()
        const survey = SH.getQuestionnaire(questionnaire)
        const answerDisplay = SH.getAnswerGivenValue(survey, qid, value)
        switch(e.target.type) {
            case 'text':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'radio':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'select-one':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            default:
        }
    }

    handleDateInput(questionnaire, qid = '', value)
    {
        const current = STM.getCurrentUTCTimestamp()
        const date = STM.formatUTCTime(value)
        const newObj = {
            id: qid,
            answer: date,
            created_at: current,
            answer_text: date
        }
        return this.props.addAnswer(questionnaire, newObj)
    }

    handleRestart(qid = initProps.survey.BCI)
    {
        const self = this
        this.props.initConfirmMessage({
            title: `Restart the survey?`,
            value: `You want to restart the survey ${initProps.questionnaireName[qid]}. All of your data will be deleted, do you still want to restart?`,
            onConfirm: () => {
                self.props.initConfirmMessage({})
                self.props.clearAnswer(qid)
            },
            onCancel: () => {
                self.props.initConfirmMessage({})
            }
        })
    }


    render()
    {
        // console.log(this.props.BCIAnswers)
        let renderTab = [], targetSurvey = []
        tabsArr.forEach(tab => {
            renderTab.push(<Tab key={tab.idx} label={tab.label} value={tab.value} />)
        })
        // console.log(this.props.BCIAnswers.feedbacks)
        // the questionnaire has not been completed
        if(this.props.BCIAnswers && !this.props.BCIAnswers.hasOwnProperty('feedback'))
        {
            return (<NavSection>
                <Questionnaire 
                    key={`target-survey-${this.state.survey}`} 
                    title={BCIArr['title']} 
                    description={BCIArr['description']} 
                    items={BCIArr['questions']} 
                    questionnaire={initProps.survey.BCI} 
                    answers={this.props.BCIAnswers} 
                    handleInput={this.handleInput} 
                    handleDateInput={this.handleDateInput} 
                    handleStore={this.storeQuestionnaire} 
                    feedback={this.state.submissionFeedback} 
                    inComplete={this.state.inComplete}
                />
                <ConfirmBox 
                    confirmTitle={this.props.confirmMessage.title || ''}
                    confirmMsg={this.props.confirmMessage.value || ''}
                    close={() => this.props.addSystemFeedback('')} 
                    confirm={() => this.props.confirmMessage.onConfirm()}
                    cancel={() => this.props.confirmMessage.onCancel()} 
                />
                <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
            </NavSection>)
        }
        return <NavSection>
                <div className='my-5 w-100'>
                    <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
                    {/*<div className="row text-center m-auto">
                        <Subtitle className='mx-auto mt-5'>{this.props.lang.title.toUpperCase() || ''}</Subtitle>
                    </div>
                    
                    <Divider />

                    <Paragraph className="fyw-font-dark mx-auto text-left" style={{width: '80%'}}>
                        {this.props.lang.subtitle || ''}
                    </Paragraph>*/}

                    <Grid item xs={12} sm={8} className='mx-auto my-4'>
                        <Paragraph className="fyw-font-dark mx-auto text-left fyw-font-bold">
                        {this.props.lang.feedback || ''}
                        </Paragraph>
                        <Paragraph className="fyw-font-dark mx-auto text-left">
                        {this.props.lang.chartIntro || ''}
                        </Paragraph>
                        {/*<Chart
                            width={'100%'}
                            height={'300px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                            ['Bereavement Challenges', 'Score'],
                            [this.props.lang.dimensions.connecting, this.props.BCIAnswers.feedback.answer.connecting],
                            [this.props.lang.dimensions.change, this.props.BCIAnswers.feedback.answer.change],
                            [this.props.lang.dimensions.imagining, this.props.BCIAnswers.feedback.answer.imagining],
                            [this.props.lang.dimensions.accepting, this.props.BCIAnswers.feedback.answer.accepting],
                            [this.props.lang.dimensions.guilt, this.props.BCIAnswers.feedback.answer.guilt],
                            ]}
                            options={{
                            title: 'Dimensions of Bereavement Challenges',
                            chartArea: { width: '50%' },
                            colors: [initProps.css.colors.darkBlue],
                            hAxis: {
                                title: 'Score',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'Dimensions',
                            }
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />*/}
                        <GriefMap 
                            BCIScore={this.props.BCIAnswers.feedback.hasOwnProperty('answer') ? this.props.BCIAnswers.feedback.answer:this.props.BCIAnswers.feedback}
                            redirectConnect={() => this.props.history.push(paths.connecting)}
                            dimensions={this.props.lang.dimensions}
                            feedbacks={this.props.lang.feedbacks}
                        />


                    </Grid>

                    {/*<Grid item xs={12} sm={8} className='mx-auto my-4'>
                        <Paragraph className="fyw-font-dark mx-auto text-left fyw-font-bold">
                        {this.props.lang.copingCardsIntro || ''}
                        </Paragraph>
                        <CopingCards {...this.props} />
                    </Grid>*/}

                    <Button className='m-auto' onClick={e => this.handleRestart()}>Restart</Button>

                </div>
            <ConfirmBox 
                confirmTitle={this.props.confirmMessage.title || ''}
                confirmMsg={this.props.confirmMessage.value || ''}
                close={() => this.props.addSystemFeedback('')} 
                confirm={() => this.props.confirmMessage.onConfirm()}
                cancel={() => this.props.confirmMessage.onCancel()} 
            />
            <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
        </NavSection>
    }
}

export default BCI