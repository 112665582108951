import FetchWrapper from '../assets/FetchWrapper'

const Fetch = new FetchWrapper()

class DatabaseHelper {
    constructor()
    {
        
    }

    /**
     * 
     * @param {str} table 
     * @param {obj} params 
     * @param {obj} where 
     */
    async upsert(table, params, where)
    {
        try
        {
            const data = await Fetch.post('/api/v1/upsert', {
                table: table,
                params: params,
                where: where
            }, (err, result) => {
                if(err) throw err
                return result
            })
            return data
            // console.log(data)
        }
        catch(err)
        {
            return err
        }

    }

    getUserIdGivenEmail(email)
    {
        return Fetch.post(`/users/v1/getUserProfile`, {
            fieldData: {
                email: email
            }
        }, (err, result) => {
            if(err) throw err
            return result
        })
    }

    async remove(table, where)
    {
        try
        {
            const data = await Fetch.post('/api/v1/remove', {
                table: table,
                where: where
            }, (err, result) => {
                if(err) throw err
                return result
            })
            return data
            // console.log(data)
        }
        catch(err)
        {
            return err
        }
    }
}

export default new DatabaseHelper()