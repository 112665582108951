import { combineReducers } from 'redux'
import methods from '../methods'
import matchReducer from './match'
import langReducer from './lang'
import configReducer from './config'
import surveyReducer from './survey'
import modulesReducer from './modules'
import userReducer from './user'

const appReducer = combineReducers({
    langReducer,
    configReducer,
    surveyReducer,
    modulesReducer,
    userReducer,
    matchReducer
})
  
const rootReducer = (state, action) => {
    if (action.type === methods.USER_LOGOUT) {
        state = undefined
    }
    if(action.type === methods.LOGOUT) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer