import { connect } from 'react-redux'
import withAuth from './withAuth'
import Match from '../ui/Match'
import initLang from '../../lang/initLang'
import { switchPage, updateProfile, matchLogout, updateHugs, updateSupportMessages, updateMatches, updateConnectedUsers } from '../../actions/match'
import { initConfirmMessage, storePayloadBeforeConfirm } from '../../actions/config'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['matchmaking']['match'],
    page: state.matchReducer.page,
    profile: state.matchReducer.profile,
    hugs: state.matchReducer.hugs,
    supportiveMessages: state.matchReducer.supportiveMessages,
    matches: state.matchReducer.matches,
    connectedUsers: state.matchReducer.connectedUsers,
    confirmMessage: state.configReducer.confirmMessage,
    toConfirmPayload: state.configReducer.toConfirmPayload,
})

const mapDispatchToProps = dispatch => ({
    switchPage: page => dispatch(switchPage(page)),
    updateProfile: profile => dispatch(updateProfile(profile)),
    matchLogout: () => dispatch(matchLogout()),
    updateHugs: hugs => dispatch(updateHugs(hugs)),
    updateSupportMessages: msgs => dispatch(updateSupportMessages(msgs)),
    updateMatches: matches => dispatch(updateMatches(matches)),
    updateConnectedUsers: users => dispatch(updateConnectedUsers(users)),
    initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
    storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(Match)
// export default withAuth(withRouter(Container))
export default withAuth(Container)