const config = {
    aws: {
        Auth: {
                identityPoolId: 'us-east-1:9710b08e-646a-46e2-bba8-b14becc9bf60',
                // REQUIRED - Amazon Cognito Region
                region: 'us-east-1',
                // OPTIONAL - Amazon Cognito User Pool ID
                userPoolId: 'us-east-1_nFqp8IUq1',
                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolWebClientId: '68j5ij7bccd8c6ns9n0uq1ct4t',
        },
        Storage: {
            AWSS3: {
                bucket: 'wcm-eol', //REQUIRED -  Amazon S3 bucket
                region: 'us-east-1', //OPTIONAL -  Amazon service region
            }
        },
        Analytics: {
            // OPTIONAL - disable Analytics if true
            disabled: false,
            // OPTIONAL - Allow recording session events. Default is true.
            autoSessionRecord: true,
    
            AWSPinpoint: {
                region: 'us-east-1',
    
                // Buffer settings used for reporting analytics events.
                // OPTIONAL - The buffer size for events in number of items.
                bufferSize: 1000,
    
                // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
                flushInterval: 5000, // 5s 
    
                // OPTIONAL - The number of events to be deleted from the buffer when flushed.
                flushSize: 100,
    
                // OPTIONAL - The limit for failed recording retries.
                resendLimit: 5
            }
        }
    },
    google: {
        captchaKey: '6LdJGeoUAAAAAAx-K5fOess_Wkzi92W-YXzIeDQn'
    },
    localAuth: {
        // apiServiceUrl: 'http://localhost:5000/api/'
        apiServiceUrl: 'https://api.prolongedgrief.com/api/'
    },
    chatServer: {
        url:"wss://chat.sipanote.com/websocket"
    }
}

export default config