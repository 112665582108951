import { combineReducers } from 'redux'
import lang from '../lang/initLang'
import init from '../config/init'

const initLang = (state = init.lang.en, action) => {
    switch(action.type)
    {
        default: return state
    }
}

export default combineReducers({ initLang })