import React from 'react';
import init from '../config/init'

const config = init
const PUBLIC_URL = config.serverUrl
const IMG_FOLDER_NAME = ''
const suffix = '?alt=media'
/**
 * use this wrapper function to get image from the public url
 * usage:
    import GetImg from 'utilities/GetImg'
    <GetImg
        className={arr}
        onClick={func}
        imageUrl={str} // "name-of-the-img.jpg"
        srcSet={{
            sm: 'name-of-the-img-sm.jpg',
            md: 'name-of-the-img-md.jpg',
            lg: 'name-of-the-img-lg.jpg',
            xl: 'name-of-the-img-xl.jpg'
        }}
        alt={str}
    />
 */
export default class GetImg extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        // console.log(this.props)
        let srcSet = [], className = ""
        if(this.props.className)
        {
            this.props.className.forEach(name => {
                className += ` ${name}`;
            })
        }
        if(this.props.srcSet)
        {
            Object.keys(this.props.srcSet).map(src => {
                let targetSrc = PUBLIC_URL+IMG_FOLDER_NAME+this.props.srcSet[src]+suffix || PUBLIC_URL+IMG_FOLDER_NAME+this.props.imgUrl+suffix
                switch(src)
                {
                    case "sm":
                        srcSet.push(`${targetSrc} 575w`) 
                        break;
                    case "md":
                        srcSet.push(`${targetSrc} 767w`) 
                        break;
                    case "lg":
                        srcSet.push(`${targetSrc} 991w`) 
                        break;
                    case "xl":
                        srcSet.push(`${targetSrc} 1200w`) 
                        break;
                }
            })
            return(
            <img 
                alt={this.props.alt || ""}
                className={className}
                fluid="true"
                src={PUBLIC_URL+IMG_FOLDER_NAME+this.props.imageUrl+suffix}
                onClick={this.props.onClick}
                srcSet={srcSet.join(', ')}
                style={this.props.style || {}}
            />)
        }

        return(
            <img 
                alt={this.props.alt || ""}
                className={className}
                fluid="true"
                src={PUBLIC_URL+IMG_FOLDER_NAME+this.props.imageUrl+suffix}
                onClick={this.props.onClick}
                style={this.props.style || {}}
            />)
    }

}

/**
 * use this wrapper function to get image from the public url
 * usage:
    import { GameSVGImage } from 'utilities/GameImage'
    <GameSVGImage
        className={arr}
        onClick={func}
        imageUrl={str} // "name-of-the-img.jpg"
        id={str}
        coord={obj} // x: ,y:
        styles={obj}
    />
 */
export class GameSVGImage extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            className: ""
        }
    }
    componentWillMount() {
        if(this.props.className)
        {
            let classNames = "";
            this.props.className.forEach(name => {
                classNames += name;
            });
            this.setState({
                className: classNames
            })
        }
    }
    render(){
       //return(<img src={process.env.PUBLIC_URL+IMG_FOLDER_NAME+this.props.imageUrl}></img>)
        return(
        <image 
            className={this.state.className} 
            xlinkHref={PUBLIC_URL+IMG_FOLDER_NAME+this.props.imageUrl+suffix}
            onClick={this.props.onClick}
            x={this.props.coord.x || 0}
            y={this.props.coord.y || 0}
            id={this.props.id}
            style={this.props.styles}
            width={this.props.width} 
            height={this.props.height}
        />)
    }

}
