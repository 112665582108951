import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import NavBar from '../ui/NavBar'
import { clearAuth } from '../../actions'
import { switchPage, matchLogout } from '../../actions/match'

import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['nav'],
    auth: state.userReducer.auth,
    userInfo: state.userReducer.accountInfo,
    session: state.userReducer.session,
    matchProfile: state.matchReducer.profile
})

const mapDispatchToProps = dispatch => ({
    switchPage: page => dispatch(switchPage(page)),
    matchLogout: () => dispatch(matchLogout()),
    // manageAccountInfo: (userId, params) => dispatch(manageAccountInfo(userId, params)),
    // login: (auth, session) => (dispatch(authUser(auth, session))),
    logout: (auth) => (dispatch(clearAuth(auth))),
    // clearLoginErr: (err) => (dispatch(clearLoginErr(err))),
    // getSession: () => dispatch(loadSession())
})

const Cont = connect(mapStateToProps, mapDispatchToProps)(NavBar)
export default withRouter(Cont)