import methods from '../methods'

export const matchLogout = () => ({
    type: methods.LOGOUT
})

export const switchPage = page => ({
    type: methods.SWITCH_PAGE,
    payload: page
})

export const updateProfile = profile => ({
    type: methods.UPDATE_PROFILE,
    payload: profile
})

export const updateHugs = hugs => ({
    type: methods.UPDATE_HUGS,
    payload: hugs
})

export const updateSupportMessages = msgs => ({
    type: methods.UPDATE_MSGS,
    payload: msgs
})

export const updateMatches = matches => ({
    type: methods.UPDATE_MATCHES,
    payload: matches
})

export const updateConnectedUsers = users => ({
    type: methods.UPDATE_CONNECT_USERS,
    payload: users
})