import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'

/**
 * usage:
  include systemFeedback: state.configReducer.systemFeedback in the mapStateToProps
  include addSystemFeedback: feedback => dispatch(addSystemFeedback(feedback)) in the mapDispatchToProps
  
  import { SystemFeedback } from '../../assets/Feedback'
  <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
 */
const SystemFeedback = props => {
  // console.log('called system feedback')
  // console.log(props)
  if(typeof props.feedback !== 'string') return null
    return (<Snackbar
        open={props.feedback !== '' ? true:false}
        autoHideDuration={4000}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        action={
            <Button color="inherit" size="small" onClick={props.close || null}>
              Close
            </Button>
        }
        message={<span id="message-id">{props.feedback}</span>}
    />)
}
/**
 * usage:
  import { ConfirmBox } from '../../assets/Feedback'
  <ConfirmBox 
    confirmTitle={this.props.confirmTitle || ''}
    confirmMsg={this.props.confirmMsg || ''}
    close={() => this.props.addSystemFeedback('')} 
    confirm={() => this.function(this.props.toConfirmPayload, true)} 
    cancel={() => {
      this.props.initConfirmMessage({})
      this.props.storePayloadBeforeConfirm({})
    }} 
  />

  mapStateToProps:
  toConfirmPayload: state.configReducer.toConfirmPayload,
  confirmMessage: state.configReducer.confirmMessage,

  mapDispatchToProps:
  initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
  storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload)),

  this.props.initConfirmMessage({
      title: 'Confirm delete',
      value: 'You are about to delete the contact, please confirm.'
  })
  this.props.storePayloadBeforeConfirm(yourPayload)

 */
const ConfirmBox = props => {
  return(<Dialog aria-labelledby="simple-dialog-title" open={props.confirmMsg ? true: false}>
    <DialogTitle id="simple-dialog-title">{props.confirmTitle || 'System message'}</DialogTitle>
    <DialogContent>
    {props.component !== null ? 
      props.component
      :
      <DialogContentText id="alert-dialog-description">{props.confirmMsg || 'Please confirm you action.'}</DialogContentText>
    }
    {/*<DialogContentText id="alert-dialog-description">{props.confirmMsg || 'Please confirm you action.'}</DialogContentText>*/}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.cancel()} color="primary">
        Cancel
      </Button>
      <Button onClick={() => props.confirm()} color="primary" autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>)
}

export { SystemFeedback, ConfirmBox }