import React, { Component } from 'react'
import Match from '../containers/Match'
import Auth from '../containers/Auth'
import NavSection from '../containers/NavBar'
import { TitleSecion } from '../../assets/UIElements'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import matchConfig from '../../config/matchConfig'

class Main extends Component {
    
    constructor(props)
    {
        super(props)
    }

    async componentWillMount()
    {
        // console.log(`main component mounted`)
        const loggedIn = await AuthenticationHelper.hasAuthenticated()
        if(!loggedIn) this.props.switchPage(matchConfig.pages.login)

        // console.log(AuthenticationHelper.uuid)
        // console.log(AuthenticationHelper.token)
    }

    render()
    {
        console.log(this.props)

        if(!this.props.profile.hasOwnProperty('uuid') 
        || this.props.profile.uuid === ''
        || !AuthenticationHelper.hasAuthenticated()) return <NavSection>
            <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
            <Auth />
        </NavSection>

        switch(this.props.page)
        {
            case matchConfig.pages.profile:
            case matchConfig.pages.story:
            case matchConfig.pages.chat:
                return <NavSection>
                    <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
                    <Match />
                </NavSection>
            case matchConfig.pages.login:
            case matchConfig.pages.register:
            case matchConfig.pages.forgotPass:
            default:
                return <NavSection>
                    <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
                    <Auth />
                </NavSection>
        }
    }
}

export default Main