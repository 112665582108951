import React, { useState } from 'react'
import GetImg from './GetImg'

/**
 * Create an image slider using the format
 * @param {*} props 
    usage:
    const imgObj = {
        'yarning': {
            srcSet: {
                sm: 'yearning-sm.png',
                md: 'yearning-md.png',
                lg: 'yearning-lg.png',
                xl: 'yearning-xl.png'
            },
            alt: 'yearning'
        }
    }
 */
const ImageSlider = props => {
    let [slideIndex, changeIndex] = useState(1)
    // console.log(slideIndex)
    if(!props.hasOwnProperty('source')) return null
    let sliders = [], thumbnails = [], length = Object.keys(props.source).length
    Object.keys(props.source).map((k, i) => {
        const target = props.source[k], targetIdx = i + 1
        sliders.push(<SliderImage key={`image-slider-${i}`} slideIndex={slideIndex} targetIdx={targetIdx} length={length} {...target} />)
        thumbnails.push(<SliderThumbnail key={`image-slider-thumbnail-${i}`} active={slideIndex === targetIdx} {...target} onClick={() => changeIndex(targetIdx)} />)
    })
    return(<>
        <div className={props.className ? `img-slider-container ${props.className}` : 'img-slider-container'} {...props} >
            {sliders}
            <a className="prev" onClick={() => {
                let newIndex = slideIndex - 1 < 1 ? length : slideIndex - 1
                changeIndex(newIndex)
            }}>&#10094;</a>
            <a className="next"  onClick={() => {
                let newIndex = slideIndex + 1 > length ? 1 : slideIndex + 1
                changeIndex(newIndex)
            }}>&#10095;</a>

        </div>
        <div className="caption-container d-flex">
            <p id="caption"></p>
        </div>

        <div className="w-100 d-flex">
            {thumbnails}
        </div>
    </>)
}

const SliderImage = props => {
    return (<div key={`image-slider-${props.targetIdx}`} className={props.slideIndex === props.targetIdx ? `mySlides`: 'mySlides hide'}>
        <div className="numbertext fyw-font-dark">{props.targetIdx} / {props.length}</div>
        <GetImg
            className={['w-100']}
            onClick={null}
            imageUrl={props.srcSet.md} // "name-of-the-img.jpg"
            srcSet={props.srcSet}
            alt={props.alt}
        />
    </div>)
}

const SliderThumbnail = props => {
    const c = props.active ? ['demo cursor', 'w-100', 'active']:['demo cursor', 'w-100']

    return (<div className={"column"}>
    <GetImg
        className={c}
        onClick={() => props.onClick()}
        imageUrl={props.url} // "name-of-the-img.jpg"
        srcSet={props.srcSet}
        alt={props.alt}
    />
    </div>)
}

export { ImageSlider }