import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { ImageButton, Button } from '../../../assets/Buttons'
import matchConfig from '../../../config/matchConfig'
import ChatSocketHelper from '../../../helpers/ChatSocketHelper'
import ChatSystemHelper from '../../../helpers/ChatSystemHelper'
import ChatInfoStore from '../../../helpers/ChatInfoStore'
import AuthenticationHelper from '../../../helpers/AuthenticationHelper'

const MatchNav = props => {
    let lang = props.lang.matchNav
    return (<Grid item xs={12} sm={10} md={8} className='mx-auto mb-4'>
        <Grid className='d-flex justify-content-between mx-auto'>
            <ImageButton onClick={() => props.switchPage(matchConfig.pages.profile)} active={props.page === matchConfig.pages.profile} className={props.page === matchConfig.pages.profile ? 'w-100 match-nav-btn match-nav-btn-active':'w-100 match-nav-btn'} src={'profile-icon.svg'} activeSrc={'profile-icon.svg'}>{lang.myBoard.toUpperCase()}</ImageButton>
            <ImageButton onClick={() => props.switchPage(matchConfig.pages.story)} active={props.page === matchConfig.pages.story} className={props.page === matchConfig.pages.story ? 'w-100 match-nav-btn match-nav-btn-active':'w-100 match-nav-btn'} src={'stories-icon.svg'} activeSrc={'stories-icon.svg'}>{lang.stories.toUpperCase()}</ImageButton>
            <ImageButton onClick={() => props.switchPage(matchConfig.pages.chat)} active={props.page === matchConfig.pages.chat} className={props.page === matchConfig.pages.chat ? 'w-100 match-nav-btn match-nav-btn-active':'w-100 match-nav-btn'} src={'connect-icon.svg'} activeSrc={'connect-icon.svg'}>{lang.connect.toUpperCase()}</ImageButton>
        </Grid>
        {props.children}
        <Grid item xs={12} className='d-flex justify-content-center'><Button onClick={() => {
            props.switchPage(matchConfig.pages.login)
            props.matchLogout()
            AuthenticationHelper.resetToken()
            //reset all the chat parameters
            ChatSystemHelper.resetChatToken()
            // ChatInfoStore.chatRoomInfo=null
            ChatInfoStore.resetChatInfo()
            ChatSocketHelper.logout()
        }}>Logout</Button></Grid>
    </Grid>)
}

export default MatchNav