import React from 'react'
import { Text } from './Text'
import STM from '../helpers/TimeManage'
import './assets.scss'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SelectComponent from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CheckboxItem from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers'
  import DateFnsUtils from '@date-io/date-fns'

// const STM = new SystemTimeManagement()

/**
 * usage:
    <TextInput 
        ref={password => this._password = password}
        type={this.state.hidePass ? "password" : "text"}
        label='Password'
        ariaLabel='password'
        icon='envelope'
        onChange={this.onChangehandler}
        error='your error text'
        helperText=''
    />
 */
const TextInput = React.forwardRef((props, ref) => {
    if(!props.withAdornment)
    {
        if(props.hasOwnProperty('error') && props.error !== '')
        {
            return (
                <FormControl error className='w-100'>
                    <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
                    <Input
                        inputRef={ref}
                        id={props.id}
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange ? (e) => props.onChange(e) : null}
                        startAdornment={
                            <InputAdornment position="start">
                            <i className={`fas fa-${props.icon}`}></i>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText >{props.error}</FormHelperText>
                </FormControl>)
        }
        return (
        <FormControl className='w-100'>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <Input
                inputRef={ref}
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                startAdornment={
                    <InputAdornment position="start">
                    <i className={`fas fa-${props.icon}`}></i>
                    </InputAdornment>
                }
            />
            {props.helperText !== '' ? <FormHelperText >{props.helperText}</FormHelperText> : null}
        </FormControl>)
    }
    if(props.hasOwnProperty('error') && props.error !== '')
    {
        return (<FormControl error className='w-100'>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <Input
                inputRef={ref}
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                startAdornment={
                    <InputAdornment position="start">
                        <i className={`fas fa-${props.icon}`}></i>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label={props.endAdornmentLabel}
                        onClick={props.handleEndAdornmentClick}
                    >
                    <i className={`fas fa-${props.endAdornmentIcon}`}></i>
                    </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText >{props.error}</FormHelperText>
        </FormControl>)
    }
    return (<FormControl className='w-100'>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <Input
            inputRef={ref}
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange ? (e) => props.onChange(e) : null}
            startAdornment={
                <InputAdornment position="start">
                    <i className={`fas fa-${props.icon}`}></i>
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label={props.endAdornmentLabel}
                    onClick={props.handleEndAdornmentClick}
                >
                <i className={`fas fa-${props.endAdornmentIcon}`}></i>
                </IconButton>
                </InputAdornment>
            }
        />
        {props.helperText !== '' ? <FormHelperText >{props.helperText}</FormHelperText> : null}
    </FormControl>)

    // return(
    //     <MDBInput inputRef={ref} id={props.id} onChange={props.onChange ? e => props.onChange(e):null} icon={props.icon || ''} type={props.type} className={"form-control "+props.classes} label={props.label} placeholder={props.placeholder} aria-label={props.ariaLabel} aria-describedby="basic-addon1" />
    // )
})

/**
 * usage:
    <TextArea 
        ref={password => this._password = password}
        type={this.state.hidePass ? "password" : "text"}
        label='Password'
        ariaLabel='password'
        icon='envelope'
        onChange={this.onChangehandler}
        rows={5}
    />
 */
const TextArea = React.forwardRef((props, ref) => {
    if(props.hasOwnProperty('error') && props.error !== '')
    {
        return (<FormControl error className='w-100'>
            <TextField
                error={props.error !== ''}
                helperText={props.error || ''}
                inputRef={ref || null}
                id={props.id || ''}
                label={props.label}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                multiline
                rows={props.rows || 2}
                rowsMax={props.rows || 4}
            />
        </FormControl>)
    }
    return (<FormControl className='w-100'>
        <TextField
            inputRef={ref || null}
            id={props.id || ''}
            label={props.label}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange ? (e) => props.onChange(e) : null}
            multiline
            rows={props.rows || 2}
            rowsMax={props.rows || 4}
        />
        <FormHelperText>{props.helperText || ''}</FormHelperText>
    </FormControl>)
})

const FormTextInput = React.forwardRef((props, ref) => {
    if(!props.withAdornment)
    {
        return (<>
            <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
            <div className="select-container col-md-6 col-sm-12">
            <FormControl className='w-100'>
            <Input
                inputRef={ref}
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                value={props.inputValue || ''}
                onChange={props.handleInput ? (e) => props.handleInput(e, props.questionnaire, props.qid) : null}
                startAdornment={
                    <InputAdornment position="start">
                    <i className={`fas fa-${props.icon}`}></i>
                    </InputAdornment>
                }
            />
            </FormControl>
            </div>
        </>)
    }
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
        <FormControl className='w-100'>
        <Input
            inputRef={ref}
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.handleInput ? (e) => props.handleInput(e, props.questionnaire, props.qid) : null}
            value={props.inputValue || ''}
            startAdornment={
                <InputAdornment position="start">
                    <i className={`fas fa-${props.icon}`}></i>
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label={props.endAdornmentLabel}
                    onClick={props.handleEndAdornmentClick}
                >
                <i className={`fas fa-${props.endAdornmentIcon}`}></i>
                </IconButton>
                </InputAdornment>
            }
        />
        </FormControl>
        </div>
    </>)
})
/**
 * usage:
    <Radio 
        ref={opt1 => this._opt1 = opt1}
        id="id"
        value={1}
        display="option 1"
        checked={false}
        inline={false}
        name="PG13-1"
    />
 */
const RadioItem = React.forwardRef((props, ref) => {
    return (<FormControlLabel value={props.value} control={<Radio />} checked={props.checked} label={props.label} />)
})
const FormGroup = React.forwardRef((props, ref) => {

    let defaultClassName = `form-group row fyw-form-group`
    
    if(props.hasOwnProperty('inComplete') && props.inComplete)
    {
        defaultClassName = `form-group row fyw-form-group fyw-form-group-incomplete`
    }
    return (
        <div ref={ref} className={props.className !== undefined ? `${defaultClassName} ${props.className}` : defaultClassName}>
            {props.children}
        </div>
    )
})

const RadioSet = React.forwardRef((props, ref) => {
    // console.log('radio set is called')
    let renderArr = []
    props.options.forEach(item => {
        renderArr.push(<RadioItem 
            key={item.id}
            id={item.id}
            value={item.value}
            label={item.display}
            name={props.name}
            checked={props.inputValue !== -1 && (parseInt(props.inputValue, 10) === parseInt(item.value, 10))}
        />)
    })
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
        <RadioGroup className={props.inline ? 'radio-set-inline' : ''} aria-label={props.name} name={props.name} onChange={(e) => props.handleInput(e, props.questionnaire, props.qid)} value={props.inputValue || -1}>{renderArr}</RadioGroup>
        </div>
    </>)
        // return (<>
    //     <div className="col-form-label col-sm-12 pt-0 d-flex justify-content-center align-items-md-start align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
    //     <div className="col-sm-12 form-inline">{renderArr}</div>
    // </>)
})

/**
 * usage:
    const optionsArr = [
        {
            value: '1',
            display: 'option 1'
        }
    ]
    <Select 
        options={optionsArr}
        id="select-1"
        title="Select one"
        name="select1"
    />
 */
const Select = React.forwardRef((props, ref) => {
    let options = []
    props.options.forEach((option, idx) => {
        options.push(<option key={`${props.id}-option-${idx}`} id={option.id} value={option.value}>{option.display}</option>)
    })
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
          <select ref={ref} id={props.id} name={props.name} value={props.inputValue || 0} className="form-control" onChange={(e) => props.handleInput(e, props.questionnaire, props.qid)}>
            {options}
          </select>
        </div>
    </>)
})

/**
 * usage:
    <SelectItem 
        ref={r => this._select = r}
        label='Your Relationship'
        id=''
        inputValue='1'
        handleInput={e => console.log(e)}
    />
 */
const SelectItem = React.forwardRef((props, ref) => {
    let options = []
    props.options.forEach((option, idx) => {
        options.push(<MenuItem key={`${props.id}-option-${idx}`} id={option.id} value={option.value}>{option.display}</MenuItem>)
    })
    if(props.hasOwnProperty('error') && props.error !== '')
    {
        return (<FormControl error className='w-100'>
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
            <SelectComponent ref={ref} id={props.id || ''} name={props.name} value={props.inputValue || ''} className="form-control" onChange={(e) => props.handleInput(e)}>
            {options}
            </SelectComponent>
            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>)
    }
    return (<FormControl className='w-100'>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <SelectComponent ref={ref} id={props.id || ''} name={props.name} value={props.inputValue || ''} className="form-control" onChange={(e) => props.handleInput(e)}>
        {options}
        </SelectComponent>
    </FormControl>)
})

const DatePicker = React.forwardRef((props, ref) => {
    const today = STM.getCurrentUTCTimestamp()
    return(<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center">
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id={props.id}
            value={props.inputValue || today}
            label={props.label}
            onChange={(date) => props.handleInput(props.questionnaire, props.qid, date)}
            KeyboardButtonProps={{
            'aria-label': 'change date',
            }}
            className="w-100"
        />
        </Grid>
        </MuiPickersUtilsProvider>
        </div>
    </>)
})

/**
 * usage:
    <Checkbox 
        label='label'
        checked={true}
        onChange={e => console.log('changed')}
    />
 */

const Checkbox = props => {
    if(props.hasOwnProperty('error') && props.error !== '')
    {
        return <FormControl error className='w-100'>
        <FormControlLabel
            control={<CheckboxItem color={props.color || "primary"} checked={props.checked} onChange={props.onChange} name={props.name || ''} />}
            label={props.label}
        />
        <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    }
    return <FormControl className='w-100'>
    <FormControlLabel
        control={<CheckboxItem color={props.color || "primary"} checked={props.checked} onChange={props.onChange} name={props.name || ''} />}
        label={props.label}
    />
    </FormControl>
}

export { TextInput, TextArea, RadioItem, Select, FormGroup, RadioSet, DatePicker, FormTextInput, SelectItem, Checkbox }