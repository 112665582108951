import React, { Component } from 'react'
// import GH from '../../../classes/GeneralHelper'
import Grid from '@material-ui/core/Grid'
import { ConnectingIcons, ModuleProgressBar } from '../../../assets/SVGIcons'
import { Tip } from '../../../assets/Text'
import '../../../scss/modules.scss'
import initProps from '../../../config/init'

// console.log(initProps)

const m = initProps.modules.connecting
const connectingComponents = initProps[m]['components']

class ModuleProgress extends Component {
    constructor(props)
    {
        super(props)
        // this.switchComponent = this.switchComponent.bind(this)
    }

    // async switchComponent(module = initProps.modules.connecting, component = connectingComponents.intenseFeelings, details = {})
    // {
    //     let progress
    //     if(!this.props.userInfo.progress || this.props.userInfo.progress === null)
    //     {
    //         // console.log('init a new progress')
    //         progress = {}
    //     }
    //     else
    //     {
    //         progress = typeof this.props.userInfo.progress === 'string' ? JSON.parse(this.props.userInfo.progress) : this.props.userInfo.progress
    //         // console.log(progress)
    //     }
    //     // let 
    //     const newDetails = GH.joinObjs(progress.details || {}, details)
    //     // console.log(newDetails)
    //     progress['module'] = module
    //     progress['component'] = component
    //     progress['detail'] = newDetails
    //     // console.log(progress['detail'])
    //     let update = await this.props.manageAccountInfo(this.props.user.id, {
    //         progress: JSON.stringify(progress)
    //     })
    //     let updateAccount = await this.props.updateCurrentStep(module, component, newDetails, this.props.user.id)
    //     return true
    //     // console.log(this.props.userInfo.progress)
    //     // await this.props.updateCurrentStep(module, component, details, this.props.user.id)
    //     // console.log(this.props.progress)
    // }

    render()
    { 
        // console.log(this.props)
        const progress = this.props.progress
        // console.log(progress)
        return (<Grid container className="w-100 d-flex justify-content-between mt-4" spacing={2} >

            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} onClick={e => this.props.switchComponent(initProps.modules.connecting, connectingComponents.intenseFeelings, connectingComponents.intenseFeelings, null)}>
                    <div className="connecting-icon mx-auto mb-2"><ConnectingIcons type={connectingComponents.intenseFeelings} /></div>
                    <ModuleProgressBar type="beginUp" title={this.props.lang.intenseFeelings.title} coord={{ x: "-10" }} active={this.props.progress.component === connectingComponents.intenseFeelings} 
                        completed={progress.hasOwnProperty(initProps.modules.connecting) && progress[initProps.modules.connecting].hasOwnProperty(initProps.connectingModule.components.intenseFeelings) 
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.intenseFeelings].hasOwnProperty('details')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.intenseFeelings]['details'].hasOwnProperty('completed')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.intenseFeelings]['details']['completed']}
                    />
                    <Tip className="fyw-font-grey mt-2 px-2">{this.props.lang.intenseFeelings.subtitle}</Tip>
                </Grid>

                <Grid item xs={6} sm={6} md={3} onClick={e => this.props.switchComponent(initProps.modules.connecting, connectingComponents.copingCards, connectingComponents.copingCards, null)}>
                    <div className="connecting-icon mx-auto mb-2"><ConnectingIcons type={connectingComponents.copingCards} /></div>
                    <ModuleProgressBar type="middleUp" title={this.props.lang.copingCards.title} coord={{ x: "-2" }} active={this.props.progress.component === connectingComponents.copingCards} 
                        completed={progress.hasOwnProperty(initProps.modules.connecting) && progress[initProps.modules.connecting].hasOwnProperty(initProps.connectingModule.components.copingCards) 
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.copingCards].hasOwnProperty('details')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.copingCards]['details'].hasOwnProperty('completed')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.copingCards]['details']['completed']}
                    />
                    <Tip className="fyw-font-grey mt-2 px-2">{this.props.lang.copingCards.subtitle}</Tip>
                </Grid>

                <Grid item xs={6} sm={6} md={3} onClick={e => this.props.switchComponent(initProps.modules.connecting, connectingComponents.identifyCompanions, connectingComponents.identifyCompanions, null)}>
                    <div className="connecting-icon mx-auto mb-2"><ConnectingIcons type={connectingComponents.identifyCompanions}/></div>
                    <ModuleProgressBar type="middleUp" title={this.props.lang.identifyCompanions.title} coord={{ x: "6" }} active={this.props.progress.component === connectingComponents.identifyCompanions}  
                        completed={progress.hasOwnProperty(initProps.modules.connecting) && progress[initProps.modules.connecting].hasOwnProperty(initProps.connectingModule.components.identifyCompanions) 
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.identifyCompanions].hasOwnProperty('details')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.identifyCompanions]['details'].hasOwnProperty('completed')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.identifyCompanions]['details']['completed']}
                    />
                    <Tip className="fyw-font-grey mt-2 px-2">{this.props.lang.identifyCompanions.subtitle}</Tip>
                </Grid>
                
                <Grid item xs={6} sm={6} md={3} onClick={e => this.props.switchComponent(initProps.modules.connecting, connectingComponents.rehearseWhatToSay, connectingComponents.rehearseWhatToSay, null)}>
                    <div className="connecting-icon mx-auto mb-2"><ConnectingIcons type={connectingComponents.rehearseWhatToSay}/></div>
                    <ModuleProgressBar type="endUp" title={this.props.lang.rehearseWhatToSay.title} coord={{ x: "15" }}  active={this.props.progress.component === connectingComponents.rehearseWhatToSay}  
                        completed={progress.hasOwnProperty(initProps.modules.connecting) && progress[initProps.modules.connecting].hasOwnProperty(initProps.connectingModule.components.rehearseWhatToSay) 
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.rehearseWhatToSay].hasOwnProperty('details')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.rehearseWhatToSay]['details'].hasOwnProperty('completed')
                            && progress[initProps.modules.connecting][initProps.connectingModule.components.rehearseWhatToSay]['details']['completed']}
                    />
                    <Tip className="fyw-font-grey mt-2 px-2" display={{ xs: 'none', }}>{this.props.lang.rehearseWhatToSay.subtitle}</Tip>
                </Grid>
            </Grid>

        </Grid>)
    }
}

export default ModuleProgress