import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BCI from '../ui/BCI'
import initLang from '../../lang/initLang'
import { addSystemFeedback, initConfirmMessage, storePayloadBeforeConfirm } from '../../actions/config'
import { addAnswer, clearAnswer, loadQuestionnaireAnswer } from '../../actions/survey'
import { manageCopingCards } from '../../actions/modules'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['BCI'],
    auth: state.userReducer.auth,
    userInfo: state.userReducer.accountInfo,
    confirmMessage: state.configReducer.confirmMessage,
    systemFeedback: state.configReducer.systemFeedback,
    toConfirmPayload: state.configReducer.toConfirmPayload,
    BCIAnswers: state.surveyReducer.BCIAnswers,
    copingCards: state.modulesReducer.copingCards
})

const mapDispatchToProps = dispatch => ({
    addAnswer: (questionnaire, answerObj) => dispatch(addAnswer(questionnaire, answerObj)),
    clearAnswer: (questionnaire) => dispatch(clearAnswer(questionnaire)),
    loadQuestionnaireAnswer: (questionnaire, answerObj) => dispatch(loadQuestionnaireAnswer(questionnaire, answerObj)),
    addSystemFeedback: feedback => dispatch(addSystemFeedback(feedback)),
    initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
    storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload)),
    manageCopingCards: (type, obj) => dispatch(manageCopingCards(type, obj))
})

const Cont = connect(mapStateToProps, mapDispatchToProps)(BCI)
export default withRouter(Cont)