import React, { Component, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paragraph, Tip, Text } from '../../../assets/Text'
import { TextInput } from '../../../assets/Form'
import AuthenticationHelper from '../../../helpers/AuthenticationHelper'
import UserInteractionHelper from '../../../helpers/UserInteractionHelper'
import ChatSystemHelper from '../../../helpers/ChatSystemHelper'
import ChatInfoStore from '../../../helpers/ChatInfoStore'
import ChatSocketHelper from '../../../helpers/ChatSocketHelper'
import GetImg from '../../../assets/GetImg'
// import { ChatFeed, Message, BubbleGroup, ChatBubble } from 'react-chat-ui'
import { ThemeProvider, MessageList, MessageGroup, Message, MessageText, FixedWrapper, darkTheme, elegantTheme, purpleTheme, defaultTheme } from '@livechat/ui-kit'
import GH from '../../../helpers/GeneralHelper'
// import { ThemeProvider,  } from '@livechat/ui-kit'

const themes = {
    defaultTheme: {
        FixedWrapperMaximized: {
            css: {
                boxShadow: '0 0 1em rgba(0, 0, 0, 0.1)',
            },
        },
    },
    purpleTheme: {
        ...purpleTheme,
        TextComposer: {
            ...purpleTheme.TextComposer,
            css: {
                ...purpleTheme.TextComposer.css,
                marginTop: '1em',
            },
        },
        OwnMessage: {
            ...purpleTheme.OwnMessage,
            secondaryTextColor: '#fff',
        },
    },
    elegantTheme: {
        ...elegantTheme,
        Message: {
            ...darkTheme.Message,
            secondaryTextColor: '#fff',
        },
        OwnMessage: {
            ...darkTheme.OwnMessage,
            secondaryTextColor: '#fff',
        },
    },
    darkTheme: {
        ...darkTheme,
        Message: {
            ...darkTheme.Message,
            css: {
                ...darkTheme.Message.css,
                color: '#fff',
            },
        },
        OwnMessage: {
            ...darkTheme.OwnMessage,
            secondaryTextColor: '#fff',
        },
        TitleBar: {
            ...darkTheme.TitleBar,
            css: {
                ...darkTheme.TitleBar.css,
                padding: '1em',
            },
        },
    },
}


// const theme = {
//     vars: {
//         'primary-color': '#427fe1',
//         'secondary-color': '#fbfbfb',
//         'tertiary-color': '#fff',
//         'avatar-border-color': 'blue',
//         'width': '100%',
//         'height': '100%'
//     },
//     Message: {
//         MessageText: {
//             "border-radius": "20px",
//             "padding": "8px 15px",
//             "margin-top": "5px",
//             "margin-bottom": "5px",
//             "display": "inline-block"
//         },
//         css: {
//             "border-radius": "20px",
//             "padding": "8px 15px",
//             "margin-top": "5px",
//             "margin-bottom": "5px",
//             "display": "inline-block"
//         },
//     },
// }

class Connect extends Component {
    _isMount = false

    constructor(props)
    {
        super(props)

        this.state = {
            selectedContact: {},
            messageList:[]
        }

        this.handleUpdateMessage = this.handleUpdateMessage.bind(this)
        this.handleSwitchChannel = this.handleSwitchChannel.bind(this)
        this.handleSendMessage = this.handleSendMessage.bind(this)
        console.log("fires constructer")
        console.log(this._isMount)
    }


    async componentWillMount()
    {
        console.log("Starting Mount")
        this._isMount = true
        console.log(this._isMount)
        const userContacts= await UserInteractionHelper.getContactSentByUserId(AuthenticationHelper.uuid)
        const requestedToContact = await UserInteractionHelper.getContactByUserId(AuthenticationHelper.uuid)
        let contacts = [], noRepeat = []
        // console.log(requestedToContact)
        // console.log(userContacts)
        if(requestedToContact.hasOwnProperty('data') && requestedToContact.data !== null && this._isMount)
        {
            contacts = contacts.concat(requestedToContact.data)
            // contacts.push(requestedToContact.data)
            // contacts.push(userContacts.data)
            // console.log(userContacts.data)
        }
        if(userContacts.hasOwnProperty('data') && userContacts.data !== null && this._isMount)
        {
            contacts = contacts.concat(userContacts.data)
            // contacts = [...userContacts.data]
            // contacts.push(userContacts.data)
            // console.log(userContacts.data)
            // await this.props.updateConnectedUsers(userContacts.data)
        }
        // noRepeat = [...contacts]
        
        noRepeat=[]
        contacts.forEach((item,index)=>{
            if(
                noRepeat.findIndex(contact => contact.reciever_id ===item.sender_id  && contact.sender_id === item.reciever_id )=== -1  && // remove sender, reciever cross match items
                noRepeat.findIndex(contact => contact.sender_id ===item.sender_id  && contact.reciever_id === item.reciever_id )=== -1     // remove same sender reciever items
              )
            {
                noRepeat.push(item)
            }
        })
        await this.props.updateConnectedUsers(noRepeat)

        //get chat account information
        if(!ChatSystemHelper.chatToken)
        {
            if(await ChatSystemHelper.loginChat(AuthenticationHelper.uuid))
            {
                console.log("logged in to chat successfully")
            }
            else{
                console.log("failed logging in")
            }
        }
        else{
            console.log("socket already exists, resume")
        }

        //subscribe to chatroom
        await ChatSystemHelper.subscribeToChat(AuthenticationHelper.uuid,this.handleUpdateMessage) //chatroomIdList holds all the relationship between roomid and partnerid
        if(this._isMount)
        {
            this.setState({
                selectedContact: noRepeat[0] || {}
            })
        }
        console.log("Ending Mount")
        console.log(this._isMount)

    }
    //TODO: process message into correct rooms with user id
    //[ {partnerid="id", messageList=[]},{partner="id",messageList=[]}]
    processMessageList(MessageList){
        // console.log('process message called')
        //console.log(MessageList)
        let roomMessage={}
        //console.log(ChatInfoStore.chatRoomInfo)

        if(ChatInfoStore.chatRoomInfo)
        {
            ChatInfoStore.chatRoomInfo.forEach((room) => roomMessage[room.partnerId] = [])
        }

        if(MessageList){
            // console.log(MessageList)
            MessageList.forEach((messageObj)=>{
                if(messageObj === undefined) return
                //roomMessage[messageObj]
                let roomObj = ChatInfoStore.chatRoomInfo.find(element=> element.roomId ==  messageObj.rid) || {}
                if (!roomMessage[roomObj.partnerId])
                {
                    roomMessage[roomObj.partnerId]= [messageObj]
                }
                else
                {
                    roomMessage[roomObj.partnerId].push(messageObj)
                }
            })
        }
        // console.log(roomMessage)

        return roomMessage
        // console.log(roomMessage)
    }

    handleUpdateMessage(newMessageList){
        console.log("Update message")
        console.log(newMessageList)
        console.log(this._isMount)
        if(this._isMount)
        {
            this.setState({
                messageList: this.state.messageList.concat(newMessageList)
            })
        }
    }

    handleSendMessage(rid = '', msg = '')
    {
        // console.log(this.state.selectedContact.contact_id)
        // console.log(this.state.selectedContact)
        // console.log(`room id ${rid}`)
        console.log("send Message")
        console.log(rid+":"+msg)
        if(rid === '' || msg === '') return null
        //const result = await ChatSocketHelper.sendMessageToRoom(rid, msg, AuthenticationHelper.uuid)
        ChatSocketHelper.sendMessageToRoom(rid, msg, AuthenticationHelper.uuid)
        //console.log(result)

        return true
    }

    handleSwitchChannel(contact)
    {
        if(contact.hasOwnProperty("contact_id") && contact.contact_id !== '' && this._isMount)
        {
            return this.setState({
                selectedContact: contact || {}
            })
        }
        // if(!senderId || senderId === '' || !receiverId || receiverId === '') return false
        // console.log(`The sender id is ${senderId} and receiver id is ${receiverId}`)
    }

    componentWillUnmount()
    {
        //console.log("Unmounted")
        this._isMount = false
        ChatInfoStore.resetChatInfo()
        //console.log(this._isMount)
    }

    render()
    {
        // return (<>
        //     <BubbleGroup
        //         messages={this.state.messages}
        //         id={'test'}
        //         showSenderName={true}
        //     />
        // </>)
        console.log("render")
        let lang = this.props.lang.connect
        let senderId = AuthenticationHelper.uuid
        let currentMsgObj = {}, msgArr = []
        if(this.state.messageList.length>0)
        {   
            console.log(this.state.messageList)
            currentMsgObj = {...this.processMessageList(this.state.messageList)}
            console.log(currentMsgObj)
        }
        // console.log(currentMsgObj)
        // console.log(ChatInfoStore.chatRoomInfo)
        // console.log(this.state.selectedContact.hasOwnProperty('reciever_id'))
        if(currentMsgObj.hasOwnProperty(this.state.selectedContact.reciever_id))
        {
            // console.log('receiver id')
        //    console.log(currentMsgObj[this.state.selectedContact.reciever_id])
        // remember to reverse the array to reveal the real sequence of the messages
           msgArr = ChatSocketHelper.sortMessageByDates([...currentMsgObj[this.state.selectedContact.reciever_id]])
        }

        if(currentMsgObj.hasOwnProperty(this.state.selectedContact.sender_id))
        {
            // console.log('sender id')
            msgArr = ChatSocketHelper.sortMessageByDates([...currentMsgObj[this.state.selectedContact.sender_id]])
        }

        // console.log(senderId)
        // console.log(this.state.selectedContact)

        // console.log(msgArr)
        // console.log(this.props)
        let matches = []
        // console.log(this.props.matches)
        if(this.props.connectedUsers.length > 0)
        {
            this.props.connectedUsers.forEach((m, idx) => {
                // console.log(m)
                matches.push(
                <ChatAvatarContainer 
                    key={`match-chat-${idx}`}
                    active={m.contact_id === this.state.selectedContact.contact_id || false}
                    contact={m}
                    currentSelectedContact={this.state.selectedContact}
                    senderId={senderId}
                    receiverId={m.reciever_id === senderId ? m.sender_id : m.reciever_id}
                    display_name={m.hasOwnProperty('reciever_display_name') ? m.reciever_display_name:m.sender_display_name} 
                    onClick={this.handleSwitchChannel} 
                />)
            })
            // console.log(this.state.messageList)
            // let ownMessages = [], otherMessages = [], selectedMsgs = this.state.messageList.hasOwnProperty(this.state.selectedContact.contact_id) ? this.state.messageList[this.state.selectedContact.contact_id] : []
            // this.state.messageList.forEach((msg, idx) => {

            // })

            /*get partner id */
            let partnerid=""
            if(this.state.selectedContact.reciever_id === AuthenticationHelper.uuid)
            {
                partnerid=this.state.selectedContact.sender_id
            }
            else
            {
                partnerid=this.state.selectedContact.reciever_id
            }

            return <Grid item className='my-4' style={{ height: '80vh' }}>
                <Grid container className='match-paper' style={{ height: '100%' }}>
                    <Grid item xs={3} className='match-avatar-container py-2 pb-4 right-border d-flex flex-column justfiy-content-flex-start'  style={{ height: '100%', overflowY: 'auto' }}>
                        <ChatHeader title={lang.yourConnection} />
                        {matches}
                    </Grid>
                    <Grid item xs={9} className='py-2' style={{ position: 'relative', height: '100%' }}>
                        <ChatInformationPanel contact={this.state.selectedContact} />
                    
                        {/*<BubbleGroup
                            messages={this.state.messages}
                            id={'test'}
                            showSenderName={true}
                        />*/}
                        
                        <div style={{ overflowY: 'auto', height: '70%' }}>
                            <ThemeProvider theme={themes}>
                            
                                <MessageList active>
                                    <MsgUnitGroup msgArr={msgArr || []} senderId={senderId} lang={lang} />
                                </MessageList>

                            </ThemeProvider>
                        </div>
                        <SendPanel className='w-100' style={{ bottom: '10px', position: 'absolute' }} rid={ ChatInfoStore.getRoomId(partnerid)} handleSendMessage={this.handleSendMessage} />

                    </Grid>
                </Grid>
            </Grid>
        }
        return (<Grid item className='my-4'>
            <Paragraph className='fyw-font-dark'>{lang.noContact}</Paragraph>
        </Grid>)
    }
}

const ChatHeader = props => {
    return (<Grid className='p-4 text-center'>
    <Text className='fyw-font-white'>
        {props.title.toUpperCase()}
    </Text>
    </Grid>)
}

const ChatAvatarContainer = props => {

    if(!props.hasOwnProperty('currentSelectedContact') && !props.currentSelectedContact.hasOwnProperty('contact_id') && props.currentSelectedContact.contact_id === '' 
    && !props.hasOwnProperty('contact') && !props.contact.hasOwnProperty('contact_id') && props.contact.contact_id === '') return null

    return (<div className={props.active ? `pl-4 d-flex align-items-center cursor-pointer match-chat-avatar-active`: `pl-4 d-flex align-items-center cursor-pointer`} onClick={() => props.onClick(props.contact)}>
        <ChatAvatar {...props} />
        <Tip className={props.active ? 'ml-2 my-auto p-0 fyw-font-dark':'ml-4 my-auto p-0 fyw-font-white'}>{props.display_name}</Tip>
    </div>)
}

const ChatAvatar = props => {
    // console.log(props)
    if(!props.hasOwnProperty('display_name') || props.display_name === null || props.display_name === undefined) return <CircularProgress />
    return (<div className='match-avatar-sm midgreen-avatar cursor-pointer my-2'>
        <Paragraph className='p-0 m-0'>{props.display_name.length > 1 ? `${props.display_name[0].toUpperCase()}${props.display_name[1].toUpperCase()}`:`${props.display_name[0].toUpperCase()}`}</Paragraph>
    </div>)
}

const ChatInformationPanel = props => {
    // console.log(props)
    if(!props.hasOwnProperty('contact') || Object.keys(props.contact).length < 0 || !props.contact.hasOwnProperty('contact_id') )
    {
        return <CircularProgress />
    }
    return (
    <Grid container direction='column'>
        
        <Grid item xs={12} className='d-flex justify-content-between align-items-center px-3'>
            <ChatAvatar display_name={props.contact.hasOwnProperty('reciever_display_name') ? props.contact.reciever_display_name:props.contact.sender_display_name} />
            <Tip>{props.contact.hasOwnProperty('reciever_display_name') ? props.contact.reciever_display_name:props.contact.sender_display_name}</Tip>
            <GetImg 
                className={['match-avatar-sm']}
                imageUrl={'chat-hug-icon.svg'}
                alt={'information'}
            />
        </Grid>

        <Grid item xs={12} className='chat-story-container pl-3 py-3'>
            <Tip className='p-0 m-0 fyw-font-grey'>{props.contact.hasOwnProperty('reciever_display_name') ? `${props.contact.reciever_age} ${GH.capitalizeFirstLetter(props.contact.reciever_gender)} ${props.contact.reciever_address}`:`${props.contact.sender_age} ${GH.capitalizeFirstLetter(props.contact.sender_gender)} ${props.contact.sender_address}`}</Tip>
            <Tip className='p-0 m-0 fyw-font-grey'>{props.contact.hasOwnProperty('reciever_display_name') ? `Lost relationship: ${props.contact.reciever_relationship}`:`Lost relationship: ${props.contact.sender_relationship}`}</Tip>
            <Tip className='p-0 m-0 fyw-font-grey'>{props.contact.hasOwnProperty('reciever_display_name') ? `${props.contact.reciever_loss_description}`:`${props.contact.sender_loss_description}`}</Tip>
        </Grid>
        
    </Grid>
    )
}

const SendPanel = props => {
    console.log(props)
    let [message, updateMessage] = useState('')
    return (<Grid item xs={12} className={props.className || ''} style={props.style || {}}>
        <TextInput 
            // ref={password => this._password = password}
            type={"text"}
            label={``}
            ariaLabel='Supportive message'
            placeholder={`Enter your message...`}
            // icon='envelope'
            withAdornment
            value={message}
            endAdornmentIcon={'paper-plane'}
            endAdornmentLabel='send'
            handleEndAdornmentClick={async () => {
                props.handleSendMessage(props.rid, message)
                updateMessage('')
                // props.sendSupportMsg(comment)
                // updateMsgSent(true)
            }} 
            // error={commentError}
            // helperText={`${comment.length}/250 ${props.lang.words}`}
            onChange={e => updateMessage(e.target.value)}
        />
    </Grid>)
}

const MsgUnitGroup = props => {
    if(props.msgArr === undefined) return null
    let msgUnit = []
    // console.log(props.senderId)
    // console.log(props.msgArr)
    // add username authorName={msg.u.name} 
    props.msgArr.forEach((msg, idx) => {
        const isOwn = props.senderId !== msg.u.username
        msgUnit.push(<Message 
                key={msg._id} 
                className={isOwn ?  idx === props.msgArr.length - 1 ? 'matchmaking-message-bubble last yours': 'matchmaking-message-bubble yours' : idx === props.msgArr.length - 1 ? 'matchmaking-message-bubble last mine': 'matchmaking-message-bubble mine' }
                // isOwn={isOwn}
            >
            <MessageText className='matchmaking-text'>{msg.msg}</MessageText>
        </Message>)
    })

    if(msgUnit.length > 0)
    {
        return (
            <MessageGroup >{msgUnit}</MessageGroup> 
        )
    }
    return <Tip className='fyw-font-grey'>{props.lang.noMessage}</Tip>
}

export default Connect