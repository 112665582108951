import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PG13 from '../ui/PG13'
import initLang from '../../lang/initLang'
import { addSystemFeedback, initConfirmMessage, storePayloadBeforeConfirm } from '../../actions/config'
import { addAnswer, clearAnswer, loadQuestionnaireAnswer } from '../../actions/survey'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['PG13'],
    auth: state.userReducer.auth,
    confirmMessage: state.configReducer.confirmMessage,
    systemFeedback: state.configReducer.systemFeedback,
    toConfirmPayload: state.configReducer.toConfirmPayload,
    PG13Answers: state.surveyReducer.PG13Answers,
})

const mapDispatchToProps = dispatch => ({
    addAnswer: (questionnaire, answerObj) => dispatch(addAnswer(questionnaire, answerObj)),
    clearAnswer: (questionnaire) => dispatch(clearAnswer(questionnaire)),
    loadQuestionnaireAnswer: (questionnaire, answerObj) => dispatch(loadQuestionnaireAnswer(questionnaire, answerObj)),
    addSystemFeedback: feedback => dispatch(addSystemFeedback(feedback)),
    initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
    storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload))
})

const Cont = connect(mapStateToProps, mapDispatchToProps)(PG13)
export default withRouter(Cont)