const matchConfig = {
  pages: {
      login: 'login',
      register: 'register',
      forgotPass: 'forgotPass',
      story: 'story',
      profile: 'profile',
      chat: 'chat'
  },
  form: {
      relationshipArr: [
          {
              value: '0',
              display: 'The deceased is my...'
          },
          {
            value: 'Partner',
            display: 'Partner'
          },
          {
              value: 'Grandparent',
              display: 'Grandparent'
          },
          {
              value: 'Parent',
              display: 'Parent'
          },
          {
              value: 'Offspring',
              display: 'Offspring'
          },
          {
              value: 'Sibling',
              display: 'Sibling'
          },
          {
              value: 'Grand child',
              display: 'Grand child'
          },
          {
              value: 'Aunt',
              display: 'Aunt'
          },
          {
              value: 'Uncle',
              display: 'Uncle'
          },
          {
              value: 'Niece',
              display: 'Niece'
          },
          {
              value: 'Nephew',
              display: 'Nephew'
          },
          {
            value: 'Friend',
            display: 'Friend'
          },
          {
              value: 'Other',
              display: 'Other'
          },
          {
            value: 'I want to support others',
            display: 'I want to support others'
        }
      ],
      genderArr: [
          {
              value: '0',
              display: 'Your sex/gender'
          },
          {
              value: 'Female',
              display: 'Female'
          },
          {
              value: 'Male',
              display: 'Male'
          },
          {
              value: 'Prefer not to disclose',
              display: 'Prefer not to disclose'
          }
      ],
      states: [
          {
            key: "0",
            value: "0",
            display: "Where do you live in the US"
          },
          {
            key: "AL",
            value: "AL",
            display: "Alabama (AL)"
          },
          {
            key: "AK",
            value: "AK",
            display: "Alaska (AK)"
          },
          {
            key: "AS",
            value: "AS",
            display: "American Samoa (AS)"
          },
          {
            key: "AZ",
            value: "AZ",
            display: "Arizona (AZ)"
          },
          {
            key: "AR",
            value: "AR",
            display: "Arkansas (AR)"
          },
          {
            key: "CA",
            value: "CA",
            display: "California (CA)"
          },
          {
            key: "CO",
            value: "CO",
            display: "Colorado (CO)"
          },
          {
            key: "CT",
            value: "CT",
            display: "Connecticut (CT)"
          },
          {
            key: "DE",
            value: "DE",
            display: "Delaware (DE)"
          },
          {
            key: "DC",
            value: "DC",
            display: "District Of Columbia (DC)"
          },
          {
            key: "FM",
            value: "FM",
            display: "Federated States Of Micronesia (FM)"
          },
          {
            key: "FL",
            value: "FL",
            display: "Florida (FL)"
          },
          {
            key: "GA",
            value: "GA",
            display: "Georgia (GA)"
          },
          {
            key: "GU",
            value: "GU",
            display: "Guam (GU)"
          },
          {
            key: "HI",
            value: "HI",
            display: "Hawaii (HI)"
          },
          {
            key: "ID",
            value: "ID",
            display: "Idaho (ID)"
          },
          {
            key: "IL",
            value: "IL",
            display: "Illinois (IL)"
          },
          {
            key: "IN",
            value: "IN",
            display: "Indiana (IN)"
          },
          {
            key: "IA",
            value: "IA",
            display: "Iowa (IA)"
          },
          {
            key: "KS",
            value: "KS",
            display: "Kansas (KS)"
          },
          {
            key: "KY",
            value: "KY",
            display: "Kentucky (KY)"
          },
          {
            key: "LA",
            value: "LA",
            display: "Louisiana (LA)"
          },
          {
            key: "ME",
            value: "ME",
            display: "Maine (ME)"
          },
          {
            key: "MH",
            value: "MH",
            display: "Marshall Islands (MH)"
          },
          {
            key: "MD",
            value: "MD",
            display: "Maryland (MD)"
          },
          {
            key: "MA",
            value: "MA",
            display: "Massachusetts (MA)"
          },
          {
            key: "MI",
            value: "MI",
            display: "Michigan (MI)"
          },
          {
            key: "MN",
            value: "MN",
            display: "Minnesota (MN)"
          },
          {
            key: "MS",
            value: "MS",
            display: "Mississippi (MS)"
          },
          {
            key: "MO",
            value: "MO",
            display: "Missouri (MO)"
          },
          {
            key: "MT",
            value: "MT",
            display: "Montana (MT)"
          },
          {
            key: "NE",
            value: "NE",
            display: "Nebraska (NE)"
          },
          {
            key: "NV",
            value: "NV",
            display: "Nevada (NV)"
          },
          {
            key: "NH",
            value: "NH",
            display: "New Hampshire (NH)"
          },
          {
            key: "NJ",
            value: "NJ",
            display: "New Jersey (NJ)"
          },
          {
            key: "NM",
            value: "NM",
            display: "New Mexico (NM)"
          },
          {
            key: "NY",
            value: "NY",
            display: "New York (NY)"
          },
          {
            key: "NC",
            value: "NC",
            display: "North Carolina (NC)"
          },
          {
            key: "ND",
            value: "ND",
            display: "North Dakota (ND)"
          },
          {
            key: "MP",
            value: "MP",
            display: "Northern Mariana Islands (MP)"
          },
          {
            key: "OH",
            value: "OH",
            display: "Ohio (OH)"
          },
          {
            key: "OK",
            value: "OK",
            display: "Oklahoma (OK)"
          },
          {
            key: "OR",
            value: "OR",
            display: "Oregon (OR)"
          },
          {
            key: "PW",
            value: "PW",
            display: "Palau (PW)"
          },
          {
            key: "PA",
            value: "PA",
            display: "Pennsylvania (PA)"
          },
          {
            key: "PR",
            value: "PR",
            display: "Puerto Rico (PR)"
          },
          {
            key: "RI",
            value: "RI",
            display: "Rhode Island (RI)"
          },
          {
            key: "SC",
            value: "SC",
            display: "South Carolina (SC)"
          },
          {
            key: "SD",
            value: "SD",
            display: "South Dakota (SD)"
          },
          {
            key: "TN",
            value: "TN",
            display: "Tennessee (TN)"
          },
          {
            key: "TX",
            value: "TX",
            display: "Texas (TX)"
          },
          {
            key: "UT",
            value: "UT",
            display: "Utah (UT)"
          },
          {
            key: "VT",
            value: "VT",
            display: "Vermont (VT)"
          },
          {
            key: "VI",
            value: "VI",
            display: "Virgin Islands (VI)"
          },
          {
            key: "VA",
            value: "VA",
            display: "Virginia (VA)"
          },
          {
            key: "WA",
            value: "WA",
            display: "Washington (WA)"
          },
          {
            key: "WV",
            value: "WV",
            display: "West Virginia (WV)"
          },
          {
            key: "WI",
            value: "WI",
            display: "Wisconsin (WI)"
          },
          {
            key: "WY",
            value: "WY",
            display: "Wyoming (WY)"
          }
        ]
  }
}

export default matchConfig