import React, { Component } from 'react'
// import Match from '../containers/Match'
// import Auth from '../containers/Auth'
import Grid from '@material-ui/core/Grid'
import Register from './Auth/Register'
import NavSection from '../containers/NavBar'
import { TitleSecion } from '../../assets/UIElements'

import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import matchConfig from '../../config/matchConfig'
import init from '../../config/init'

class RegisterSection extends Component {
    
    constructor(props)
    {
        super(props)
        this.switchToLogin = this.switchToLogin.bind(this)
    }

    async componentWillMount()
    {
        // console.log(`main component mounted`)
        // const loggedIn = await AuthenticationHelper.hasAuthenticated()
        // if(!loggedIn) this.props.switchPage(matchConfig.pages.login)
        this.props.switchPage(matchConfig.pages.register)
        // console.log(`page ${this.props.page}`)
        // console.log(AuthenticationHelper.uuid)
        // console.log(AuthenticationHelper.token)
    }
    switchToLogin(login = false)
    {
        // const page = login ? matchConfig.pages.login : matchConfig.pages.register
        // this.props.switchPage(matchConfig.pages.register)
        this.props.history.push(init.navItem.index.url)
    }
    render()
    {
        // console.log(this.props)

        // if(!this.props.profile.hasOwnProperty('uuid') 
        // || this.props.profile.uuid === ''
        // || !AuthenticationHelper.hasAuthenticated()) return <NavSection>
        //     <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
        //     <Auth />
        // </NavSection>

        switch(this.props.page)
        {
            case matchConfig.pages.profile:
            case matchConfig.pages.story:
            case matchConfig.pages.chat:
            case matchConfig.pages.login:
            case matchConfig.pages.register:
            case matchConfig.pages.forgotPass:
            default:
                return <NavSection>
                    <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />
                    <Grid>
                        <Register lang={this.props.lang.auth} switchToLogin={this.switchToLogin} hideLogin={true} />
                    </Grid>
                </NavSection>
        }
    }
}

export default RegisterSection