import methods from '../methods'
import { combineReducers } from 'redux'
// import FetchWrapper from '../utilities/FetchWrapper'
// const Fetch = new FetchWrapper()

const authState = {
    // username: '',
    accessToken: '',
    isLoggedIn: false
}

const session = (state = {}, action) => {
  switch (action.type) {
    case methods.LOAD_SESSION:
      return action.payload
    case methods.CLEAR_SESSION:
      return {}
    default: return state
  }
}

const auth = (state = authState, action) => {
  switch (action.type) {
    case methods.LOGIN:
      // call the API to login and check username
      return action.payload
    case methods.LOGOUT:
      // console.log('logout called')
        return authState
    default:
      return state
  }
}

const loginErr = (state = {}, action) => {
  switch(action.type) {
    case methods.LOGIN_ERR:
      return action.payload
    case methods.CLEAR_LOGIN_ERR:
        return {}
    default:
      return state
  }
}

const registerFeedback = (state = '', action) => {
  switch(action.type) {
    case methods.REGISTER_MSG:
      return action.payload
    case methods.CLEAR_REGISTER_ERR:
      return ''
    default:
      return state
  }
}

let defaultAccountInfo = {
  nickname: 'Traveler',
  deceased: 'The Loved One'
}
const accountInfo = (state = defaultAccountInfo, action) => {
  switch(action.type) {
    case methods.LOAD_ACCOUNT_INFO:
      return action.payload !== null ? action.payload : defaultAccountInfo
    case methods.LOGOUT:
      return defaultAccountInfo
    default: return state
  }
}

export default combineReducers({ session, auth, loginErr, registerFeedback, accountInfo })
  