const paths = {
    index: '/',
    pg13: '/pg13',
    bci: '/bci',
    register: '/register',
    connecting: '/connecting-with-others',
    resources: '/resources',
    contact: '/contact',
    coping: '/coping-module'
}

export default paths