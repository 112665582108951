import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Text, Tip } from '../../../assets/Text'
import STM from '../../../helpers/TimeManage'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * usage:
    <SupportiveMessages key={`match-supportive-messages-${idx}`} display_name={msg.sender_display_name} message={msg.message} time={msg.sent_time} />
 */

const SupportiveMessages = props => {
    let date = new Date()
    if(props.hasOwnProperty('time') && props.time !== null)
    {
        date = new Date(props.time)
    }
    return (<><Grid item xs={10} className='d-flex mx-auto my-3'>

        <Grid item xs={2}>
            <div className='match-avatar-sm mx-auto'>
                <Text className='p-0 m-0'>{props.display_name.length > 1 ? `${props.display_name[0].toUpperCase()}${props.display_name[1].toUpperCase()}`:`${props.display_name[0].toUpperCase()}`}</Text>
            </div>
        </Grid>

        {props.enableDelete ? 
            <Grid item xs={10} className='supportive-message-bubble p-3 d-flex justify-content-between align-items-center'>
                <Grid item xs={11}><Text className='p-0 m-0'>{props.message}</Text></Grid>
                <Tooltip title="Delete" placement="top-end">
                    <IconButton aria-label="delete" onClick={props.handleDelete} >
                    <DeleteIcon />
                    </IconButton>
                </Tooltip>
                {/*<Grid item xs={1} className='text-center cursor-pointer'><Icon className='fas fa-times' onClick={props.handleDelete} /></Grid>*/}
            </Grid>
            :
            <Grid item xs={10} className='supportive-message-bubble p-3 d-flex justify-content-between align-items-center'>
                <Text className='p-0 m-0'>{props.message}</Text>
            </Grid>
        }


        </Grid>
        
        <Grid item xs={10} className='mx-auto d-flex justify-content-end'>
            <Tip>{STM.convertTimeToString(date)}</Tip>
        </Grid>
    
    </>)
}


export default SupportiveMessages