import React, { Component } from 'react'
// import GetImg from '../../assets/GetImg'
import NavSection from '../containers/NavBar'
import Amplify, { Auth } from 'aws-amplify'
import { TextInput } from '../../assets/Form'
import { Divider } from '../../assets/UIElements'
import { Tip, Text, Title, Subtitle, Paragraph } from '../../assets/Text'
import { Authenticator } from 'aws-amplify-react'
import AmplifyTheme from './AmplifyTheme'
import config from '../../config/config'
import init from '../../config/init'
import '../../scss/amplify-theme.scss'

Amplify.configure(config.aws)


class Login extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            signUpConfig: {}
        }
        this.handleAuthStateChange = this.handleAuthStateChange.bind(this)
    }

    componentDidMount()
    {
        const signUpConfig = {
            header: this.props.lang.register,
            hideAllDefaults: true,
            defaultCountryCode: '1',
            signUpFields: [
                {
                    label: 'Email Address',
                    key: 'username',
                    required: true,
                    displayOrder: 1,
                    type: 'string'
                },
                {
                    label: 'Password',
                    key: 'password',
                    required: true,
                    displayOrder: 2,
                    type: 'password'
                }
            ]
        }
        this.setState({
            signUpConfig
        })
    }

    /**
     * 
     * @param {str} authState 
     * @param {obj} data

     - signIn
    - signUp
    - confirmSignIn
    - confirmSignUp
    - forgotPassword
    - requireNewPassword
    - verifyContact
    - signedIn
    - signedUp
     */
    async handleAuthStateChange(authState)
    {
        // console.log(authState)
        switch(authState)
        {
            case init.authState.signedIn:
                const userInfo = await Auth.currentUserInfo().then(r => r).catch(e => e)
                const userSession = await Auth.currentSession().then(r => r).catch(e => e)
                const authUser = await this.props.login(userInfo, userSession)
                // console.log(authUser)
                // console.log(authUser)
                // this.props.history.push('/connecting-with-others')
                break
            case init.authState.signedUp:
                break
            case init.authState.signUp:
                break
            case init.authState.signIn:
            default:
                // console.log(authState)
                // console.log(Auth.currentUserInfo())
                // const userSession = await Auth.currentSession().then(r => r).catch(e => e)
                // console.log(userInfo)
                // console.log(userSession)
        }
    }

    render()
    {
        // console.log(this.props)
        // console.log('rendered')
        return (<NavSection>
            <div className="row text-center m-auto">
            <Subtitle className='mx-auto mt-5'>{this.props.lang.loginTitle.toUpperCase() || ''}</Subtitle>
            </div>
            <Divider />
            <Paragraph className="fyw-font-dark mx-auto text-left" style={{width: '80%'}}>
                {this.props.lang.loginSubtitle || ''}
            </Paragraph>
            <Authenticator 
                usernameAttributes='email'
                authState={init.authState.signIn}
                authData={'email'} 
                theme={AmplifyTheme}
                onStateChange={(authState) => this.handleAuthStateChange(authState)} 
                signUpConfig={this.state.signUpConfig}
            >
            </Authenticator>
        </NavSection>)
    }
}

export default Login