import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Figure } from '../../../assets/Figure'
import { Text, Subtitle, Paragraph } from '../../../assets/Text'
import { Divider } from '../../../assets/UIElements'
import { Button } from '../../../assets/Buttons'
import init from '../../../config/init'
import '../../../scss/coping-module.scss'

const figureObj = {
    feelingJudged: {
        imgUrl: 'permission1_feeling_judged-01.png',
        alt: 'feeling judged',
        content: 'It’s natural for people to care about what others think of them.  Sometimes people worry that experiencing or showing their feelings will make other people think they are weak or seeking attention. But we expect that losing someone important in your life will be devastating and deeply distressing.'
    },
    goingCrazy: {
        imgUrl: 'permission_going_crazy-01.png',
        alt: 'permission going crazy',
        content: 'One way to help us cope with these fears is to understand where they come from. How we feel always makes sense. Maybe you have felt like others expect you to be “strong,” or that others need you to be “okay”. Maybe you are afraid that if people think you’re emotional, they will think you’re “crazy” or they might be able to hurt you.'
    },
    griefIsLove: {
        imgUrl: 'permission_grief_is_love-01.png',
        alt: 'grief is love',
        content: 'When we lose someone so close to us, it often just doesn’t feel real. It feels like it just can’t be. You may feel like this person is not really gone, or that you’re not ready to let him/her go and still need him/her. This is so common after losing someone who has been so central and important to you. When people say, ‘Time heals,’ it’s not because time itself has magical healing powers. It’s because with time, we come across more and more evidence that the person we love isn’t physically around.'
    },
    selfCompassion: {
        imgUrl: 'self_compassion-01.png',
        alt: 'self compassion',
        content: 'Of course, these reminders can be excruciating. And so it’s common to just want to avoid them, to want to avoid the pain. The problem is that while avoiding our feelings works in the short-term—a temporary block to the pain—it can make things even more painful in the long-term because each time you come across a reminder – which may even just be a thought that runs through your mind – the pain comes flooding back, and you may feel less and less like you can handle that pain. '
    },
    surfTide: {
        imgUrl: 'surf_grief_tide-01.png',
        alt: 'surf the grief tide',
        content: 'So how can you feel more like you can cope with the pain, that you can be in the world without this person physically here? You can do that by practicing being with difficult feelings. Grief often comes in waves. When we try to get away from a wave that is coming, it can topple over us and feel like we’re drowning. So, instead of trying to avoid the wave, we can try to ride the wave or even surf the wave. We move with the feelings that come, or even learn to maneuver as they come. When we practice moving with the grief and take a stance that we can surf these waves when they come, that we can tolerate the feelings.'
    }
}

const IntenseFeelings = props => {
    let figures = [], chevronWidth = '100%'
    const [activeItemIndex, setActiveItemIndex] = useState(0)
    Object.keys(figureObj).map((key, idx) => {
        figures.push(
        <Figure
            key={`connecting-with-others-figure-${idx}`}
            className="mx-auto col-md-8 col-sm-8 py-2 mt-4"
            src={figureObj[key]['imgUrl']}
            alt={figureObj[key]['alt']}
        >{figureObj[key]['content']}</Figure>)
    })
    // console.log(props)
    return(
    <Grid item xs={12} className="mt-5">

        <Paragraph className='fyw-font-dark fyw-font-bold text-center'>{props.lang.intenseFeelings.title}</Paragraph>
        <Divider />

        <Paragraph className='fyw-font-dark text-center'>
        {props.lang.intenseFeelings.subtitle}
        </Paragraph>
        {figures}
        {/*<ItemsCarousel
            infiniteLoop={false}
            gutter={6}
            activePosition={'center'}
            chevronWidth={200}
            activeItemIndex={activeItemIndex}
            disableSwipe={false}
            alwaysShowChevrons={true}
            numberOfCards={2}
            numberOfChildren={Object.keys(figureObj).length}
            outsideChevron={true}
            showSlither={true}
            firstAndLastGutter={true}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={<button className="carousel-button">{'>'}</button>}
            leftChevron={<button className="carousel-button">{'<'}</button>}
            children={figures}
        >
        </ItemsCarousel>*/}
        <Button className='fyw-btn-light my-5 mx-auto' onClick={e => {
            props.switchComponent(init.modules.connecting, init[init.modules.connecting].components.intenseFeelings, init[init.modules.connecting].components.copingCards, { completed: true })
        }}>{props.lang.next}</Button>
    </Grid>)
}

export default IntenseFeelings