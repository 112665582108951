import Amplify, { Analytics, Storage, Auth } from 'aws-amplify'
import config from '../config/config'
import GH from './GeneralHelper'

// const config = init
const storageLevel = 'private'
Amplify.configure(config.aws)
Storage.configure({ level: storageLevel, track: true })

class AmplifyHelper {
    constructor()
    {

    }
    async signUp(auth)
    {
        try
        {
            let signupResult = await Auth.signUp({
                username: auth.email,
                password: auth.password
            })
            return signupResult
        }
        catch(err)
        {
            return err
        }
    }
    signIn(auth)
    {
        return Auth.signIn({
            username: auth.username, // Required, the username
            password: auth.password, // Optional, the password
        })
        .then(data => {
            // console.log(data)
            return data
        })
        .catch(err => {
            // console.error(err)
            throw err
        })
    }
    signOut(global = false)
    {
        if(!global)
        {
            return Auth.signOut()
                .then(data => {
                    // console.log(data)
                    return data
                })
                .catch(err => {
                    // console.error(err)
                    return err
                })
        }
        // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
        // which means the user is signed out from all the devices
        // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
        return Auth.signOut({ global: true })
                .then(data => {
                    // console.log(data)
                    return data
                })
                .catch(err => {
                    // console.error(err)
                    return err
                })
    }
    
    getSession()
    {
        return Auth.currentSession()
        .then(data => {
            // console.log(data)
            return data
        })
        .catch(err => {
            // console.error(err)
            return err
        })
    }

    getUser()
    {
        return Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => user )
        .catch(err => err );
    }

    async resendVerificationCode(username)
    {
        try
        {
            const resendResult = await Auth.resendSignUp(username)
            if(resendResult.hasOwnProperty("CodeDeliveryDetails")) return true
            throw false
        }
        catch(err)
        {
            return false
        }
    }
    async confirmSignUp(username, code)
    {
        try
        {
            const confirmResult = await Auth.confirmSignUp(username, code, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true    
            })
            if(confirmResult.hasOwnProperty("code"))
            {
                throw confirmResult
            }
            return confirmResult
        }
        catch(err)
        {
            return err
        }
    }
    upload(filename, content, attrs)
    {
        return Storage.put(filename, content, attrs)
        .then(result => result )
        .catch(err => err )
    }

    get(filename, attrs)
    {
        return Storage.get(filename, attrs)
        .then(result =>  result )
        .catch(err => err )
    }

    remove(filename)
    {
        return Storage.remove(filename, {level: storageLevel})
        .then(result => result)
        .catch(err => err);
    }

    async getAllImages(folder = 'pictures/')
    {
        let finalArr = await Storage.list(folder || 'pictures/', { level: storageLevel })
        .then(async imgArr => {
            let newArr = []
            if(imgArr.length > 0)
            {
                newArr = [...imgArr]
                const imgLink = await GH.asyncForEach(imgArr, async (img, idx) => {
                    const url = await Storage.get(img.key, { level: storageLevel }).then(r => r).catch(e => false)
                    // console.log(url)
                    let link = await Storage.get(img.key, { level: storageLevel, download: true }).then(r => r).catch(e => false)
                    if(link)
                    {
                        // console.log(link)
                        link.url = url
                        delete link["Body"]
                        newArr[idx].src = link
                    }
                })
                return newArr
            }
            return newArr
        })
        .catch(err => [])
        return finalArr
    }

    async downloadFile(key = '')
    {
        let file = await Storage.get(key, { level: storageLevel, download: true }).then(r => r).catch(e => false)
        return file["Body"]
    }

    async getAllMusics(folder = 'music-list/')
    {
        let finalArr = await Storage.list(folder || 'music-list/', { level: storageLevel })
        .then(async imgArr => {
            let newArr = []
            if(imgArr.length > 0)
            {
                newArr = [...imgArr]
                const imgLink = await GH.asyncForEach(imgArr, async (img, idx) => {
                    const link = await Storage.get(img.key, { level: storageLevel }).then(r => r).catch(e => false)
                    if(link)
                    {
                        // console.log(link)
                        newArr[idx].src = link
                    }
                })
                return newArr
            }
            return newArr
        })
        .catch(err => [])
        return finalArr
    }
}

export default new AmplifyHelper()