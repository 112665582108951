import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paragraph, Subtitle } from './Text'
import { RadioSet, Select, FormGroup, FormTextInput, DatePicker } from './Form'
import { Button } from './Buttons'
import { Divider, TitleSecion } from './UIElements'

//create a PG-13 questionnaire
const Questionnaire = (props) => {
    // console.log(props.inComplete)
    let renderArr = []
    const [loading, setLoading] = useState(false)

    props.items.forEach((item, idx) => {
        // console.log(item.id)
        // console.log(props.inComplete.includes(item.id))
        switch(item.type)
        {
            case 'radio-inline':
                return renderArr.push(
                <FormGroup 
                    className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} 
                    key={`${idx}-${item.name}`}
                    inComplete={props.hasOwnProperty('inComplete') ? props.inComplete.includes(item.id) ? true : false : false}
                >
                    <RadioSet 
                        key={`${item.id}-${item.name}-${idx}`}
                        id={item.id}
                        qid={item.id}
                        value={item.value}
                        title={item.title}
                        display={item.display}
                        checked={false}
                        name={item.name}
                        inline={true}
                        options={item.options}
                        handleInput={props.handleInput}
                        inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                        questionnaire={props.questionnaire}
                    />
                </FormGroup>)
            case 'radio':
                return renderArr.push(
                <FormGroup 
                    className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} 
                    key={`${idx}-${item.name}`}
                    inComplete={props.hasOwnProperty('inComplete') ? props.inComplete.includes(item.id) ? true : false : false}
                >
                    <RadioSet 
                        key={`${item.id}-${item.name}-${idx}`}
                        id={item.id}
                        qid={item.id}
                        value={item.value}
                        title={item.title}
                        display={item.display}
                        checked={false}
                        name={item.name}
                        options={item.options}
                        handleInput={props.handleInput}
                        inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                        questionnaire={props.questionnaire}
                    />
                </FormGroup>)
            case 'select':
                return renderArr.push(
                <FormGroup 
                    className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} 
                    key={`${idx}-${item.name}`}
                    inComplete={props.hasOwnProperty('inComplete') ? props.inComplete.includes(item.id) ? true : false : false}
                >
                    <Select 
                        key={`${item.id}-${item.name}-${idx}`}
                        options={item.options}
                        id={item.id}
                        qid={item.id}
                        title={item.title}
                        name={item.name}
                        handleInput={props.handleInput}
                        inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                        questionnaire={props.questionnaire}
                    />
                </FormGroup>)
            case 'input':
                return renderArr.push(
                <FormGroup 
                    className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} 
                    key={`${idx}-${item.name}`}
                    inComplete={props.hasOwnProperty('inComplete') ? props.inComplete.includes(item.id) ? true : false : false}
                >
                <FormTextInput 
                    key={`${item.id}-${item.name}-${idx}`}
                    options={item.options}
                    id={item.id}
                    qid={item.id}
                    title={item.title}
                    name={item.name}
                    handleInput={props.handleInput}
                    label={item.display}
                    placeholder={item.display}
                    inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                    questionnaire={props.questionnaire}
                />
            </FormGroup>)
            case 'datepicker':
                return renderArr.push(
                <FormGroup 
                    className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} 
                    key={`${idx}-${item.name}`}
                    inComplete={props.hasOwnProperty('inComplete') ? props.inComplete.includes(item.id) ? true : false : false}
                >
                <DatePicker 
                    key={`${item.id}-${item.name}-${idx}`}
                    options={item.options}
                    id={item.id}
                    qid={item.id}
                    title={item.title}
                    name={item.name}
                    handleInput={props.handleDateInput}
                    inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                    questionnaire={props.questionnaire}
                />
            </FormGroup>)
        }
    })

    return(<div className="my-5 w-100">
        <TitleSecion title={props.title.toUpperCase()} subtitle={props.description} />
        {/*<div className="row text-center m-auto">
            <Subtitle className='mx-auto mt-5'>{props.title.toUpperCase()}</Subtitle>
        </div>

        <Divider />

        <Paragraph className="fyw-font-dark mx-auto text-left" style={{width: '80%'}}>
            {props.description}
</Paragraph>*/}

        <form>
            <div className='col-sm-12 col-md-10 mx-auto '>
                {renderArr}
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 secondary-btn-container mx-auto d-flex justify-content-center align-items-center'>
                <Button className="btn btn-lg btn-block fyw-primary-btn fyw-btn-dark" type="submit" onClick={(e) => {
                    props.handleStore(e, props.questionnaire)
                    setLoading(true)
                }}>Submit</Button>
                {loading && !props.feedback ? <CircularProgress size={24} className="btn-circular-loader" /> : null}
            </div>
        </form>

    </div>)
}


export default Questionnaire