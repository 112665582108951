import jstz from 'jstz'
const moment = require('moment-timezone')

class SystemTimeManagement {
    constructor() {
        this.timezone = jstz.determine()
    }

    getCurrentLocalTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatLocalTime('', false);
        }
        return this.formatLocalTime('', true);
    }
    getCurrentUTCTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatUTCTime('', false);
        }
        return this.formatUTCTime('', true);
    }

    getTimezoneName(promise = true) {
        if(promise)
        {
            return new Promise(res => {
                return res(this.timezone.name());
            });
        }
        return this.timezone.name();
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getFullYear();
            var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
            var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
            var h = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
            var mm = now.getMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getMinutes();
            var s = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        var h = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
        var mm = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
        var s = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalDate(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getFullYear();
            var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
            var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
            const completeStr = `${y}-${m}-${d}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        const completeStr = `${y}-${m}-${d}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }


    // get the current time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatUTCTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getUTCFullYear();
            var m = now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
            var d = now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
            var h = now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours();
            var mm = now.getUTCMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getUTCMinutes();
            var s = now.getUTCSeconds() < 10 ? "0" + now.getUTCSeconds() : now.getUTCSeconds();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getUTCFullYear();
        var m = now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
        var d = now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
        var h = now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours();
        var mm = now.getUTCMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getUTCMinutes();
        var s = now.getUTCSeconds() < 10 ? "0" + now.getUTCSeconds() : now.getUTCSeconds();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    /**
     * convert the local timestring to UTC timestring
     * @param {timestring} LocalTime 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertLocaltoUTC("2019-02-05T14:48:37-05:00")
        .then(UTCTime => {
            // do something
            // if you want UTC time string
            STM.formatUTCTime(UTCTime);
        });
     */
    convertLocaltoUTC(LocalTime, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(LocalTime).tz(tzName).utc().format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }

    /**
     * convert the UTC timestring to local timestring
     * @param {timestring} UTC 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertUTCtoLocal("2019-02-05T14:48:37-05:00")
        .then(LocalTime => {
            // do something
            // if you want UTC time string
            STM.formatLocalTime(LocalTime);
        });
     */
    convertUTCtoLocal(UTC, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(UTC).tz(tzName).format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }
    /**
     * It's always a good practice to convert the time to unix timestring when storing in the MySQL database
     * @param {str} timestamp
     * @param {func} onResults
     * usage:
        var timestamp = '2019-02-26 03:12:06';

        // optional: combine with getting the timezone of localtime
        const timezone = STM.getTimezoneName(false); // do not return a promise
        timestamp += ` ${timezone}`;

        STM.convertToUnixTime(timestamp, unixTime => {
            console.log(unixTime); // 1551150726
        });

     */
    convertToUnixTime(timestamp = '', onResults) {
        if(!timestamp) return onResults(false);
        let day = new Date(timestamp);
        day = Math.floor(day.getTime() / 1000);
        return onResults(day);
    }
    /**
     * convert the unix timestring to timestamp for MySQL database
     * @param {int} unixTime 
     * @param {str} type ('Local' or 'UTC')
     * @param {func} onResults 
     * usage:
        STM.convertUnixTimeToTimestamp(unixTime, 'UTC', timestamp => {
            console.log(timestamp); // 2019-02-25 22:12:06
        });
     */
    convertUnixTimeToTimestamp (unixTime = null, type = 'UTC',onResults) {
        if(!unixTime) return onResults(false);
        let day = new Date(unixTime * 1000);
        switch(type)
        {
            case 'Local':
                day = this.formatLocalTime(day);
                return onResults(day);
            case 'UTC':
            default:
                day = this.formatUTCTime(day);
                return onResults(day);
        }
    }

    /**
     * return true if day 2 is {howmany} hours behind day 1
     * @param {timestamp} d1 
     * @param {timestamp} d2 
     * @param {int} hrs 
     * usage:
        let d1 = '2018-09-20 16:08:06';
        let d2 = '2018-09-20 20:55:16';
        let result = STM.compareDate(d1, d2, 3);
        console.log(result) // true
    */
    compareDate(d1, d2, hrs = 12) {
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        var diff = (date2 - date1) / 1000 / 60 / 60; // convert the time to hours
        if(diff < hrs) // day 2 time is before day 1
        {
            return false;
        }
        return true;
    }
    /** Calculate the duration of between two time stamps */
    calcDuration(t1, t2) {
        let h,m,s;
        // parse the time and get the timestring in milliseconds .getTime()
        const t1Str = new Date(t1);
        const t2Str = new Date(t2);
        // minuse the time
        let result = t2Str - t1Str;
        // get the time in format (h:m:s) - 1 second is 1000 milliseconds
        //Step1: check if it's over 1 hour - very rare, but if it's over 1 hour, then divide it by 3600000
        //Step2: check if it's over 1 minute - rare, divide it by 60000
        //Step3: if it's over 1 second - divide it by 1000
        if(result >= 3600000)
        {
            h = Math.floor(result / 3600000)
            var hrmd = result%3600000
            m = Math.floor(hrmd/60000)
            var mrmd = hrmd%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 60000 & result < 3600000)
        {
            h = 0
            m = Math.floor(result/60000)
            var mrmd = result%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 1000 & result < 60000)
        {
            h = 0; m = 0
            s = Math.floor(result/1000)
            return `${h}:${m}:${s}`
        }
        h = 0; m = 0
        s = Math.floor(result/1000)
        return `${h}:${m}:${s}`
    }
    calcDaysOfVisit(since) {
        const from = moment(since);
        const now = moment(new Date());
        return now.diff(from, 'days');
    }
    // STM.convertTimeToString(new Date('2020-3-30')) => "March 30, 2020"
    convertTimeToString(d = new Date()) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        const y = d.getFullYear()
        const m = monthNames[d.getMonth()]
        const day = d.getDate()
        return `${m} ${day}, ${y}`
    }
}

export default new SystemTimeManagement()