import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput, SelectItem, TextArea, Checkbox } from '../../../assets/Form'
import { Paragraph, Text, Tip } from '../../../assets/Text'
import { Alert } from '../../../assets/UIElements'
import { Button } from '../../../assets/Buttons'
import AuthenticationHelper from '../../../helpers/AuthenticationHelper'
// import AVH from '../../../helpers/AuthValidateHelper'
import matchConfig from '../../../config/matchConfig'
import UserProfileHelper from '../../../helpers/UserProfileHelper'
import '../../../scss/signup.scss'
// import GetImg from '../../../assets/GetImg'

const relationshipArr = matchConfig.form.relationshipArr
const gender = matchConfig.form.genderArr
const states = matchConfig.form.states


const EditProfile = props => {
    // console.log(props)
    let profile = props.profile
    const [name, updateName] = useState(profile.display_name)
    // const [birthYear, updateBirthYear] = useState()
    const [deceased, updateDeceased] = useState(profile.relationship)
    const [sex, updateSex] = useState(profile.gender)
    const [location, updateLocation] = useState(profile.state_address)
    const [story, updateStory] = useState(profile.loss_description)
    // const [disclaimer, checkDisclaimer] = useState(false)

    //validate submitted content
    const [nameError, updateNameError] = useState('')
    const [birthError, updateBirthError] = useState('')
    const [deceasedError, updateDeceasedError] = useState('')
    const [sexError, updateSexError] = useState('')
    const [locationError, updateLocationError] = useState('')
    const [storyError, updateStoryError] = useState('')
    
    //register error
    const [editError, updateEditError] = useState('')
    const [registerSuccessful, updateSuccess] = useState(false)

    if(!registerSuccessful)
    {
      return (<Grid container className='mt-3 mx-auto'>
      <Grid item xs={12}  className='mx-auto'>
          <div className='mx-auto'>
          <Tip className='fyw-font-grey'>Please fill in the basic information to share your story with others. * are required fields</Tip>
          <form>
              <TextInput 
                id='match-displayname'
                // ref={pass => this._password = pass}
                type={"text"}
                label='* Your Display Name'
                ariaLabel='display name'
                value={name}
                icon='user-alt'
                withAdornment={false}
                placeholder='Your display name'
                error={nameError}
                onChange={e => {
                  updateNameError('')
                  updateName(e.target.value)
                }}
              />
              <SelectItem 
                  options={relationshipArr}
                  id="match-relationship"
                  label="* Relationship to deceased: the deceased is my..."
                  name="relationship"
                  inputValue={deceased}
                  error={deceasedError}
                  handleInput={e => {
                    updateDeceasedError('')
                    updateDeceased(e.target.value)
                  }}
              />
              <SelectItem 
                  options={gender}
                  id="match-gender"
                  label="* What is your sex/gender"
                  name="gender"
                  inputValue={sex}
                  error={sexError}
                  handleInput={e => {
                    updateSexError('')
                    updateSex(e.target.value)
                  }}
              />
              <SelectItem 
                  options={states}
                  id="match-states"
                  label="* Where do you live in the US"
                  name="states"
                  inputValue={location}
                  error={locationError}
                  handleInput={e => {
                    updateLocationError('')
                    updateLocation(e.target.value)
                  }}
              />
              <TextArea 
                  type={"text"}
                  label='* Please use this space to describe your loss experience.'
                  placeholder='What you write here will be shared on the ‘Stories’ page where users who have a similar type of loss can read and respond to it.'
                  ariaLabel='story'
                  error={storyError}
                  helperText={`${story.length}/750 words`}
                  value={story}
                  onChange={e => {
                    // console.log(e.target.value.length)
                    if(e.target.value.length > 750)
                    {
                      updateStoryError(`You have exceed the word limit. ${story.length}/750 words`)
                      updateStory(e.target.value)
                      return
                    }
                    updateStoryError('')
                    updateStory(e.target.value)
                  }}
                  rows={4}
              />
              <Button tabIndex='0' className='mx-auto mt-4' onClick={async () => {
                // first check if all the items are filled
                if(name === '') updateNameError('Your display name is required')
                if(deceased === '' || deceased === '0') updateDeceasedError('Please select your relationship with the deceased')
                if(sex === '' || sex === '0') updateSexError('Please indicate your gender')
                if(location === '' || location === '0') updateLocationError('Please indicate your location')
                if(story === '') updateStoryError('This field is required')
  
                if(name !== '' && deceased !== '' && deceased !== '0' && sex !== '' && sex !== '0'
                  && location !== '' && location !== '0' && story !== '')
                {
                  // console.log('you can register')
                //   const d = new Date()
                //   const y = d.getFullYear()
                //   const age = Math.abs(y - birthYear).toString()
                    const userObj = {
                    "displayName": name,
                    "relationship": deceased,
                    "gender": sex,
                    "state": location,
                    "lossExperience": story
                    }
                    // console.log(userObj)
                    const editResult = await UserProfileHelper.updateProfileById(AuthenticationHelper.uuid, userObj)
                    
                    if(editResult.hasOwnProperty('status') && editResult.status === 'success')
                    {
                        await props.updateProfile(editResult.addedData)
                        updateSuccess(true)
                        return
                    }

                    return updateEditError('The system has encountered a problem while updating your profile. Please try again later or contact the system manager.')

                }
              }}>Submit</Button>
          </form>
          {editError !== '' ? <Grid className='w-100 d-flex justify-content-center'>
              <Alert><Text className='fyw-font-dark mx-auto'>{editError}</Text></Alert>
            </Grid>
          :null}
          </div>
      </Grid>
          
      </Grid>)
    }
    return <Grid container className='mt-3 mx-auto'>
    <Grid item xs={12} sm={10} md={8} className='mx-auto'>
      <Grid className='mx-auto text-center'>
        <Paragraph className='fyw-font-dark fyw-font-bold my-5'>Your profile is updated!</Paragraph>
      </Grid>
    </Grid>
    </Grid>

}


export default EditProfile
