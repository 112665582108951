import AuthRequestHelper from './AuthRequestHelper'


class UserMatchHelper{
    async getRecProfile(id,matchNo){
        try{
            const requestUrl="recuser/"+id
            return await AuthRequestHelper.request("get",requestUrl,{matchNo:matchNo})
        }
        catch(error){
            console.log(error)
            return{}
        }

    }
}

export default new UserMatchHelper();