import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Icon } from '../../assets/Buttons'
import GetImg from '../../assets/GetImg'
import { Paragraph, Tip } from '../../assets/Text'
import { Divider } from '../../assets/UIElements'
import init from '../../config/init'
import paths from '../../config/paths'

import matchConfig from '../../config/matchConfig'
import ChatSocketHelper from '../../helpers/ChatSocketHelper'
import ChatSystemHelper from '../../helpers/ChatSystemHelper'
import ChatInfoStore from '../../helpers/ChatInfoStore'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'

/**
 * create a navbar
 * @param {*} props 
    usage:
    listObj = {
        key1: {
            title: 'Home',
            url: '/'
        },
        key2: {
            title: 'Grief Intensity Scale',
            url: '/pg13'
        }
    }
    <NavSection title='your-title' lists={listObj} />
 */
const NavSection = props => {
    const [active, enable] = useState(false)
    // console.log(props.matchProfile)
    if(!props.lang.hasOwnProperty('listObj')) return null
    let list = [], listItem = init.navItem
    Object.keys(listItem).map((k, idx) => {
        const langDetails = props.lang.listObj[k]
        const listDetails = listItem[k]

        list.push(<ListItem title={langDetails.title} className='fyw-list-btn' key={`nav-list-${idx}`} button onClick={() => {
            // window.location.replace(listDetails.url)
            props.history.push(listDetails.url)
        }}>
            <Icon className={`${listDetails.icon} fyw-font-white mr-lg-3 m-sm-auto fyw-list-icon`} />
            <ListItemText primary={langDetails.title} className='fyw-font-white fyw-list-text' />
        </ListItem>)
    })
    
    return (
    <Grid container>
    <Grid item xs={1} sm={2} className={active ? 'left-wrapper show':'left-wrapper'}>

        <Grid className='w-100 d-flex'>
            <GetImg
                className={['fyw-compass-logo', 'm-auto', 'pointer']}
                // onClick={() => enable(!active)}
                onClick={() => props.history.push(paths.index)}
                imageUrl={"FOW-logo.svg"} // "name-of-the-img.jpg"
                srcSet={{
                    sm: 'FOW-logo.svg',
                    md: 'FOW-logo.svg',
                    lg: 'FOW-logo.svg',
                    xl: 'FOW-logo.svg'
                }}
                id={'fyw-compass-logo'}
            />
        </Grid>
        <Paragraph className='fyw-font-white text-center pb-0 mb-0 fyw-nav-title'>{props.lang.title}</Paragraph>
        <Divider className='fyw-divider-white' />
        <Tip className='fyw-font-white text-center mt-0 pt-0 fyw-nav-subtitle'>{props.lang.subtitle}</Tip>
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={'fyw-nav-list'}
        >
            {list}
            {props.matchProfile.hasOwnProperty('uuid') && props.matchProfile.uuid !== undefined && props.matchProfile.uuid !== ''
            ?
            <ListItem title='logout' className='fyw-list-btn' button onClick={() => {
                // window.location.replace(listDetails.url)
                // props.history.push(listDetails.url)
                // props.logout(props.auth)
                props.switchPage(matchConfig.pages.login)
                props.matchLogout()
                AuthenticationHelper.resetToken()
                //reset all the chat parameters
                ChatSystemHelper.resetChatToken()
                // ChatInfoStore.chatRoomInfo=null
                ChatInfoStore.resetChatInfo()
                ChatSocketHelper.logout()
            }}>
                <Icon className={`fas fa-sign-out-alt fyw-font-white mr-lg-3 m-sm-auto fyw-list-icon`} />
                <ListItemText primary='logout' className='fyw-font-white fyw-list-text' />
            </ListItem>
            :null}
            {props.auth.hasOwnProperty('accessToken') && props.auth.accessToken !== undefined && props.auth.accessToken !== '' 
            ?
            <ListItem title='logout' className='fyw-list-btn' button onClick={() => {
                // window.location.replace(listDetails.url)
                // props.history.push(listDetails.url)
                props.logout(props.auth)
            }}>
                <Icon className={`fas fa-sign-out-alt fyw-font-white mr-lg-3 m-sm-auto fyw-list-icon`} />
                <ListItemText primary='logout' className='fyw-font-white fyw-list-text' />
            </ListItem>
            :null}
            {/*<ListItem button onClick={() => {
                window.location.replace('/')
            }}>
                <Icon className='fas fa-home fyw-font-white mr-3' />
                <ListItemText primary="Home" className='fyw-font-white' />
            </ListItem>
            <ListItem button onClick={() => {
                window.location.replace('/pg13')
            }}>
                <Icon className='fas fa-clipboard-list fyw-font-white mr-3' />
                <ListItemText primary="Grief Intensity Scale" className='fyw-font-white' />
            </ListItem>
            <ListItem button onClick={() => {
                window.location.replace('/bci')
            }}>
                <Icon className='fas fa-clipboard-list fyw-font-white mr-3' />
                <ListItemText primary="Bereavement Challenges Inventory" className='fyw-font-white' />
            </ListItem>
            <ListItem button onClick={() => {
                window.location.replace('/resources')
            }}>
                <Icon className='fas fa-medkit fyw-font-white mr-3' />
                <ListItemText primary="Grief Resources" className='fyw-font-white' />
            </ListItem>
            <ListItem button>
                <Icon className='fas fa-star fyw-font-white mr-3' />
                <ListItemText primary="Disclaimer" className='fyw-font-white' />
            </ListItem>*/}
        </List>
    </Grid>
    <Grid item xs={11} sm={10} className='right-wrapper col px-sm-3 px-md-1'>
        {props.children}
    </Grid>
    </Grid>)
}

export default NavSection