import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import GH from '../../helpers/GeneralHelper'
import { TitleSecion } from '../../assets/UIElements'
import ModuleProgress from './ConnectingComponents/ModuleProgress'
import IntenseFeelings from './ConnectingComponents/IntenseFeelings'
import CopingCards from './ConnectingComponents/CopingCards'
import IdentifyCompanions from './ConnectingComponents/IdentifyCompanions'
import RehearseWhatToSay from './ConnectingComponents/RehearseWhatToSay'
import { SystemFeedback, ConfirmBox } from '../../assets/Feedback'
import initProps from '../../config/init'
import NavSection from '../containers/NavBar'
import Login from '../containers/Login'

const m = initProps.modules.connecting
const connectingComponents = initProps[m]['components']
// console.log(m)
class ConnectingModule extends Component {
    constructor(props)
    {
        super(props)
        this.switchComponent = this.switchComponent.bind(this)
    }
    
    async switchComponent(module = initProps.modules.connecting, prevComponent = connectingComponents.intenseFeelings, nextComponent = connectingComponents.copingCards, details = {})
    {
        let progress
        // init a new progress if the user does not have it in his/her profile table
        if(!this.props.progress || this.props.progress === null)
        {
            progress = {}
        }
        else
        {
            progress = typeof this.props.progress === 'string' ? JSON.parse(this.props.progress) : this.props.progress
        }
        // GH.joinObjs(progress.details || {}, details)
        let newDetails = {}

        if(details === null)
        {
            newDetails = progress.hasOwnProperty(module) && progress[module].hasOwnProperty(prevComponent) && progress[module][prevComponent].hasOwnProperty('details') ? GH.joinObjs(progress[module][prevComponent]['details'] || {}, {}) : {}
        }
        else
        {
            newDetails = progress.hasOwnProperty(module) && progress[module].hasOwnProperty(prevComponent) 
            && progress[module][prevComponent].hasOwnProperty('details') 
            && Object.keys(progress[module][prevComponent]['details']).length > 0
            ? GH.joinObjs(progress[module][prevComponent]['details'] || {}, details) : details
        }

        progress['module'] = module
        progress['component'] = nextComponent
        progress[module] = progress.hasOwnProperty(module) ? progress[module] : {}
        progress[module][prevComponent] = progress[module].hasOwnProperty(prevComponent) ? progress[module][prevComponent] : {}
        progress[module][prevComponent]['details'] = newDetails
        progress[module][nextComponent] = progress[module].hasOwnProperty(nextComponent) ? progress[module][nextComponent] : {}
        progress[module][nextComponent]['details'] = progress[module][nextComponent].hasOwnProperty('details') && Object.keys(progress[module][nextComponent]['details']).length > 0 ? progress[module][nextComponent]['details'] : {}

        // let updateAccount = await this.props.updateCurrentStep(progress, this.props.user.id)
        let updateAccount = await this.props.updateCurrentStep(progress)

        return true

    }

    render()
    {
        // console.log(this.props.user)
        // if(this.props.user.hasOwnProperty('accessToken') && this.props.user.accessToken !== undefined && this.props.user.accessToken !== '')
        // {
            const progress = this.props.progress
            // console.log(this.props.progress)
            if(progress.hasOwnProperty('module') && progress.module === m)
            {
                // console.log(this.props.progress.component)
                let toDisplayComponent = <Grid></Grid>
                switch(this.props.progress.component)
                {
                    case connectingComponents.intenseFeelings:
                        toDisplayComponent = <IntenseFeelings {...this.props} switchComponent={this.switchComponent} />
                        break
                    case connectingComponents.copingCards:
                        toDisplayComponent = <CopingCards {...this.props} switchComponent={this.switchComponent} />
                        break
                    case connectingComponents.identifyCompanions:
                        toDisplayComponent = <IdentifyCompanions {...this.props} switchComponent={this.switchComponent} />
                        break
                    case connectingComponents.rehearseWhatToSay:
                        toDisplayComponent = <RehearseWhatToSay {...this.props} switchComponent={this.switchComponent} />
                        break
                    default: toDisplayComponent = <IntenseFeelings {...this.props} />
                }
                return(<NavSection>
                    {/*<Instruction />*/}
                    <Grid container>
                        <TitleSecion title={this.props.lang.title} subtitle={this.props.lang.subtitle} />
                        
                        <Grid item xs={11} sm={8}  className='mx-auto'>
                            <Grid item xs={12}>
                                <ModuleProgress {...this.props} switchComponent={this.switchComponent} />
                            </Grid>
                            {toDisplayComponent}
                        </Grid>

    
                        <ConfirmBox 
                            confirmTitle={this.props.confirmMessage.title || ''}
                            confirmMsg={this.props.confirmMessage.value || ''}
                            close={() => this.props.addSystemFeedback('')} 
                            confirm={() => this.props.confirmMessage.onConfirm()}
                            cancel={() => this.props.confirmMessage.onCancel()} 
                        />
                        <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
                        
                    </Grid>
                    </NavSection>)
            }
        //     return null
        // }
        // console.log('called')
        // return <Login />
    }
}

export default ConnectingModule