import AuthRequestHelper from './AuthRequestHelper'
import ChatSocketHelper from './ChatSocketHelper'
import ChatInfoStore from './ChatInfoStore'

class ChatSystemHelper{

    //LOGIN TO THE CHAT SYSTEM AND GET THE TOKEN
    async loginChat(id){
        try{
            const requestUrl="userchatinfo/"+id
            let chatAccountData= await AuthRequestHelper.request("get",requestUrl,{})
            this.setChatToken(chatAccountData.data.auth_token)
            return true
        }
        catch(error){
            console.log(error)
            return{}
        }

    }

    setChatToken(token) {
        sessionStorage.setItem('xtokenchat', token);
        return true;
    }

    /*when loggin out */
    resetChatToken() {
        sessionStorage.removeItem('xtokenchat');
    }

    get chatToken() {
        let token = sessionStorage.getItem('xtokenchat');

        if (token === undefined || token === null || token.length === 0) {
            return null;
        }

        return token;
    }


    //GET THE CHATROOM INFO
    async getChatroom(id){
        try{
            const requestUrl="chatroom/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})
        }
        catch(error){
            console.log(error)
            return{}
        }
    } 

 

    //subscribe to chatroom 
    async subscribeToChat(userid,handleUpdateMessage){

        await ChatSocketHelper.connectToChatWithToken(this.chatToken,handleUpdateMessage) //login to chat using the function

        //get the chatroom
        let chatRoomList= await this.getChatroom(userid)
        let chatRoomIdList=[]
        console.log('chat room id list')
        console.log(chatRoomIdList)

        try
        {
            chatRoomList.data.forEach(roominfo => {
                console.log("Subscribed to chat:"+ roominfo.room_id)
                let partnerId=""
                if(roominfo.user_1_id==userid)
                {
                    partnerId = roominfo.user_2_id
                }
                else
                {
                    partnerId = roominfo.user_1_id
                }
                chatRoomIdList.push({"roomId":roominfo.room_id,"partnerId":partnerId})
            })

            if(!ChatInfoStore.chatRoomInfo)
            {
                ChatInfoStore.chatRoomInfo=chatRoomIdList
            }
            ChatSocketHelper.subscribeToRooms(chatRoomIdList)
        }
        catch(e)
        {

        }

        return chatRoomIdList
    }

}

export default new ChatSystemHelper();