import methods from '../methods'

const addSystemFeedback = feedback => dispatch => {
    if(feedback === '')
    {
        return dispatch({
            type: methods.ADD_SYSTEM_FEEDBACK,
            payload: feedback
        })
    }
    dispatch({
        type: methods.ADD_SYSTEM_FEEDBACK,
        payload: feedback
    })

    return setTimeout(() => {

        dispatch({
            type: methods.ADD_SYSTEM_FEEDBACK,
            payload: ''
        })
    }, 4000)
}

const initConfirmMessage = msgObj => ({
    type: methods.INIT_CONFIRM,
    payload: msgObj
})

const storePayloadBeforeConfirm = payload => ({
    type: methods.STORE_PAYLOAD_BEFORE_CONFIRM,
    payload: payload
})

/**
 * To add/delete/load the instructions to user
 * @param {str} type 
 * @param {obj} obj 
    let instructionArr = []
    const instructionObj = {
        key: 'first',
        content: 'Please test the instruction screen',
        imgSrc: `backpack-icon-green-lg.png`
    }
    instructionArr.push(instructionObj)
    this.props.initInstructions('load', instructionArr)
    this.props.initInstructions('add', instructionObj)
    this.props.initInstructions('delete', instructionObj)
 */
const initInstructions = (type = 'load', obj) => dispatch => {
    switch(type)
    {
        case 'add':
            return dispatch({
                type: methods.ADD_INSTRUCTION,
                payload: obj
            })
        case 'delete':
            console.log(obj)
            return dispatch({
                type: methods.REMOVE_INSTRUCTION,
                payload: obj
            })
        case 'load':
        default:
            return dispatch({
                type: methods.LOAD_INSTRUCTION,
                payload: obj
            })
    }
}

export { initInstructions, addSystemFeedback, initConfirmMessage, storePayloadBeforeConfirm }