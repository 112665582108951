import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import Index from './containers/Index'
import MatchMaking from './containers/Main'
import Register from './containers/Register'
import PG13 from './containers/PG13'
import BCI from './containers/BCI'
import Connect from './containers/Connect'
import Resources from './containers/Resources'
import Contact from './containers/Contact'
import paths from '../config/paths'

import '../scss/index.scss'
import '../scss/match.scss'

class Home extends Component {
  constructor(props)
  {
    super(props)
  }
  render()
  {
    return (
      <Router>
          <Switch>
            <Route exact path={paths.index} component={Index} />
            <Route exact path={paths.connecting} component={MatchMaking} />
            <Route exact path={paths.register} component={Register} />
            <Route exact path={paths.pg13} component={PG13} />
            <Route exact path={paths.bci} component={BCI} />
            <Route exact path={paths.coping} component={Connect} />
            <Route exact path={paths.resources} component={Resources} />
            <Route exact path={paths.contact} component={Contact} />
          </Switch>
      </Router>
    )
  }
}

export default Home