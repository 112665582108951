import React from 'react'

const Tip = React.forwardRef((props, ref) => {
    return(
        <p id={props.id} ref={ref} className={`fyw-font-tip ${props.className}`} style={props.style} onClick={props.onClick !== null ? props.onClick:null}>
            {props.children}
        </p>
    )
})

const Text = React.forwardRef((props, ref) => {
    return(
        <p id={props.id} ref={ref} className={`fyw-paragraph ${props.className}`} style={props.style}>
            {props.children}
        </p>
    )
})

const Paragraph = React.forwardRef((props, ref) => {
    return(
        <article id={props.id} ref={ref}>
            <p className={`fyw-paragraph ${props.className}`} style={props.style}>
                {props.children}
            </p>
        </article>
    )
})

const Title = React.forwardRef((props, ref) => {
    return(
        <h1 id={props.id} ref={ref} className={`fyw-title text-center ${props.className}`}>
            {props.children}
        </h1>
    )
})

const Subtitle = React.forwardRef((props, ref) => {
    return(
        <h1 id={props.id} ref={ref} className={`fyw-subtitle-dark text-center ${props.className ? props.className : ''}`}>
            {props.children}
        </h1>
    )
})

export { Tip, Text, Title, Subtitle, Paragraph }