//TODO: This might not be the best practice, I had to make a static variable to store info about the chatroom and partner id. 
//Maybe try to embed this in ChatSystemHelper. This is currently being called inside ChatSystemHelper and Connect.js
class ChatInfoStore{
    static chatRoomInfo= null //stores info about the chatroom and partner id 

    static resetChatInfo()
    {
        ChatInfoStore.chatRoomInfo = null
    }

    static getRoomId(partnerId = '')
    {
        if(ChatInfoStore.chatRoomInfo === null || partnerId === '') return null
        let rid = ''
        ChatInfoStore.chatRoomInfo.forEach((room) => {
            if(room.partnerId === partnerId)
            {
                rid = room.roomId
            }
        })
        return rid
    }
}

export default ChatInfoStore