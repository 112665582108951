import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput } from '../../../assets/Form'
import { Paragraph, Text, Tip } from '../../../assets/Text'
import { Button } from '../../../assets/Buttons'
import { Alert } from '../../../assets/UIElements'
import UserProfileHelper from '../../../helpers/UserProfileHelper'
import AuthValidateHelper from '../../../helpers/AuthValidateHelper'
import GH from '../../../helpers/GeneralHelper'

/*
    <ResetPass 
        email='your email'
    />
*/
const ResetPass = props => {
    // console.log(props)
    const [hidePass, changeHide] = useState(true)
    const [password, updatePassword] = useState('')
    const [confirmPass, updateConfirmPass] = useState('')
    const [submitError, updateSubmitError] = useState('')
    const [passReset, updatePassReset] = useState(false)

    //validate password input
    const [passValidated, updatePassValidated] = useState(true)
    const [lowercase, updateLowercase] = useState(false)
    const [capital, updateCapital] = useState(false)
    const [number, updateNumber] = useState(false)
    const [special, updateSpecial] = useState(false)
    const [minimum, updateMinimum] = useState(false)


    if(!passReset)
    {
        return (<Grid item xs={12} className='mx-auto'>
            <div className='mx-auto'>
            <form>
                <TextInput 
                    id='match-password'
                    // ref={pass => this._password = pass}
                    type={hidePass ? "password" : "text"}
                    label={props.lang.password}
                    ariaLabel='password'
                    icon='lock'
                    endAdornmentIcon={hidePass ? "eye-slash" : "eye"}
                    endAdornmentLabel={props.lang.togglePassVisibility}
                    withAdornment={true}
                    handleEndAdornmentClick={() => changeHide(!hidePass)}
                    placeholder={props.lang.yourPassword}
                    onChange={e => {
                        updatePassword(e.target.value)
                        const validate = AuthValidateHelper.validatePassword(e.target.value)
                        if(typeof validate === 'object')
                        {
                            updatePassValidated(false)
                            updateLowercase(validate.lowercase)
                            updateCapital(validate.uppercase)
                            updateNumber(validate.number)
                            updateSpecial(validate.special)
                            updateMinimum(validate.length)
                        }
                        else
                        {
                            updatePassValidated(true)
                        }
                    }}
                />
                {!passValidated ? <div className="pass-valid-section show">
                    <div className="mx-auto col-sm-12">
                        <Text className="pass-validation">{props.lang.passwordNotice}</Text>
                        <Tip className={lowercase ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.lowercase}</Tip>
                        <Tip className={capital ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.uppercase}</Tip>
                        <Tip className={number ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.number}</Tip>
                        <Tip className={special ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.specialChar}</Tip>
                        <Tip className={minimum ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.eightChar}</Tip>
                    </div>
                </div>
                :null}
                <TextInput 
                    id='match-password-confirm'
                    // ref={pass => this._password = pass}
                    type={hidePass ? "password" : "text"}
                    label={props.lang.confirmPassword}
                    ariaLabel='confirm password'
                    icon='lock'
                    endAdornmentIcon={hidePass ? "eye-slash" : "eye"}
                    endAdornmentLabel={props.lang.togglePassVisibility}
                    withAdornment={true}
                    handleEndAdornmentClick={() => changeHide(!hidePass)}
                    placeholder={props.lang.confirmPassword}
                    onChange={e => updateConfirmPass(e.target.value)}
                />
                <Button className='mx-auto mt-4' onClick={async () => {
                    const validPass = await AuthValidateHelper.confirmPassword(GH.removeWhiteSpace(password), GH.removeWhiteSpace(confirmPass))
                    if(validPass)
                    {
                        const resetpasswordresult= await UserProfileHelper.resetPassword(props.email, GH.removeWhiteSpace(password))
                        // console.log(resetpasswordresult)
                        if(resetpasswordresult.hasOwnProperty('status') && resetpasswordresult.status === 'success')
                        {
                            updatePassReset(true)
                            return
                        }
                        updateSubmitError(props.lang.resetError)
                        return
                    }
                    updateSubmitError(props.lang.confirmPasswordWarning)
                    // console.log(email)
                    // const validEmail = await AuthValidateHelper.validateEmail(email)
                    // if(validEmail)
                    // {
                    //     const emailresetpasswordresult= await UserProfileHelper.sendresetPasswordEmail(email)
                    //     if(emailresetpasswordresult.hasOwnProperty('status') && emailresetpasswordresult.status === 'success')
                    //     {
                    //         updateEmailSent(true)
                    //         return 
                    //     }
                    //     // console.log(emailresetpasswordresult)
                    //     updateSubmitError('The system has encountered problem to send you the temporary email. Please try again later or contact our system manager.')
                    //     return
                    // }
                    // console.log(validEmail)
                    // return updateSubmitError('Please double check your email format')


                    // const result = await props.login(email, password)
                    // console.log(result)
                    // if(!result) updateSubmitError('Oops, the system failed to process your login request. Please try again later.')
                    // console.log(result)
                    // console.log(`entered email: ${email} and entered pass: ${password}`)
                }}>{props.lang.submit}</Button>
                {submitError !== '' ? <Grid className='w-100 d-flex justify-content-center'>
                    <Alert><Text className='fyw-font-dark mx-auto'>{submitError}</Text></Alert>
                </Grid>
                :null}
            </form>
            </div>
        </Grid>)
    }
    return <Grid container className='mt-3 mx-auto'>
    <Grid item xs={12} sm={10} md={8} className='mx-auto'>
    <Grid className='mx-auto text-center'>
        <Paragraph className='fyw-font-dark fyw-font-bold my-5'>{props.lang.resetSuccessful}</Paragraph>
    </Grid>
    </Grid>
    </Grid>
}

export default ResetPass