import FetchWrapper from '../assets/FetchWrapper'
import GH from './GeneralHelper'
import DB from './DatabaseHelper'

const PG13Arr = require('../json/PG13.json')
const BCIArr = require('../json/BCI.json')
const SCFeedback = require('../json/SCFeedback.json')

const Fetch = new FetchWrapper()

class SurveyHelper {
    constructor()
    {

    }
    getQuestionnaire(surveyKey = 'PG13')
    {
        switch(surveyKey)
        {
            // case 'Demo':
            //     return Demographics
            case 'BCI':
                return BCIArr
            case 'SCFeedback':
                return SCFeedback
            case 'PG13':
            default:
                return PG13Arr
        }
    }

    getQuestionGivenQid(survey, qid = '')
    {
        // console.log('called')
        if(!survey && !qid) return false
        if(typeof survey === 'string') // the survey key is provided
        {
            let newSurvey = this.getQuestionnaire(survey)
            let qObj = {}
            const questions = newSurvey['questions']
            questions.forEach(question => {
                if(question.id === qid)
                {
                    qObj = question
                }
            })
            return qObj
        }
        let qObj = {}
        const questions = survey['questions']
        questions.forEach(question => {
            if(question.id === qid)
            {
                qObj = question
            }
        })
        return qObj
    }

    async checkIfSurveyCompleted(qid, answers, returnArr = false)
    {
        const questionnaire = await this.getQuestionnaire(qid)
        let notAnswered = []
        const surveyKeyArr = await GH.asyncForEach(questionnaire.questions, async (question, idx) => {
            const key = question.id
            if(!answers.hasOwnProperty(key))
            {
                notAnswered.push(key)
            }
        })
        // console.log(notAnswered)
        if(notAnswered.length > 0)
        {
            if(returnArr) return notAnswered
            return false
        }
        return true
    }

    getAnswerGivenValue(survey, qid = '', value = 0)
    {
        // console.log('called 2')

        if(!survey && !qid && !value) return false
        const qObj = this.getQuestionGivenQid(survey, qid)
        const options = qObj['options']
        let answer = ''
        options.forEach(option => {
            if(parseInt(option.value, 10) === parseInt(value, 10))
            {
                answer = option.display
            }
        })
        return answer
    }

    async updateSurveyAnswers(uid, qid, submissionHash, answers)
    {
        // console.log(answers)
        const ip = await GH.getIp()
        const newAnswer = this.stringifySurveyObj(answers)
        let data = {
            ip_address: ip,
            submission_hash: submissionHash,
            ...newAnswer
        }

        try
        {
            const update = await DB.upsert(qid, data, {
                user_id: uid,
                submission_hash: submissionHash
            })
            // console.log(update)
            if(update.success && update.data.length > 0)
            {
                return update.data[0]
            }
            throw update
        }
        catch(e)
        {
            return e
        }

        // console.log(data)
        // return Fetch.post('/survey/v1/upsertSurvey', {
        //     table: qid,
        //     uid: uid,
        //     params: data
        // }, (err, result) => {
        //     if(err)
        //     {
        //         // console.log(err)
        //         return err
        //     }
        //     return result
        // })
    }

    removeNullValues(surveyObj) {
        let newObj = {}
        Object.keys(surveyObj).map(key => {
            if(key !== 'created_at' && key !== 'last_edited' && surveyObj[key] !== null)
            {
                newObj[key] = surveyObj[key]
            }
        })
        return newObj
    }

    //return true if there are null values
    checkNullColumns(surveyObj) {
        // console.log(`called`)

        if(Object.keys(surveyObj).length <= 0) return true
        let nullValues = 0
        Object.keys(surveyObj).map(k => {
            if(surveyObj[k] === null) nullValues += 1
        })
        const hasNullValues = nullValues > 0 ? true : false
        // console.log(`has null value ${hasNullValues}`)
        return hasNullValues
    }

    stringifySurveyObj(surveyObj) {
        let newObj = {}
        Object.keys(surveyObj).map(key => {
            if(surveyObj[key].hasOwnProperty("id"))
            {
                newObj[key] = JSON.stringify(surveyObj[key])
            }
            else
            {
                newObj[key] = surveyObj[key]
            }
        })
        return newObj
    }

    // remove unnecessary data before storing the answer in the database
    async cleanDataBeforeStore(qid, answers)
    {
        // console.log(answers)
        const survey = await this.getQuestionnaire(qid)
        let newObj = {}
        const loop = await GH.asyncForEach(survey.questions, async q => {
            newObj[q.id] = answers[q.id]
        })
        // console.log(newObj)
        return newObj
    }

    async storeSurveyAnswers(uid, qid, answers)
    {
        // console.log(answers)
        const ip = await GH.getIp()
        const hash = await GH.hash(10)
        let stringified = await this.stringifySurveyAnswers(qid, answers)
        let data = {
            ip_address: ip,
            submission_hash: hash,
            ...stringified
        }
        // console.log(stringified)
        return Fetch.post('/survey/v1/upsertSurvey', {
            table: qid,
            uid: uid,
            params: data
        }, (err, result) => {
            if(err)
            {
                return err
            }
            return result
        })
    }

    // filter the answers of a questionnaire by a threashold of scale on each answer
    // e.g., find the answers in BCI survey which has the intensity higher than or equal to 3
    async filterAnswers(qid, answers, cap, operator = '>=')
    {
        let filteredAnswers = {}
        const survey = await this.getQuestionnaire(qid)
        const loop = await GH.asyncForEach(survey.questions, async q => {
            const targetId = q.id
            const qs = await this.getQuestionGivenQid(qid, targetId)
            // console.log(qs)
            // const question = qs.display
            const altKey = qs.alternativeKey
            // console.log(question)
            if(answers.hasOwnProperty(targetId))
            {
                let answer = typeof answers[targetId] === 'string' ? parseInt(JSON.parse(answers[targetId]).answer, 10) : parseInt(answers[targetId].answer, 10)

                switch(operator)
                {
                    case '>':
                        if(answer > cap)
                        {
                            filteredAnswers[targetId] = JSON.parse(answers[targetId])
                            // filteredAnswers[targetId]['question'] = question
                            filteredAnswers[targetId]['alternativeKey'] = altKey
                        }
                    break
                    case '<':
                        if(answer < cap)
                        {
                            filteredAnswers[targetId] = JSON.parse(answers[targetId])
                            filteredAnswers[targetId]['alternativeKey'] = altKey
                        }
                    break
                    case '<=':
                        if(answer <= cap)
                        {
                            filteredAnswers[targetId] = JSON.parse(answers[targetId])
                            filteredAnswers[targetId]['alternativeKey'] = altKey
                        }
                    break
                    case '=':
                        if(answer === cap)
                        {
                            filteredAnswers[targetId] = JSON.parse(answers[targetId])
                            filteredAnswers[targetId]['alternativeKey'] = altKey
                        }
                    break
                    case '>=':
                    default:
                        if(answer >= cap)
                        {
                            filteredAnswers[targetId] = JSON.parse(answers[targetId])
                            filteredAnswers[targetId]['alternativeKey'] = altKey
                        }
                }
            }
        })
        return filteredAnswers
    }

    async stringifySurveyAnswers(qid, inputAnswers)
    {
        const survey = await this.getQuestionnaire(qid)
        let newAnswerObj = {}
        const loop = await GH.asyncForEach(survey.questions, async (question, i) => {
            if(inputAnswers.hasOwnProperty(question.id))
            {
                newAnswerObj[question.id] = typeof inputAnswers[question.id] !== 'string' ? JSON.stringify(inputAnswers[question.id]) : inputAnswers[question.id]
            }
        })
        return newAnswerObj
    }

    async parseSurveyAnswers(retrievedSurveyAnswers, answerKey)
    {
        // console.log(retrievedSurveyAnswers)
        let newAnswerObj = {}
        try
        {
            await Object.keys(retrievedSurveyAnswers).map(key => {
                if(key.includes(answerKey))
                {
                    const answerObj = JSON.parse(retrievedSurveyAnswers[key])
                    newAnswerObj[key] = answerObj
                }
            })

            return newAnswerObj
        }
        catch(err)
        {
            return false
        }
    }

    async getSurveyGivenContactKey(qid, uid, contactKey)
    {
        try
        {
            const result = await DB.upsert(qid, {}, {
                user_id: uid,
                contact_person_key: contactKey
            })
            if(!result.success || !result ) throw result
            if(result.data.length <= 0) return []
            return result.data
        }
        catch(e)
        {
            return e
        }
    }

    getLastSurvey(qid, uid)
    {
        return Fetch.post('/survey/v1/getLastSurveyEntry', {
            qid: qid,
            uid: uid
        }, (err, result) => {
            if(err)
            {
                return false
            }
            return result.data
        })
    }

    async PG9Analysis(inputAnswers, stringify = false)
    {
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        const excludeKeys = ["PG-002", "PG-001"]
        if(inputAnswers.hasOwnProperty('submission_hash') || inputAnswers.hasOwnProperty('ip_address') 
            || inputAnswers.hasOwnProperty('created_at') || inputAnswers.hasOwnProperty('id'))
        {
            answers = await this.parseSurveyAnswers(inputAnswers, 'PG')
        }
        try
        {
            let total = 0, meetPG9 = false,
            meetDuration = false, corona = false
            // let meetP13 = false, meetP1 = false, meetRest = false
            // let x = 0, total = 0, meetDuration = false, corona = false
            await Object.keys(answers).forEach(key => {
                if(!excludeKeys.includes(key))
                {
                    total += parseInt(answers[key]["answer"], 10)
                    // meetP1 = true
                }
                // if(!excludeKeys.includes(key) && key === "PG-13" && parseInt(answers[key]["answer"], 10) === 2)
                // {
                //     total += parseInt(answers[key]["answer"], 10)
                //     meetP13 = true
                // }
                // if(!excludeKeys.includes(key) && key !== "PG-01" && key !== "PG-03" && key !== "PG-05" && key !== "PG-14" && answers[key]["answer"] >= 4)
                // {
                //     total += parseInt(answers[key]["answer"], 10)
                //     x += 1
                // }
                // if(!excludeKeys.includes(key) && key === "PG-14" && parseInt(answers[key]["answer"], 10) >= 3) { 
                //     x += 1
                //     total += parseInt(answers[key]["answer"], 10)
                // }
                if(excludeKeys.includes(key) && key === 'PG-001')
                {
                    meetDuration = parseInt(answers[key]["answer"], 10) === 1
                }
                if(excludeKeys.includes(key) && key === 'PG-002')
                {
                    corona = parseInt(answers[key]["answer"], 10) === 1
                }
            })
            // if(x >= 3) meetRest = true
            if(total >= 29) meetPG9 = true
            if(meetPG9)
            {
                if(stringify)
                {
                    return `The total PG9 score is ${total}, and you meet the criteria of PGD.`
                }
                return {
                    total: total,
                    meet: true,
                    meetDuration,
                    corona
                }
            }
            if(stringify)
            {
                return `The total PG9 score is ${total}, and you do not meet the criteria of PGD.`
            }
            return {
                total: total,
                meet: false,
                meetDuration,
                corona
            }
        }
        catch(err)
        {
            return false
        }
    }

    async PG13Analysis(inputAnswers, stringify = false)
    {
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        const excludeKeys = ["PG-002", "PG-001"]
        if(inputAnswers.hasOwnProperty('submission_hash') || inputAnswers.hasOwnProperty('ip_address') 
            || inputAnswers.hasOwnProperty('created_at') || inputAnswers.hasOwnProperty('id'))
        {
            answers = await this.parseSurveyAnswers(inputAnswers, 'PG')
        }
        try
        {
            let meetP13 = false, meetP1 = false, meetRest = false
            let x = 0, total = 0, meetDuration = false, corona = false
            await Object.keys(answers).forEach(key => {
                if(!excludeKeys.includes(key) && key === "PG-01" && parseInt(answers[key]["answer"], 10) >= 4)
                {
                    total += parseInt(answers[key]["answer"], 10)
                    meetP1 = true
                }
                if(!excludeKeys.includes(key) && key === "PG-13" && parseInt(answers[key]["answer"], 10) === 2)
                {
                    total += parseInt(answers[key]["answer"], 10)
                    meetP13 = true
                }
                if(!excludeKeys.includes(key) && key !== "PG-01" && key !== "PG-03" && key !== "PG-05" && key !== "PG-14" && answers[key]["answer"] >= 4)
                {
                    total += parseInt(answers[key]["answer"], 10)
                    x += 1
                }
                if(!excludeKeys.includes(key) && key === "PG-14" && parseInt(answers[key]["answer"], 10) >= 3) { 
                    x += 1
                    total += parseInt(answers[key]["answer"], 10)
                }
                if(excludeKeys.includes(key) && key === 'PG-001')
                {
                    meetDuration = parseInt(answers[key]["answer"], 10) === 1
                }
                if(excludeKeys.includes(key) && key === 'PG-002')
                {
                    corona = parseInt(answers[key]["answer"], 10) === 1
                }
            })
            if(x >= 3) meetRest = true
            if(meetP13 && meetP1 && meetRest)
            {
                if(stringify)
                {
                    return `The total PG13 score is ${total}, and you meet the criteria of PGD.`
                }
                return {
                    total: total,
                    meet: true,
                    meetDuration,
                    corona
                }
            }
            if(stringify)
            {
                return `The total PG13 score is ${total}, and you do not meet the criteria of PGD.`
            }
            return {
                total: total,
                meet: false,
                meetDuration,
                corona
            }
        }
        catch(err)
        {
            return false
        }
    }

    async BCIAnalysis(inputAnswers, stringify = false)
    {
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        if(inputAnswers.hasOwnProperty('submission_hash') || inputAnswers.hasOwnProperty('ip_address') 
            || inputAnswers.hasOwnProperty('created_at') || inputAnswers.hasOwnProperty('id'))
        {
            answers = await this.parseSurveyAnswers(inputAnswers, 'BCI')
        }

        try
        {
            let connectingWithOthers = 0, change = 0, imaginingHopefulFuture = 0, acceptingTheLoss = 0, guilt = 0
            // let connectingItems = ["BCI-05", "BCI-10", "BCI-11", "BCI-12", "BCI-13", "BCI-16", "BCI-27", "BCI-29", "BCI-30", "BCI-32"], 
            // changeItems = ["BCI-03", "BCI-04", "BCI-08", "BCI-14", "BCI-15", "BCI-20", "BCI-21", "BCI-22"], 
            // imagineItems = ["BCI-01", "BCI-02", "BCI-06", "BCI-07", "BCI-09"], 
            // acceptingItems = ["BCI-17", "BCI-18", "BCI-19"], 
            // guiltItems = ["BCI-24", "BCI-25", "BCI-26", "BCI-28", "BCI-34"]
            Object.keys(answers).forEach(key => {
                const q = this.getQuestionGivenQid(BCIArr, key)
                const a = answers[key]
                const score = parseInt(a.answer, 10)
                switch(q.category)
                {
                    case "Challenges with Connecting with Others":
                        connectingWithOthers += score
                        // connectingItems.push(key)
                        break
                    case "Challenges with Change":
                        change += score
                        // changeItems.push(key)
                        break
                    case "Challenges Imagining a Hopeful Future":
                        imaginingHopefulFuture += score
                        // imagineItems.push(key)
                        break
                    case "Challenges with Accepting the Loss":
                        acceptingTheLoss += score
                        // acceptingItems.push(key)
                        break
                    case "Challenges with guilt":
                        guilt += score
                        // guiltItems.push(key)
                        break
                }
            })

            if(stringify)
            {
                return `The scores for Connecting with Others: ${connectingWithOthers}, </br> 
                The score for Change: ${change}, </br>, 
                The score for Imagining a Hopeful Future: ${imaginingHopefulFuture}, </br>
                The score for Accepting the Loss: ${acceptingTheLoss}, </br>
                The score for Guilt: ${guilt}`
            }
            return {
                connecting: connectingWithOthers,
                change: change,
                imagining: imaginingHopefulFuture,
                accepting: acceptingTheLoss,
                guilt: guilt
            }
        }
        catch(err)
        {
            return false
        }
    }

    sortScore(inputObj, desc = true)
    {
        let sorted = []
        try
        {
            Object.keys(inputObj).map(key => {
                sorted.push([key, inputObj[key]])
            })
            if(desc)
            {
                return sorted.sort((a, b) => b[1]-a[1])
            }
            return sorted.sort((a, b) => a[1]-b[1])
        }
        catch(err)
        {
            return false
        }
    }
}

export default new SurveyHelper()