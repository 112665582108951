import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Text, Tip } from '../../../assets/Text'
// import { Divider } from '../../assets/UIElements'
import GetImg from '../../../assets/GetImg'
import init from '../../../config/init'
import '../../../scss/bci.scss'
import { Tween } from 'react-gsap'
import DH from '../../../helpers/DatabaseHelper'

const config = init
const PUBLIC_URL = config.serverUrl
  
class CardsComponent extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = { 
            data: this.props.lang.copingCards.cardsContent, 
            currentQuestion: 0,
            enable: true
        }
        this.checkIfBoxesAreRevealed = this.checkIfBoxesAreRevealed.bind(this)
        this.getAlternativeThoughtGivenKey = this.getAlternativeThoughtGivenKey.bind(this)
        this.switchQuestion = this.switchQuestion.bind(this)
        this.storeCopingCards = this.storeCopingCards.bind(this)
        this.onCardRevealed = this.onCardRevealed.bind(this)
        this.unAnsweredCopingCards = this.unAnsweredCopingCards.bind(this)
    }

    async componentWillMount()
    {
        // console.log(this.props)
        //
        // console.log(this.props.filteredAnswers)
        let data = this.props.lang.copingCards.cardsContent
        let newData = []
        data.forEach((d, i) => {
            // console.log(d)
            let answers = d.a
            let answerKeys = Object.keys(d.a)
            newData[i] = {...d}
            // newData[i]['a'] = {...answers}
            if(d.q.includes('[deceased]'))
            {
                newData[i]['q'] = d.q.replace('[deceased]', 'the deceased')
                // console.log(newData)
            }
            if(d.q.includes('[Deceased]'))
            {
                newData[i]['q'] = d.q.replace('[Deceased]', 'The deceased')
                // console.log(newData)
            }
            else
            {
            // newData[i]['q'] = d.q.replace('[deceased]', 'the deceased')
            }
            // console.log(newData)
            answerKeys.map(k => {
                let answer = answers[k]
                if(answer.value.includes('[deceased]'))
                {
                    answer.value = answer.value.replace('[deceased]', 'the deceased')
                    newData[i]['a'][k] = answer
                }
                if(answer.value.includes('[Deceased]'))
                {
                    answer.value = answer.value.replace('[Deceased]', 'The deceased')
                    newData[i]['a'][k] = answer
                }
            })

        })
        this.setState({
            data: newData
        })
    }

    async checkIfBoxesAreRevealed(qKey)
    {
        if(!qKey) return false
        // console.log('checkbox ', qKey)
        const qObj = this.getAlternativeThoughtGivenKey(qKey)
        // console.log(qObj)
        let unchecked = 0
        // const currentQuestion = Object.keys(this.props.copingCards).length
        // console.log(this.props.copingCards)
        const allAnswerBoxes = qObj
        Object.keys(allAnswerBoxes.a).map((k, i) => {
            if(allAnswerBoxes.a[k]['active'] !== true)
            {
                unchecked += 1
            }
        })

        if(unchecked > 0) return false
        
        return true

    }

    getAlternativeThoughtGivenKey(qKey)
    {
        let targetObj = {}
        this.state.data.forEach((d) => {
            if(d.key === qKey)
            {
                targetObj = d
            }
        })
        return targetObj
    }

    async storeCopingCards(cardObj = {}) {
        if(Object.keys(cardObj).length <= 0) return null
        // console.log(cardObj.key)
        const check = await this.checkIfBoxesAreRevealed(cardObj.key)
        if(!check)
        {
            this.props.initConfirmMessage({
                title: 'Incomplete coping cards',
                value: 'Please drag the left text to the box until you reveal all the alternative thoughts.',
                onConfirm: () => {
                    this.props.initConfirmMessage({})
                },
                onCancel: () => {
                    this.props.initConfirmMessage({})
                }
            })
            return
        }

        this.props.initConfirmMessage({
            title: 'Confirm selection',
            value: 'Do you want to select this coping card?',
            onConfirm: async () => {
                this.props.initConfirmMessage({})
                const update = await this.props.manageCopingCards('add', cardObj)
                // console.log(update)
                return this.props.addSystemFeedback("You have collected a coping card.")

                // let cards = {...this.props.copingCards}
                // try {
                //     const upsert = await DH.upsert(init.db.copingCards, {
                //         user_id: this.props.user.id,
                //         json: JSON.stringify(cards)
                //     }, { user_id: this.props.user.id }).then(r => r).catch(e => e)
                //     // console.log(upsert)
                //     const json = JSON.parse(upsert.data[0].json)
                //     // console.log(json)
                //     if(upsert.success)
                //     {
                //         // console.log('the upsert has been completed')
                //         return this.props.addSystemFeedback("You have collected a coping card.")
                //     }
                //     throw Error('Could not store the selection. Please try again later')
                // }
                // catch(e)
                // {
                //     // console.log(e)
                //     return this.props.addSystemFeedback(e)
                // }
            },
            onCancel: () => {
                this.props.initConfirmMessage({})
            }
        })

    }

    switchQuestion(no = 1)
    {
        if(this.state.currentQuestion + no >= 0 && this.state.currentQuestion + no < this.state.data.length)
        {
            this.setState({
                currentQuestion: this.state.currentQuestion + no 
            }, () => {
                // clear the container
                const boxes = document.getElementsByClassName('drag-box')
                for(let i = 0 ; i < boxes.length ; i++) {
                    boxes[i].innerText = '???';
                }
            })
        }
    }

    onCardRevealed(e, qKey, aKey)
    {
        e.preventDefault()

        let newQuestionSet = [...this.state.data]
        newQuestionSet.forEach((qObj, idx) => {
            if(qObj.key === qKey)
            {

                if(qObj.a.hasOwnProperty(aKey))
                {
                    newQuestionSet[idx].a[aKey].active = true
                }
            }

        })
        this.setState({
            data: newQuestionSet
        })
    }

    unAnsweredCopingCards(filteredAnswers, copingCards)
    {
        let unAnsweredQuestions = {}
        Object.keys(filteredAnswers).map((k, i) => {
            const targetQ = filteredAnswers[k]['alternativeKey']
            if(!copingCards.hasOwnProperty(targetQ))
            {
                unAnsweredQuestions[k] = filteredAnswers[k]
            }
        })
        return unAnsweredQuestions
    }

    render() {
        // console.log(this.props.copingCards)
        if(!this.props.copingCards) return null
        // console.log(this.props.filteredAnswers)
        // if a filtered answer object is passed
        if(Object.keys(this.props.filteredAnswers).length > 0)
        {
            // console.log("got filtered answers")
            // const keys = Object.keys(this.props.filteredAnswers)
            // const currentQuestion = Object.keys(this.props.copingCards).length
            const unAnswered = this.unAnsweredCopingCards(this.props.filteredAnswers, this.props.copingCards)
            const keys = Object.keys(unAnswered)
            const currentQuestion = 0
            const targetQuestionKey = keys[currentQuestion]

            // calculate the proportion of complete
            const total = Object.keys(this.props.filteredAnswers).length
            const completed = Object.keys(this.props.copingCards).length
            // console.log(`the total is ${total} and the completed is ${completed}`)
            const proportion = Math.floor(completed/total * 100)
            const proportionStr = `${proportion}%`

            if(targetQuestionKey !== undefined)
            {
                const altKey = this.props.filteredAnswers[targetQuestionKey]['alternativeKey']
                const qObj = this.getAlternativeThoughtGivenKey(altKey)
                return(
                    <Grid container>
                        <Grid item xs={12}>
                        {/*<ShowLoveAfter ref={a => this._loveAfter = a} />*/}
                        {/*<Grid style={{ maxWidth: '60px' }}><ShowLoveBefore ref={a => this._loveBefore = a} /></Grid>*/}
                        {/*<Text className="fyw-font-dark fyw-font-bold">Thought {currentQuestion+1}. {this.state.data[currentQuestion]["q"]}</Text>*/}
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: proportionStr}}  aria-valuenow={proportion} aria-valuemin="0" aria-valuemax="100">{`${completed}/${total}`}</div>
                        </div>
                        <QuestionPanel key={currentQuestion} idx={currentQuestion} 
                            questions={qObj}
                            qKey={qObj['key']}
                            switchQuestion={this.switchQuestion}
                            storeCopingCards={this.storeCopingCards}
                            onCardRevealed={this.onCardRevealed}
                        />
                        {/*<Grid className='row d-flex justify-content-between' >
                            <button className='btn fyw-btn-dark btn-lg' onClick={() => {this.switchQuestion(-1)}}>Previous question</button>
                            <button className='btn fyw-btn-dark btn-lg' onClick={() => {this.switchQuestion(1)}}>Next question</button>
                        </Grid>*/}
                        {/*<AnswerPanel feedback={'feedback panel'} />*/}
                        </Grid>
                        <Grid>
                            <AnswerPanel {...this.props} />
                        </Grid>
                    </Grid>
                )
            }
            return (<Grid item xs={10} sm={8} className='mx-auto'>
                <Text className="fyw-font-dark">{this.props.lang.copingCards.congrats.replace('[number]', Object.keys(this.props.copingCards).length)}</Text>
            </Grid>)
            // console.log(qObj)
            // console.log('display filtered answers')
            // console.log(keys)
            // const currentQuestion = parseInt(keys[Object.keys(this.props.copingCards).length].replace('BCI-', ''), 10) - 1
            // console.log(currentQuestion)
            // if(qObj !== undefined)
            // {

            // }
        }
        // console.log('display normal answers')
        const currentQuestion = Object.keys(this.props.copingCards).length
        

        if(this.state.data[currentQuestion] !== undefined)
        {
          return(
            <Grid container>
                <Grid item xs={12}>
                {/*<ShowLoveAfter ref={a => this._loveAfter = a} />*/}
                {/*<Grid style={{ maxWidth: '60px' }}><ShowLoveBefore ref={a => this._loveBefore = a} /></Grid>*/}
                {/*<Text className="fyw-font-dark fyw-font-bold">Thought {currentQuestion+1}. {this.state.data[currentQuestion]["q"]}</Text>*/}

                <QuestionPanel key={currentQuestion} idx={currentQuestion} 
                    questions={this.state.data[currentQuestion]}
                    qKey={this.state.data[currentQuestion]['key']}
                    switchQuestion={this.switchQuestion}
                    storeCopingCards={this.storeCopingCards}
                    onCardRevealed={this.onCardRevealed}
                />
                {/*<Grid className='row d-flex justify-content-between' >
                    <button className='btn fyw-btn-dark btn-lg' onClick={() => {this.switchQuestion(-1)}}>Previous question</button>
                    <button className='btn fyw-btn-dark btn-lg' onClick={() => {this.switchQuestion(1)}}>Next question</button>
                </Grid>*/}
                {/*<AnswerPanel feedback={'feedback panel'} />*/}
                </Grid>
                <Grid>
                    <AnswerPanel {...this.props} />
                </Grid>
            </Grid>
            )
        }
        return (<Grid container>
            <Text className="fyw-font-dark fyw-font-bold">You have collected all the coping cards. You can review the cards below.</Text>
        </Grid>)
    }
}
  
class QuestionPanel extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            value: '',
            feedback: 'Drag the text to the right column to reveal the alternative thoughts, and then select the thought that you want to keep as your coping card.'
        }
        this.dragStart = this.dragStart.bind(this)
        this.allowDrop = this.allowDrop.bind(this)
        this.dragEnd = this.dragEnd.bind(this)
        // this.drop = this.drop.bind(this)
    }
    dragStart(event) {
        // event.dataTransfer.setData("Text", event.target.innerText);
        // this.setState({
        //   feedback: 'Drag start: '+event.target.innerText
        // })
        // document.getElementById("demo").innerHTML = "Started to drag the p element";
    }

    dragEnd(event) {
        // this.setState({
        //   feedback: 'Drag end: '+event.target.innerText
        // })
    }

    allowDrop(event) {
        event.preventDefault();
    }

    // drop(event, qKey, aKey) {
    //     event.preventDefault()
    //     console.log(`dropped ${qKey} and ${aKey}`)
    //     console.log(this.props.questions)
    //     // this.props.questions.forEach((q, idx) => {
    //     //     if(q.key === qKey)
    //     //     {
    //     //         console.log(q)
    //     //     }
    //     // })
    //     // if(event.target.getAttribute('hiddenvalue'))
    //     // {
    //     //     const hiddenValue = event.target.getAttribute('hiddenvalue');
    //     //     event.target.innerText = hiddenValue;
    //     //     event.target.classList.remove('concealed')
    //     //     // var data = event.dataTransfer.getData("Text");
    //     // }
    //     // this.setState({
    //     //   value: data
    //     // });
    //     // event.target.appendChild(document.getElementById(data));
    // }
    render() {
        const qc = []
        Object.keys(this.props.questions.a).map((key, idx) => {
            const targetQ = this.props.questions.a[key]
            qc.push(
                <DragBox 
                    key={idx}
                    qKey={this.props.qKey}
                    aKey={key}
                    dragStart={this.dragStart}
                    allowDrop={this.allowDrop}
                    drop={(e) => this.props.onCardRevealed(e, this.props.qKey, key)}
                    dragEnd={this.dragEnd}
                    q={this.props.questions.q}
                    question={targetQ.value || '???'}
                    storeCopingCards={this.props.storeCopingCards}
                    active={targetQ.active}
                />
            )
        })
        // .forEach((question, idx) => {
        //     qc.push(
        //     <DragBox 
        //         key={idx}
        //         qKey={this.props.qKey}
        //         dragStart={this.dragStart}
        //         allowDrop={this.allowDrop}
        //         drop={this.drop}
        //         dragEnd={this.dragEnd}
        //         q={this.props.questions.q}
        //         question={question}
        //         storeCopingCards={this.props.storeCopingCards}
        //     />
        //     )
        // })
        const currentIdx = this.props.idx+1;
        const str = `Question ${currentIdx}`;
        // console.log(`current idx is ${currentIdx}`)
        return(<>
        <Tip>{this.state.feedback}</Tip>
        
        <Grid className='row row-eq-height coping-card-drag-drop'  style={{ backgroundImage: `url(${PUBLIC_URL}img/coping-cards-bg-md.jpg)`}} >
            <Grid className='col-xs-6 col-md-6 border-right feedback-row'>
            <Grid className='col-xs-11 col-md-11 text-center question-box'
                onDragStart={this.dragStart}
                onDragOver={this.allowDrop}
                onDrop={this.drop} 
                onDragEnd={this.dragEnd}
                draggable={true} 
            >

                <Text className="fyw-font-dark broken-box-text p-3"> {this.state.value ? `${str} ${this.state.value}` : `${this.props.questions.q}` }</Text>
                <GetImg
                    className={['broken-box']}
                    onClick={() => null}
                    imageUrl={'broken_box-md.png'} // "name-of-the-img.jpg"
                    srcSet={{
                        sm: 'broken_box-sm.png',
                        md: 'broken_box-md.png',
                        lg: 'broken_box-lg.png',
                        xl: 'broken_box-xl.png'
                    }}
                    alt={'challenge thoughts'}
                />

            </Grid>
            </Grid>
            <Grid className='col-xs-6 col-md-6'>
            <ul className=''>{qc}</ul> 
            </Grid>

        </Grid>
        
        </>)
    }
}

/**
 <DragBox 
    key=1
    dragStart={function}
    allowDrop={function}
    drop={function}
    dragEnd={function}
    question='question'
    active={false}
/>
*/
const DragBox = (props) => {
    // console.log(props)
    let [animation, editAnimation] = useState(true)
    if(props.active)
    {
        setTimeout(() => {
            editAnimation(false)
        }, 2000)
    }
    return (
        <Grid item xs={12} className='d-flex flex-row justify-content-evenly align-items-center' style={{ minHeight: '40px', position: 'relative' }}>
            <li 
                style={props.active ? {
                    backgroundImage: `url('${init.serverUrl}img/blossom_box-md.png')`,
                    backgroundPosition: 'left bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }: null}
                // onMouseEnter={() => editAnimation(true)} onMouseLeave={() => {
                //     setTimeout(() => {
                //         editAnimation(false)
                //     }, 2000)
                // }} 
                // draggable={true}
                onDragStart={props.dragStart}
                onDragOver={props.allowDrop}
                onDrop={(e) => props.drop(e, props.qKey, props.aKey)} 
                onDragEnd={props.dragEnd}
                onClick={e => {
                    // check if all the questions have been revealed
                    // console.log('test')
                    const cardObj = {
                        key: props.qKey,
                        aKey: props.aKey,
                        question: props.q,
                        answer: props.question
                    }
                    props.storeCopingCards(cardObj)
                }}
                hiddenvalue={props.question}
                value={props.question}
                className='py-3 pb-5 drag-box fyw-font-dark concealed'
            >
            {props.active ? props.question : '???'}
            </li>
            {props.active ?
                <Grid className={animation ? 'feedback-svg' : 'feedback-svg fade-out-svg'} style={{ maxWidth: '60px', minHeight: '40px', maxHeight: '80px', height: '50px' }}><ShowLoveBefore className='' style={{ height: '100%' }} /></Grid>
            :null}
            
        </Grid>
    )
}

const AnswerPanel = props => {
    // console.log(props)
    const cards = props.copingCards
    let cardComponents = []

    return(
        <Grid item xs={12}>

        </Grid>
    )
}

const ShowLoveBefore = React.forwardRef((props, ref) => {
    return (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={props.className || ''} style={props.style || {}} >
      <Tween
        wrapper={<svg id="show-love" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" />}
        from={{ y: 80 }} to={{ y: 0 }}
        duration={1}
      >
        {/*<circle stroke="#c54e4b" strokeWidth="1" id="show-love-before" cx="50" cy="50" r="5.6" fill="#c54e4b"/>*/}
  
        <path fill="#c64f4c" d="M57.1 22.2c-2.8 0-5.3 1.1-7.1 2.9-1.8-1.8-4.3-2.9-7.1-2.9-5.5 0-10 4.5-10 10 0 2.8 1.1 5.3 2.9 7.1L50 53.5l14.2-14.2c1.8-1.8 2.9-4.3 2.9-7.1 0-5.5-4.5-10-10-10zm-14.2 4.3c-3.2 0-5.7 2.6-5.7 5.7h-2c0-4.3 3.5-7.8 7.8-7.8l-.1 2.1z"/>
      </Tween>
      <path fill="#fff" d="M29.1 71.2v16.3l6.2-.4 20.4 7.5L81.3 78v-2.3L79.8 74l-2.5.4-11.1 6.2-.1-3.3-2-.5-15.1-.3-8.9-5.3-7.5-1z"/>
      <path fill="#485c7b" d="M17.3 65.5v26.8h13.2V65.5H17.3zm10.9 22.8H26V86h2.3v2.3z"/>
      <path d="M54.8 95.6c-1 0-2-.2-2.9-.5l-17.1-6.9h-4.3V86h4.7l17.5 7c.9.3 1.8.4 2.7.3.9-.1 1.8-.4 2.6-.9l21.6-14.1c.4-.3.6-.7.7-1.2.1-.5 0-1-.3-1.3-.3-.4-.7-.6-1.1-.7-.4-.1-.9 0-1.3.2l-11.5 7.2-1.2-1.9 11.5-7.2c.9-.5 1.9-.7 2.9-.5 1 .2 1.9.7 2.5 1.6.7.9.9 2 .8 3.1-.2 1.1-.7 2.1-1.6 2.7l-21.7 14c-1.1.7-2.3 1.1-3.6 1.3h-.9z" className="st2"/>
      <path d="M46.8 85.1h-2.3c0-2.2 1.8-4 4-4h15.1c1 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7H48.2L42.6 74c-1.1-.7-2.3-1.3-3.6-1.6-1.3-.4-2.6-.6-3.9-.6h-4.6v-2.3h4.6c1.5 0 3.1.2 4.6.7 1.5.4 2.9 1.1 4.2 1.9l5 3.2h14.7c2.2 0 4 1.8 4 4s-1.8 4-4 4H48.5c-1 0-1.7.8-1.7 1.8z" className="st2"/>
    </svg>
    )
})

export default CardsComponent