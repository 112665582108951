import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import init from '../../config/init'
import { Paragraph, Subtitle } from '../../assets/Text'
import { Divider } from '../../assets/UIElements'
import NavSection from '../containers/NavBar'
const griefResources = init.griefResources

const Resources = props => {
    const dict = props.lang

    let covid19List = [], onlineResourcesList = [], hotlineResourcesList = [];
    griefResources.online.forEach((resource, idx) => {
        onlineResourcesList.push(
            <li key={`online-resource-list-${idx}`} className='fyw-font-dark'><a href={resource.url}>{`${resource.title}`}</a> {`${resource.value}`}</li>
        )
    });
    griefResources.covid19.forEach((resource, idx) => {
        covid19List.push(
            <li key={`covid19-resource-list-${idx}`} className='fyw-font-dark'><a href={resource.url}>{`${resource.title}`}</a> {`${resource.value}`}</li>
        )
    })
    griefResources.hotline.forEach((resource, idx) => {
        hotlineResourcesList.push(
            <li key={`hotline-resource-list-${idx}`} className='fyw-font-dark'>{`${resource.title}: ${resource.value}`}</li>
        )
    });
    return(<NavSection>
            <div className="row text-center m-auto">
                <Subtitle className='mx-auto mt-5'>{dict.buildingAndBenefiting.toUpperCase() || ''}</Subtitle>
            </div>
            <Divider />
            <Paragraph className="fyw-font-dark mx-auto text-center" style={{width: '80%'}}>
                {dict.findGriefResources}
            </Paragraph>
                <Grid item xs={12} sm={10} className='mx-auto'>
                    <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center'><h3 className='fyw-font-dark'></h3></div>
                    <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 text-center mx-auto'><p className='fyw-font-dark'></p></div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto'>
                        <h4 className='fyw-font-dark'>{dict.covid19}</h4>
                            <ul>
                                {covid19List}
                            </ul>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto'>
                        <h4 className='fyw-font-dark'>{dict.onlineGriefResources}</h4>
                            <ul>
                                {onlineResourcesList}
                            </ul>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto'>
                        <h4 className='fyw-font-dark'>{dict.hotlines}</h4>
                            <ul>
                                {hotlineResourcesList}
                            </ul>
                        </div>
                    </div>
                </Grid>
        </NavSection>
    )
}

export default Resources