import methods from '../methods'
import { combineReducers } from 'redux'
import initProps from '../config/init'
import GH from '../helpers/GeneralHelper'

const mk = Object.keys(initProps.modules)[0]
const initModule = initProps.modules[mk]
const ck = Object.keys(initProps[initModule]['components'])[0]
const initComponent = initProps[initModule]['components'][ck]

const defaultProgress = {
    module: initModule,
    component: initComponent
}

const progress = (state = defaultProgress, action) => {
    // console.log(action)

    switch(action.type)
    {
        case methods.UPDATE_MODULE_PROGRESS:
            // console.log(action.payload)
            return action.payload || defaultProgress
        default: return state
    }
}


const copingCards = (state = {}, action) => {
    let newState = {...state}
    switch(action.type)
    {
        case methods.CLEAR_COPING_CARD:
            return {}
        case methods.ADD_COPING_CARD:
            const qKey = action.payload.key
            newState[qKey] = action.payload
            // console.log(newState)
            return newState
        case methods.REMOVE_COPING_CARD:
            if(newState.hasOwnProperty(action.payload.key))
            {
                delete newState[action.payload.key]
            }
            return newState
        case methods.LOAD_COPING_CARD:
            // console.log(action.payload)
            return action.payload
        default: return state
    }
}


const socialCompassContacts = (state = {}, action) => {
    let newState = {...state}
    switch(action.type)
    {
        case methods.LOAD_SC_CONTACTS:
            let newObj = action.payload
            Object.keys(newObj).map(k => {
                if(newState.hasOwnProperty(k))
                {
                    const joinObj = GH.joinObjs(newState[k], newObj[k])
                    newState[k] = joinObj
                    return
                }
                newState[k] = newObj[k]
            })
            return newState
        case methods.REMOVE_SC_CONTACTS:
            let obj = action.payload
            // console.log(newState)
            // console.log(obj)
            const key = obj.type
            const contactKey = obj.key
            if(newState[key] !== undefined && newState[key].hasOwnProperty(contactKey))
            {
                delete newState[key][contactKey]
            }
            // console.log(newState)
            return newState
        default: return state
    }
}

const socialCompassSupportTypes = (state = '', action) => {
    switch(action.type)
    {
        case methods.LOAD_ACTIVE_WHEELS:
            return action.payload
        default: return state
    }
}

export default combineReducers({ progress, copingCards, socialCompassContacts, socialCompassSupportTypes })
