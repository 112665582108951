import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { withAuthenticator } from 'aws-amplify-react'
import Login from '../ui/Login'
import { authUser, clearAuth, clearLoginErr, loadSession } from '../../actions'
import initLang from '../../lang/initLang'


const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['connectingModule'],
    auth: state.userReducer.auth,
    userInfo: state.userReducer.accountInfo,
    session: state.userReducer.session,
    loginErr: state.userReducer.loginErr,
})

const mapDispatchToProps = dispatch => ({
    login: (auth, session) => (dispatch(authUser(auth, session))),
    logout: (auth) => (dispatch(clearAuth(auth))),
    clearLoginErr: (err) => (dispatch(clearLoginErr(err))),
    getSession: () => dispatch(loadSession())
})

const Container = connect(mapStateToProps, mapDispatchToProps)(Login)
export default withRouter(Container)
// export default withAuthenticator(withRouter(Container), {
//     signUpConfig,
//     usernameAttributes
//   })