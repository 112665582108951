import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput, SelectItem, TextArea, Checkbox } from '../../../assets/Form'
import { Paragraph, Text, Tip } from '../../../assets/Text'
import { Alert } from '../../../assets/UIElements'
import { Button } from '../../../assets/Buttons'
import AVH from '../../../helpers/AuthValidateHelper'
import UserProfileHelper from '../../../helpers/UserProfileHelper'
import GH from '../../../helpers/GeneralHelper'
import matchConfig from '../../../config/matchConfig'
import '../../../scss/signup.scss'

// import GetImg from '../../../assets/GetImg'

const relationshipArr = matchConfig.form.relationshipArr
const gender = matchConfig.form.genderArr
const states = matchConfig.form.states

const Register = props => {

    const [hidePass, changeHide] = useState(true)
    const [email, updateEmail] = useState('')
    const [name, updateName] = useState('')
    const [password, updatePassword] = useState('')
    const [birthYear, updateBirthYear] = useState(null)
    const [deceased, updateDeceased] = useState(relationshipArr[0]['value'])
    const [sex, updateSex] = useState(gender[0]['value'])
    const [location, updateLocation] = useState(states[0]['value'])
    const [story, updateStory] = useState('')
    const [disclaimer, checkDisclaimer] = useState(false)

    //validate password input
    const [passValidated, updatePassValidated] = useState(true)
    const [lowercase, updateLowercase] = useState(false)
    const [capital, updateCapital] = useState(false)
    const [number, updateNumber] = useState(false)
    const [special, updateSpecial] = useState(false)
    const [minimum, updateMinimum] = useState(false)

    //validate submitted content
    const [emailValidated, updateEmailValidated] = useState('')
    const [passError, updatePassError] = useState('')
    const [nameError, updateNameError] = useState('')
    const [birthError, updateBirthError] = useState('')
    const [deceasedError, updateDeceasedError] = useState('')
    const [sexError, updateSexError] = useState('')
    const [locationError, updateLocationError] = useState('')
    const [storyError, updateStoryError] = useState('')
    const [disclaimerError, updateDisclaimerError] = useState('')
    
    //register error
    const [registerError, updateRegisterError] = useState('')
    const [registerSuccessful, updateSuccess] = useState(false)

    // use React hook to add event listener for enter event.
    // useEffect(() => {
    //   if(document.getElementById('match-register-form') !== null)
    //   {
    //     document.getElementById('match-register-form').addEventListener('keydown', handleSubmit)
    //   }

    //   return () => {
    //     if(document.getElementById('match-register-form') !== null && document.getElementById('match-register-form').removeEventListener === 'function')
    //     {
    //       document.getElementById('match-register-form').addEventListener('keydown', handleSubmit)
    //     }
    //   }
    // })

    const registerFunction = async () => {
      const emailValid = await AVH.validateEmail(email)
      if(!emailValid) updateEmailValidated(props.lang.registerValid.email)
      if(name === '') updateNameError(props.lang.registerValid.displayname)
      if(password === '' || !passValidated) updatePassError(props.lang.registerValid.password)
      if(birthYear === null) updateBirthError(props.lang.registerValid.birthyear)
      if(deceased === '' || deceased === '0') updateDeceasedError(props.lang.registerValid.relationship)
      if(sex === '' || sex === '0') updateSexError(props.lang.registerValid.gender)
      if(location === '' || location === '0') updateLocationError(props.lang.registerValid.location)
      if(story === '') updateStoryError(props.lang.registerValid.required)
      if(!disclaimer) updateDisclaimerError(props.lang.registerValid.disclaimer)

      if(emailValid && name !== '' && password !== '' 
        && birthYear !== null && deceased !== '' && deceased !== '0' && sex !== '' && sex !== '0'
        && location !== '' && location !== '0' && story !== '' && disclaimer)
      {
        // console.log('you can register')
        const d = new Date()
        const y = d.getFullYear()
        const age = Math.abs(y - birthYear).toString()
        const userObj = {
          "username": GH.removeWhiteSpace(email),
          "password": GH.removeWhiteSpace(password), 
          "displayName": name,
          "age": age,
          "relationship": deceased,
          "gender": sex,
          "state": location,
          "lossExperience": story
        }
        // console.log(userObj)
        // updateSuccess(true)
        const result = await UserProfileHelper.registerUser(userObj)
        // console.log(result)

        if(!result)
        {
          return updateRegisterError(props.lang.registerError)
        }
        return updateSuccess(true)
      }
    }

    const handleSubmit = async (e) => {
      // e.preventDefault()
      if(e.key === 'Enter' || e.keyCode === 13)
      {
        await registerFunction()
        return 
      }
    }

    if(!registerSuccessful)
    {
      return (<Grid container className='mt-3 mx-auto'>
      <Grid item xs={12} sm={10} md={8} className='mx-auto'>
          <Grid className='mx-auto text-center'>
              <Paragraph className='fyw-font-dark fyw-font-bold my-5'>{props.lang.register}</Paragraph>
          </Grid>
          <Grid item xs={12} sm={8} md={8} className='mx-auto' id='match-register-form'>
          <Tip className='fyw-font-grey'>{props.lang.fillInformation}</Tip>
          <form onKeyPress={handleSubmit}>
              <TextInput 
                  id='match-username'
                  type={"text"}
                  label={props.lang.emailAddress}
                  ariaLabel='email address'
                  icon='envelope'
                  withAdornment={false}
                  placeholder={props.lang.yourEmail}
                  error={emailValidated}
                  onChange={e => {
                    updateEmailValidated('')
                    updateEmail(e.target.value)
                  }}
              />
              <TextInput 
                  id='match-password'
                  // ref={pass => this._password = pass}
                  type={hidePass ? "password" : "text"}
                  label={props.lang.passwordLabel}
                  ariaLabel='password'
                  icon='lock'
                  endAdornmentIcon={hidePass ? "eye-slash" : "eye"}
                  endAdornmentLabel={props.lang.togglePassVisibility}
                  withAdornment={true}
                  handleEndAdornmentClick={() => changeHide(!hidePass)}
                  placeholder={props.lang.yourPassword}
                  onChange={e => {
                      updatePassword(e.target.value)
                      const validate = AVH.validatePassword(e.target.value)
                      if(typeof validate === 'object')
                      {
                          updatePassValidated(false)
                          updateLowercase(validate.lowercase)
                          updateCapital(validate.uppercase)
                          updateNumber(validate.number)
                          updateSpecial(validate.special)
                          updateMinimum(validate.length)
                      }
                      else
                      {
                          updatePassValidated(true)
                      }
                      // console.log(validate)
                  }}
              />
              {!passValidated ? <div className="pass-valid-section show">
                  <div className="mx-auto col-sm-12">
                      <Text className="pass-validation">{props.lang.passwordNotice}</Text>
                      <Tip className={lowercase ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.lowercase}</Tip>
                      <Tip className={capital ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.uppercase}</Tip>
                      <Tip className={number ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.number}</Tip>
                      <Tip className={special ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.specialChar}</Tip>
                      <Tip className={minimum ? "pass-validation valid" : "pass-validation invalid"}>{props.lang.passCriteria.eightChar}</Tip>
                  </div>
              </div>
              :null}
              <TextInput 
                id='match-displayname'
                // ref={pass => this._password = pass}
                type={"text"}
                label={props.lang.displaynameLabel}
                ariaLabel='display name'
                value={name}
                icon='user-alt'
                withAdornment={false}
                placeholder={props.lang.displaynamePlaceholder}
                error={nameError}
                onChange={e => {
                  updateNameError('')
                  updateName(e.target.value)
                }}
              />
              <TextInput 
                  id='match-birthyear'
                  // ref={pass => this._password = pass}
                  type={"number"}
                  label={props.lang.birthLabel}
                  ariaLabel='birth year'
                  icon='calendar-alt'
                  // value={birthYear}
                  withAdornment={false}
                  placeholder={props.lang.birthPlaceholder}
                  error={birthError}
                  onChange={e => {
                    updateBirthError('')
                    updateBirthYear(e.target.value)
                  }}
              />
              <SelectItem 
                  options={relationshipArr}
                  id="match-relationship"
                  label={props.lang.relationshipLabel}
                  name="relationship"
                  inputValue={deceased}
                  error={deceasedError}
                  handleInput={e => {
                    updateDeceasedError('')
                    updateDeceased(e.target.value)
                  }}
              />
              <SelectItem 
                  options={gender}
                  id="match-gender"
                  label={props.lang.genderLabel}
                  name="gender"
                  inputValue={sex}
                  error={sexError}
                  handleInput={e => {
                    updateSexError('')
                    updateSex(e.target.value)
                  }}
              />
              <SelectItem 
                  options={states}
                  id="match-states"
                  label={props.lang.locationLabel}
                  name="states"
                  inputValue={location}
                  error={locationError}
                  handleInput={e => {
                    updateLocationError('')
                    updateLocation(e.target.value)
                  }}
              />
              <TextArea 
                  type={"text"}
                  label={props.lang.lossStoryLabel}
                  placeholder={props.lang.lossStoryPlaceholder}
                  ariaLabel='story'
                  error={storyError}
                  helperText={`${story.length}/750 ${props.lang.words}`}
                  onChange={e => {
                    // console.log(e.target.value.length)
                    if(e.target.value.length > 750)
                    {
                      updateStoryError(`${props.lang.wordLimitWarning} ${story.length}/750 ${props.lang.words}`)
                      updateStory(e.target.value)
                      return
                    }
                    updateStoryError('')
                    updateStory(e.target.value)
                  }}
                  rows={4}
              />
              <Checkbox 
                  label={props.lang.disclaimer}
                  checked={disclaimer}
                  error={disclaimerError}
                  onChange={() => {
                    updateDisclaimerError('')
                    checkDisclaimer(!disclaimer)
                  }}
              />
              <Button tabIndex='0' className='mx-auto mt-4' onClick={registerFunction}>{props.lang.submit}</Button>
          </form>
          {registerError !== '' ? <Grid className='w-100 d-flex justify-content-center'>
              <Alert><Text className='fyw-font-dark mx-auto'>{registerError}</Text></Alert>
            </Grid>
          :null}
          </Grid>
          {props.hideLogin ? <Grid className='m-5'></Grid>:<Grid className='mx-auto d-flex justify-content-center flex-column'>
            <Text className='fyw-font-dark mx-auto'>{props.lang.joined}
              <span className='href-link ml-2 fyw-font-bold' tabIndex="0" aria-label='join now' role='link' onClick={() => {
                  props.switchToLogin(true)
              }}>{props.lang.loginNow}</span>
            </Text>
          </Grid>}
      </Grid>
          
      </Grid>)
    }
    return <Grid container className='mt-3 mx-auto'>
    <Grid item xs={12} sm={10} md={8} className='mx-auto'>
      <Grid className='mx-auto text-center'>
        <Paragraph className='fyw-font-dark fyw-font-bold my-5'>{props.lang.complete}</Paragraph>
        <Paragraph className='fyw-font-grey'>{props.lang.thankYou}</Paragraph>
        {props.hideLogin ? 
          <Button className='fyw-btn-dark mx-auto' onClick={() => {
            props.switchToLogin(true)
          }}>{props.lang.backToIndex}</Button>
          :
          <Button className='fyw-btn-dark mx-auto' onClick={() => {
            props.switchToLogin(true)
          }}>{props.lang.backToLogin}</Button>
        }

      </Grid>
    </Grid>
    </Grid>

}


export default Register
