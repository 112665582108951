import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import STM from '../../helpers/TimeManage'
import { Paragraph, Tip } from '../../assets/Text'
import NavSection from '../containers/NavBar'
import { Button } from '../../assets/Buttons'
import { TitleSecion, Alert } from '../../assets/UIElements'
import GetImg from '../../assets/GetImg'
import paths from '../../config/paths'
import '../../scss/index.scss'

class Index extends Component {
    constructor(props)
    {
        super(props)
    }

    render()
    {
        return(
            <NavSection>
                <TitleSecion title={this.props.lang.title} subtitle={this.props.lang.subtitle} />
                
                <Grid item xs={12} sm={10} md={8} className='mx-auto'>
                <Paragraph>{this.props.lang.accessContent}</Paragraph>
                <Paragraph className=''>{this.props.lang.survey}</Paragraph>
                <Paragraph className='pb-3 text-center'><a href={'https://weillcornell.az1.qualtrics.com/jfe/form/SV_1ySeUv686l0VLtb'} target='_blank'>
                {this.props.lang.covidAssessment}</a></Paragraph>
                <Paragraph className='fyw-font-dark fyw-font-bold'>{this.props.lang.access}</Paragraph>

                <Grid container className='mx-auto' spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <Card className={'fyw-scale-card'} onClick={() => {
                        this.props.history.push(paths.pg13)
                    }}>
                        <CardActionArea>
                        <GetImg 
                            className={['mx-auto', 'w-100']}
                            onClick={null}
                            imageUrl={'grief-intensity-scale-md.jpg'} // "name-of-the-img.jpg"
                            srcSet={{
                                sm: 'grief-intensity-scale-sm.jpg',
                                md: 'grief-intensity-scale-md.jpg',
                                lg: 'grief-intensity-scale-lg.jpg',
                                xl: 'grief-intensity-scale-xl.jpg'
                            }}
                            alt={'Grief Intensity Scale'}
                        />
                        <CardContent>
                            <Paragraph>
                            {this.props.lang.pg9.toUpperCase()}
                            </Paragraph>
                            <Tip className='fyw-tip-brown'>
                            {this.props.lang.takePG9}
                            </Tip>
                        </CardContent>
                        </CardActionArea>
                    </Card>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <Card className={'fyw-scale-card'}  onClick={() => {
                        this.props.history.push(paths.bci)
                    }}>
                        <CardActionArea>
                        <GetImg 
                            className={['mx-auto', 'w-100']}
                            onClick={null}
                            imageUrl={'bereavement-challenges-inventory-md.jpg'} // "name-of-the-img.jpg"
                            srcSet={{
                                sm: 'bereavement-challenges-inventory-sm.jpg',
                                md: 'bereavement-challenges-inventory-md.jpg',
                                lg: 'bereavement-challenges-inventory-lg.jpg',
                                xl: 'bereavement-challenges-inventory-xl.jpg'
                            }}
                            alt={'Bereavement Challenges Inventory'}
                        />
                        <CardContent>
                            <Paragraph>
                            {this.props.lang.bci.toUpperCase()}
                            </Paragraph>
                            <Tip className='fyw-tip-brown'>
                            {this.props.lang.takeBCI}
                            </Tip>
                        </CardContent>
                        </CardActionArea>
                    </Card>
                    </Grid>

                </Grid>
                </Grid>


                {/*<Article 
                    akey="bereavement-intro" 
                    title={this.props.lang.bereavementTitle} 
                    content={this.props.lang.bereavementIntro}
                    className='mx-auto my-4'
                    author={['Madeline Rogers, LMSW', 'Holly Prigerson, PhD']}
                    date={STM.convertTimeToString(new Date('2020-3-30'))}
                    alt='bereavement intro'
                    srcSet={{
                        sm: 'hand-hearts-sm.jpg',
                        md: 'hand-hearts-md.jpg',
                        lg: 'hand-hearts-lg.jpg',
                        xl: 'hand-hearts-xl.jpg'
                    }}
                >
                <div className='d-flex'>
                    <Button className='m-auto fyw-btn-light' onClick={() => {
                        window.location.replace('/pg13')
                    }}>Take Grief Intensity Scale</Button>
                    <Button className='m-auto fyw-btn-light' onClick={() => {
                        window.location.replace('/bci')
                    }}>Take Bereavement Challege Inventory</Button>
                </div>

                </Article>*/}
                
            </NavSection>
        )
    }
}


const Article = props => {

    const a = props.content.split('<br/>')
    let as = [], author = ''
    a.forEach((s, idx) => {
        as.push(<Paragraph key={`article-${props.akey}-${idx}`} className="fyw-font-dark mx-auto">
            {s || ''}
        </Paragraph>)
    })
    if(props.hasOwnProperty('author') && props.author.length > 0)
    {
        if(props.author.length === 1)
        {
            author = props.author.join('')
        }
        else
        {
            const l = props.author.length
            props.author.forEach((a, i) => {
                if(i+1 === l)
                {
                    author += ` and ${a}`
                }
                else if(i === 0)
                {
                    author += a
                }
                else
                {
                    author += `, ${a}`
                }
            })
        }
    }


    return <Grid item xs={12} sm={10} md={8} className='m-auto' {...props}>
        {props.title ? <Paragraph className='fyw-font-bold fyw-font-dark'>{props.title}</Paragraph>:null}
        {props.author ? <Tip className='fyw-font-grey'>{`By ${author}`}</Tip>:null}
        {props.date ? <Tip className='fyw-font-grey'>{props.date}</Tip>:null}
        <div className='w-100 text-center'>
        <GetImg
            className={['mx-auto', 'fyw-article-image']}
            onClick={null}
            imageUrl={props.srcSet.md} // "name-of-the-img.jpg"
            srcSet={props.srcSet}
            alt={props.alt}
            style={{
                maxWidth: '500px'
            }}
        />
        </div>
        {as}
        {props.children}
    </Grid>
}

export default Index