import { combineReducers } from 'redux'
import methods from '../methods'
import matchConfig from '../config/matchConfig'
import GH from '../helpers/GeneralHelper'

const page = (state = matchConfig.pages.login, action) => {

    switch(action.type)
    {
        case methods.SWITCH_PAGE:
            return action.payload
        default: return state
    }
}

const hugs = (state = [], action) => {
    switch(action.type)
    {
        case methods.UPDATE_HUGS:
            return action.payload
        default: return state
    }
}

const supportiveMessages = (state = [], action) => {
    switch(action.type)
    {
        case methods.UPDATE_MSGS:
            return action.payload
        default: return state
    }
}

const profile = (state = {}, action) => {
    let newState = {...state}
    switch(action.type)
    {
        case methods.UPDATE_PROFILE:
            let combined = GH.joinObjs(newState, action.payload)
            return combined
        default: return state
    }
}

const matches = (state = [], action) => {
    switch(action.type)
    {
        case methods.UPDATE_MATCHES:
            return action.payload
        default: return state
    }
}

const connectedUsers = (state = [], action) => {
    switch(action.type)
    {
        case methods.UPDATE_CONNECT_USERS:
            return action.payload
        default: return state
    }
}

export default combineReducers({ page, hugs, supportiveMessages, profile, matches, connectedUsers })
