import React from 'react'
// import Auth from './Auth'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import STM from '../../helpers/TimeManage'

function withAuth(WrappedComponent) {

    return class extends React.Component {

        constructor(props) {
            super(props)
        }

        render() {
            const loggedIn = AuthenticationHelper.hasAuthenticated()

            if(!loggedIn) 
            {
                return <WrappedComponent {...this.props} />
            }
            // check the validity of the expiry date
            const token = AuthenticationHelper.decodeToken(AuthenticationHelper.token)

            let exp = new Date(token.exp * 1000)
            exp = STM.convertLocaltoUTC(exp, false)
            let current = new Date()
            current = STM.convertLocaltoUTC(current)
            let expired = STM.compareDate(exp, current, 12)

            if(expired)
            {
                AuthenticationHelper.resetToken()
                return <WrappedComponent {...this.props} />
            }
            return <WrappedComponent {...this.props} />
        }
    }
}

export default withAuth