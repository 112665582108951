import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import GH from '../../../helpers/GeneralHelper'
import { Text, Paragraph, Tip } from '../../../assets/Text'
import { Divider, Alert } from '../../../assets/UIElements'
import { ShowLoveBefore } from '../../../assets/SVGIcons'
import { Button } from '../../../assets/Buttons'
import GetImg from '../../../assets/GetImg'
import initProps from '../../../config/init'
import paths from '../../../config/paths'
import '../../../scss/coping-module.scss'
import DH from '../../../helpers/DatabaseHelper'
import SH from '../../../helpers/SurveyHelper'
import CardsComponent from './CardsComponent'
import CollectedCards from './CollectedCards'

const config = initProps
const PUBLIC_URL = config.serverUrl

const DataComponent = (deceased = 'the deceased') => {
    return [
        {
          "q": "I believe that my life will never be as meaningful again.",
          "key": "q1",
          "a": {
            "q1a1": {
              "value": `Things feel really hard now, but in time, I can probably find some things that fulfill me.`,
              "active": false
            },
            "q1a2": {
              "value": `My life may not be meaningful in the same way as before but I will continue to live my life with meaning.`,
              "active": false
            },
            "q1a3": {
              "value": `My current emotions are strong and painful, but there's no evidence to support the idea that I'll never find meaning in life again. `,
              "active": false
            },
            "q1a4": {
              "value": `While ${deceased} was very important to me, there are still other meaningful things in my life.`,
              "active": false
            }
          }
        },
        {
          "q": "I believe that my life will never be as happy or enjoyable again.",
          "key": "q2",
          "a": {
            "q2a1": {
              "value": `Although many things have changed, I can find things to look forward to.`,
              "active": false
            },
            "q2a2": {
              "value": `I may not experience happiness and joy in the same way as before but I wil continue to have moments of happiness.`,
              "active": false
            },
            "q2a3": {
              "value": `My current emotions are strong and painful, but there's no evidence to support the idea that I'll never feel happy again.`,
              "active": false
            },
            "q2a4": {
              "value": `It might take some time, but I will eventually be able to enjoy things in a different way.`,
              "active": false
            }
          }
        },
        {
          "q": "This death hurt me and I don't want to be hurt like this again.",
          "key": "q3",
          "a": {
            "q3a1": {
              "value": `I am hurt, but I also am able to get through this. Making close connections will help life feel worth living.`,
              "active": false
            },
            "q3a2": {
              "value": `This death hurt me because I care so much about the person I lost. I still have the capacity and desire to care about others.`,
              "active": false
            },
            "q3a3": {
              "value": `This pain is a sign of my ability to fully love and connect with others.`,
              "active": false
            },
            "q3a4": {
              "value": `Even though I don't know what the future holds -- right now, other people can help and support me through this pain.`,
              "active": false
            }
          }
        },
        {
          "q": "I worry about getting close to someone only to lose him/her again.",
          "key": "q4",
          "a": {
            "q4a1": {
              "value": `It makes sense that I would worry about this, but it still is worth making close connections.`,
              "active": false
            },
            "q4a2": {
              "value": `I worry about losing another person but having close relationships is worth the risk.`,
              "active": false
            },
            "q4a3": {
              "value": `Close relationships are an important part of my life that I don't want to be without.`,
              "active": false
            },
            "q4a4": {
              "value": `It is not guaranteed that I won't lose someone again, but getting close support is worth the risk. `,
              "active": false
            }
          }
        },
        {
          "q": "It is hard to trust people since the death.",
          "key": "q5",
          "a": {
            "q5a1": {
              "value": `I can be selective about those who are worthy of my trust.`,
              "active": false
            },
            "q5a2": {
              "value": `It can be hard to trust some people but there are some I can rely on.`,
              "active": false
            },
            "q5a3": {
              "value": `I'll seek out those who care and feel trustworthy and focus on those relationships.`,
              "active": false
            },
            "q5a4": {
              "value": `It is normal for me to have trouble trusting people, but there is at least one person I can trust.`,
              "active": false
            }
          }
        },
        {
          "q": "I believe that it's too late for me to try new things or develop new relationships.",
          "key": "q6",
          "a": {
            "q6a1": {
              "value": `I can think of people who have started new chapters later in their lives.`,
              "active": false
            },
            "q6a2": {
              "value": `It might be hard to try new things and develop new relationships, but it is never too late to try. `,
              "active": false
            },
            "q6a3": {
              "value": `Every day I have is an opportunity to try something new.`,
              "active": false
            },
            "q6a4": {
              "value": `Trying new things can be hard, but there are small steps I can take.  I have the chance each day to try something new.`,
              "active": false
            }
          }
        },
        {
          "q": "I am afraid of the unknown.",
          "key": "q7",
          "a": {
            "q7a1": {
              "value": `This is scary, but I have gotten through new situations before.`,
              "active": false
            },
            "q7a2": {
              "value": `The unknown can seem scary at first but I have faced challenging situations before.`,
              "active": false
            },
            "q7a3": {
              "value": `It's normal to be afraid, but I have the courage to move forward in spite of this fear.`,
              "active": false
            },
            "q7a4": {
              "value": `The unknown can be scary, or it could be an opportunity to grow.  I have the ability to handle it either way.`,
              "active": false
            }
          }
        },
        {
          "q": "I don't like to have to change the way I do things.",
          "key": "q8",
          "a": {
            "q8a1": {
              "value": `I don't like change, but I can handle it.`,
              "active": false
            },
            "q8a2": {
              "value": `I can find ways to keep some of the important things the same in my life.`,
              "active": false
            },
            "q8a3": {
              "value": `It might be hard to change the way I do things but I have done it in the past.`,
              "active": false
            },
            "q8a4": {
              "value": `I don’t like change, but I can handle the discomfort to adapt as best I can.`,
              "active": false
            },
            "q8a5": {
              "value": `I can try a small change, and see how I feel.  I don't have to change everything at once.`,
              "active": false
            }
          }
        },
        {
          "q": "I believe that I'm too old to start over or start a new life.",
          "key": "q9",
          "a": {
            "q9a1": {
              "value": `There are always opportunities to learn new things and meet new people.`,
              "active": false
            },
            "q9a2": {
              "value": `I feel like I am starting over but there are actually many aspects of my life that have remained the same.`,
              "active": false
            },
            "q9a3": {
              "value": `Because I'm older, I have life experience that will help me to get through this situation. `,
              "active": false
            },
            "q9a4": {
              "value": `Sometimes "later" becomes "never"`,
              "active": false
            }
          }
        },
        {
          "q": "I'm so emotional that people don't want to spend time with me.",
          "key": "q10",
          "a": {
            "q10a1": {
              "value": `The people closest to me understand.`,
              "active": false
            },
            "q10a2": {
              "value": `I can be emotional without being overwhelming.`,
              "active": false
            },
            "q10a3": {
              "value": `I can find ways to let my emotions out when I am on my own.`,
              "active": false
            },
            "q10a4": {
              "value": `My emotions can make me a more compassionate person and some people will appreciate that.`,
              "active": false
            },
            "q10a5": {
              "value": `People who don't want to spend time with me because of this probably aren't the best people for me to be around right now.`,
              "active": false
            },
            "q10a6": {
              "value": `Everyone goes through painful times. It's okay to show my emotions, because other people might relate.`,
              "active": false
            },
            "q10a7": {
              "value": `If my friend was emotional, I'd want to be there for them - It's possible that people feel the same way about me.`,
              "active": false
            }
          }
        },
        {
          "q": "I depress people who spend time with me.",
          "key": "q11",
          "a": {
            "q11a1": {
              "value": `I can be sad without being depressing.`,
              "active": false
            },
            "q11a2": {
              "value": `I can explain to others what I need when I am sad`,
              "active": false
            },
            "q11a3": {
              "value": `I will find people who want to spend time with me even when I am sad.`,
              "active": false
            },
            "q11a4": {
              "value": `When I've seen someone else grieving, they don't bring me down.`,
              "active": false
            }
          }
        },
        {
          "q": "I'm so angry about the death that it is unpleasant for others to be around me.",
          "key": "q12",
          "a": {
            "q12a1": {
              "value": `I can be angry about the death but not bring that anger into my relationships.`,
              "active": false
            },
            "q12a2": {
              "value": `I can find other outlets for my anger.`,
              "active": false
            },
            "q12a3": {
              "value": `I can do things to discharge my anger before I connect with people.`,
              "active": false
            },
            "q12a4": {
              "value": `It's okay to be angry about my loss, but I don't need to let that affect my relationships.`,
              "active": false
            },
            "q12a5": {
              "value": `It's possible that others aren't unhappy around me- No one has told me that it's unpleasant being around me.`,
              "active": false
            }
          }
        },
        {
          "q": "I have trouble feeling close to people since the death.",
          "key": "q13",
          "a": {
            "q13a1": {
              "value": `I can feel closer to people by sharing more about my experience and asking them more about theirs.`,
              "active": false
            },
            "q13a2": {
              "value": `I can think about what helps me feel close to people and try to do more of those things when I'm with the people I care about.`,
              "active": false
            },
            "q13a3": {
              "value": `It will get easier to feel close to others with time.`,
              "active": false
            },
            "q13a4": {
              "value": `For now, I'll seek out those I know I can trust and focus on those relationships.`,
              "active": false
            },
            "q13a5": {
              "value": `It makes sense that I feel this way, but I can take steps so that I feel more connected with others.`,
              "active": false
            }
          }
        },
        {
          "q": "None of the new people I meet is as good as the one who died.",
          "key": "q14",
          "a": {
            "q14a1": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} was extremely special, but I may find that others have special qualities I can appreciate and value.`,
              "active": false
            },
            "q14a2": {
              "value": `I can still have meaningful relationships even if they are different from my relationship with the one who died.`,
              "active": false
            },
            "q14a3": {
              "value": `No two people are the same. ${GH.capitalizeSentenceFirstLetter(deceased)} was a unique presence in my life that I am not looking to replace.`,
              "active": false
            },
            "q14a4": {
              "value": `No one can replace this person, but other people may have different positive qualities that I didn't expect.`,
              "active": false
            }
          }
        },
        {
          "q": "Other people don't understand what I'm going through.",
          "key": "q15",
          "a": {
            "q15a1": {
              "value": `I can help people understand by sharing more with them.`,
              "active": false
            },
            "q15a2": {
              "value": `I can find other people who have gone through similar experiences and spend more time with them. `,
              "active": false
            },
            "q15a3": {
              "value": `It is true that not everyone will understand my situation, but it may still be helpful to connect.`,
              "active": false
            },
            "q15a4": {
              "value": `Sometimes it is difficult for others to understand what I'm going through but that doesn’t mean my feelings aren't valid.`,
              "active": false
            },
            "q15a5": {
              "value": `Maybe others won't understand all that I'm going through, but they may understand parts.`,
              "active": false
            }
          }
        },
        {
          "q": "The pain of missing ${deceased} is all that I have left of the relationship and I'd rather be miserable than give that up.",
          "key": "q16",
          "a": {
            "q16a1": {
              "value": `I can remain connected to ${deceased} and still engage in life.`,
              "active": false
            },
            "q16a2": {
              "value": `I can have moments of pain while still creating a life.`,
              "active": false
            },
            "q16a3": {
              "value": `There are ways to stay connected to ${deceased} and not be miserable.`,
              "active": false
            },
            "q16a4": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} would not want me to be miserable.`,
              "active": false
            },
            "q16a5": {
              "value": `Even though it feels painful, I can keep going and stay conneted to my memories of ${deceased}.`,
              "active": false
            },
            "q16a6": {
              "value": `If I am suffering less, it does not mean I love ${deceased} less.`,
              "active": false
            },
            "q16a7": {
              "value": `I will find a way to honor and remember ${deceased}, so that I have other reminders of them besides the pain.`,
              "active": false
            }
          }
        },
        {
          "q": "I'm not ready to let him/her go.",
          "key": "q17",
          "a": {
            "q17a1": {
              "value": `I don't need to let ${deceased} go in order to live life.`,
              "active": false
            },
            "q17a2": {
              "value": `There are ways to stay connected with ${deceased} and still live my life.`,
              "active": false
            },
            "q17a3": {
              "value": `I don’t have to let him or her go because I can find new ways to connect to ${deceased}.`,
              "active": false
            }
          }
        },
        {
          "q": "I still need him/her.",
          "key": "q18",
          "a": {
            "q18a1": {
              "value": `I still have him/her inside of me.`,
              "active": false
            },
            "q18a2": {
              "value": `I can take what I learned from ${deceased} as I continue to engage in life.`,
              "active": false
            },
            "q18a3": {
              "value": `I got so much from ${deceased}, but I can handle things without him/her physically here.`,
              "active": false
            },
            "q18a4": {
              "value": `I can think about what ${deceased} would tell me when I need him/her.`,
              "active": false
            }
          }
        },
        {
          "q": "A part of me feels like s/he is not really gone.",
          "key": "q19",
          "a": {
            "q19a1": {
              "value": `It's difficult to believe, but I understand he/she is no longer physically with me. I can still stay connected to ${deceased} while accepting that he/she died.`,
              "active": false
            },
            "q19a2": {
              "value": `He or she is not totally gone because I hold the memories of him or her. `,
              "active": false
            },
            "q19a3": {
              "value": `Though it's painful to acknowledge signs that ${deceased} is physically gone, I can find different ways that they’re still with me.`,
              "active": false
            },
            "q19a4": {
              "value": `I can keep this person's legacy alive.`,
              "active": false
            }
          }
        },
        {
          "q": "I'll never have a relationship as meaningful again, so socializing with others is not worth the effort.",
          "key": "q20",
          "a": {
            "q20a1": {
              "value": `My relationship with ${deceased} was precious, but I may find that other relationships are valuable and helpful, too.`,
              "active": false
            },
            "q20a2": {
              "value": `Other relationships may not be as meaningful as my relationship with ${deceased}, but that does not mean they aren't valuable.`,
              "active": false
            },
            "q20a3": {
              "value": `It may feel more effortful right now, but with time, it will be worth it.`,
              "active": false
            },
            "q20a4": {
              "value": `I can still have meaningful relationships even if they are different from my relationship with ${deceased}.`,
              "active": false
            },
            "q20a5": {
              "value": `No two relationships are the same and spending time with others could show me the positives in different types of connections`,
              "active": false
            },
            "q20a6": {
              "value": `Even if the relationship is less meaningful, it can still be important`,
              "active": false
            }
          }
        },
        {
          "q": `No one will ever love, value, or understand me as much as ${deceased}.`,
          "key": "q21",
          "a": {
            "q21a1": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} loved me in a very special way, but I may find others appreciate me as well.`,
              "active": false
            },
            "q21a2": {
              "value": `Being valued and understood is important to me, and so having other relationships in which I'm valued and understood is something I should pursue.`,
              "active": false
            },
            "q21a3": {
              "value": `I can still experience love and be valued by others in my life even if it feels different from my other relationship.`,
              "active": false
            },
            "q21a4": {
              "value": `When I look at the connection ${deceased} and I had, it also shows me the love and bond I’m capable of creating.`,
              "active": false
            },
            "q21a5": {
              "value": `People can still love and understand me in their own way, and it is nice to have a little of this in my life.`,
              "active": false
            }
          }
        },
        {
          "q": "I'm too busy caring for my family and running the household by myself to engage in new activities or focus on my own needs.",
          "key": "q22",
          "a": {
            "q22a1": {
              "value": `If I don't take care of myself, I won't be able to keep caring for the people around me.`,
              "active": false
            },
            "q22a2": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} would want me to make time for myself.`,
              "active": false
            },
            "q22a3": {
              "value": `Focusing on my own needs will help me to be able to better care for my family and run my household.`,
              "active": false
            },
            "q22a4": {
              "value": `I'll work towards giving myself time to focus on my own needs, grieving alone is already exhausting and I'm allowed to take a break. `,
              "active": false
            },
            "q22a5": {
              "value": `The more I take care of myself, the better I will be able to take care of my family too.`,
              "active": false
            }
          }
        },
        {
          "q": "I worry that I will forget the person I lost, especially if I get too busy with other things.",
          "key": "q23",
          "a": {
            "q23a1": {
              "value": `There are ways to stay connected to ${deceased} so that I don't forget him/her.`,
              "active": false
            },
            "q23a2": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)}'s impact on me can be seen in so many things I do, even if I'm not consciously thinking about him/her sometimes.`,
              "active": false
            },
            "q23a3": {
              "value": `It may feel more effortful right now, but with time, it will be worth it.`,
              "active": false
            },
            "q23a4": {
              "value": `As I move forward in life I'm taking ${deceased} with me. How I feel their presence in my life will vary, but they will always be there.`,
              "active": false
            },
            "q23a5": {
              "value": `This relationship means so much to me, that I could never forget him/her.`,
              "active": false
            },
            "q23a6": {
              "value": `I will find a way to honor this person's legacy, so they will never be forgotten.`,
              "active": false
            }
          }
        },
        {
          "q": "I will find a way to honor this person's legacy, so they will never be forgotten.",
          "key": "q24",
          "a": {
            "q24a1": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} would want me to continue living my life.`,
              "active": false
            },
            "q24a2": {
              "value": `Having new relationships doesn’t mean I'm betraying ${deceased}. It's possible to form a new relationship and remain connected to someone I love`,
              "active": false
            },
            "q24a3": {
              "value": `My loved one never said they would feel betrayed by this; they said they wanted me to be happy.`,
              "active": false
            }
          }
        },
        {
          "q": "I worry about what others (e.g., friends and family of the person I lost) would think of me if I started to make new friends.",
          "key": "q25",
          "a": {
            "q25a1": {
              "value": `I can check in with the people I'm worried about and let them know I'm concerned about how they will feel.`,
              "active": false
            },
            "q25a2": {
              "value": `I have the courage to make new friends despite what others might think.`,
              "active": false
            },
            "q25a3": {
              "value": `It's important for me to have friends and  relationships in my life and that is worth the risk of judgement.`,
              "active": false
            },
            "q25a4": {
              "value": `People might actually think it's a good idea to make new friends -- I can talk to them about it, to find out what they would think.`,
              "active": false
            }
          }
        },
        {
          "q": "Pursuing new activities or relationships would be selfish.",
          "key": "q26",
          "a": {
            "q26a1": {
              "value": `Taking care of myself is not the same as being selfish.`,
              "active": false
            },
            "q26a2": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} would feel badly if he/she knew I stopped pursuing new things.`,
              "active": false
            },
            "q26a3": {
              "value": `Pursuing new activities or relationships is a way of honoring my loved one because I'm still engaging in a life worth living. `,
              "active": false
            },
            "q26a4": {
              "value": `${GH.capitalizeSentenceFirstLetter(deceased)} will be with me as I engage in these new things.`,
              "active": false
            },
            "q26a5": {
              "value": `It is okay for me to take care of myself.  It is not selfish to keep myself healthy.`,
              "active": false
            }
          }
        },
        {
          "q": "Trying to force myself to move beyond grief would be a sign of weakness.",
          "key": "q27",
          "a": {
            "q27a1": {
              "value": `Reengaging in life does not mean I'm moving beyond grief, and enduring pain is strength.`,
              "active": false
            },
            "q27a2": {
              "value": `I don’t need to move beyond my grief, I need to learn to coexist with the grief.`,
              "active": false
            },
            "q27a3": {
              "value": `My grief is not something that I can pick up or put down. At times it will feel harder or easier, this is natural and not a sign of weakness. `,
              "active": false
            },
            "q27a4": {
              "value": `My grief is a reflection of my relationship with ${deceased} and not a reflection of how strong or weak I am.`,
              "active": false
            }
          }
        },
        {
          "q": "I feel guilty about things I did or didn't do related to the loss.",
          "key": "q28",
          "a": {
            "q28a1": {
              "value": `Guilt is a common reaction to losing someone and not a reflection of actual wrong doing.`,
              "active": false
            },
            "q28a2": {
              "value": `If I think about what ${deceased} would say, I know he/she would tell me it was okay.`,
              "active": false
            },
            "q28a3": {
              "value": `It's normal to feel guilty about some things I did or didn't do related to the loss but that doesn't mean I'm a bad person.`,
              "active": false
            },
            "q28a4": {
              "value": `I'll identify what I feel guilty about, question the truthfulness of the messages I'm giving myself, and work towards making amends with myself and ${deceased}.`,
              "active": false
            },
            "q28a5": {
              "value": `My loved one would have forgiven me, and wouldn't want me to dwell on this. `,
              "active": false
            }
          }
        },
        {
          "q": "I'm uncomfortable around others who try to console me or who pity me.",
          "key": "q29",
          "a": {
            "q29a1": {
              "value": `I can let people know that I enjoy spending time with them and for now, I'm not ready to speak about ${deceased}.`,
              "active": false
            },
            "q29a2": {
              "value": `Maybe it's uncomfortable to hear about ${deceased} right now, but in the long run, it will help keep ${deceased} a part of our lives.`,
              "active": false
            },
            "q29a3": {
              "value": `Others who try to console me are doing the best they know how even if it comes across as pity.`,
              "active": false
            },
            "q29a4": {
              "value": `I can let others know that I'm okay with my emotions, even when they're difficult.`,
              "active": false
            },
            "q29a5": {
              "value": `It makes sense that I feel uncomfortable with this -- I can talk to others about what type of support I need from them. `,
              "active": false
            }
          }
        },
        {
          "q": "I'm uncomfortable around others because they want to talk about the person I lost.",
          "key": "q30",
          "a": {
            "q30a1": {
              "value": `I can let people know that I enjoy spending time with them and for now, I'm not ready to speak about ${deceased}.`,
              "active": false
            },
            "q30a2": {
              "value": `Maybe it's uncomfortable to hear about ${deceased} right now, but  in the long run, it will help keep ${deceased} a part of our lives.`,
              "active": false
            },
            "q30a3": {
              "value": `People wanting to speak about ${deceased} is a reflection of how much s/he is loved.`,
              "active": false
            },
            "q30a4": {
              "value": `I can share my preference with others, and be curious and try to understand theirs.`,
              "active": false
            },
            "q30a5": {
              "value": `I can tell people when I don't want to talk about him/her -- It is okay if I get upset when talking about him/her`,
              "active": false
            }
          }
        },
        {
          "q": "I'm uncomfortable around others because they don't want to talk about ${deceased}.",
          "key": "q31",
          "a": {
            "q31a1": {
              "value": `Maybe people don't realize I like to speak about ${deceased}, and I can let them know. `,
              "active": false
            },
            "q31a2": {
              "value": `If some people don't want to talk about ${deceased}, I can speak about other things with them and find other people who are comfortable speaking about ${deceased}.`,
              "active": false
            },
            "q31a3": {
              "value": `I can share my wish to speak more about ${deceased} so they understand how important this is to me.`,
              "active": false
            },
            "q31a4": {
              "value": `It makes me feel connected to my loved one to talk about him or her regardless of what others think.`,
              "active": false
            },
            "q31a5": {
              "value": `If they are not ready, I can talk with others about him/her.`,
              "active": false
            }
          }
        },
        {
          "q": "I think others look down on me because I'm grieving.",
          "key": "q32",
          "a": {
            "q32a1": {
              "value": `Just because someone who hasn't gone through a loss may not fully understand my experience doesn't mean he/she is looking down on me.`,
              "active": false
            },
            "q32a2": {
              "value": `Sometimes it feels like others look down on me because of my grief but it might be their way of expressing their own fears about loss.`,
              "active": false
            },
            "q32a3": {
              "value": `My grief is a reflection of my relationship with ${deceased} and not a reflection of how strong or weak I am.`,
              "active": false
            },
            "q32a4": {
              "value": `I wouldn't look down on others for grieving, and it's unlikely that they are judging me too.`,
              "active": false
            }
          }
        },
        {
          "q": "Since my loved one died, I feel angry at God.",
          "key": "q33",
          "a": {
            "q33a1": {
              "value": `My anger at God is a sign of how meaningful God has been to me.`,
              "active": false
            },
            "q33a2": {
              "value": `God will forgive me for being angry.`,
              "active": false
            },
            "q33a3": {
              "value": `Feeling angry is normal, but may not be serving me well. If my relationship with God is important to me, I can work towards repairing my relationship with God.`,
              "active": false
            },
            "q33a4": {
              "value": `It is normal for me to feel angry after losing someone I love.  My anger won't last forever.`,
              "active": false
            }
          }
        },
        {
          "q": "I feel guilty about moving on with my life.",
          "key": "q34",
          "a": {
            "q34a1": {
              "value": `Moving forward is not moving on. `,
              "active": false
            },
            "q34a2": {
              "value": `It is  hard to move on with my life but my loved one would have wanted me to keep living. `,
              "active": false
            },
            "q34a3": {
              "value": `As I move forward I'm taking ${deceased} with me. I'll create ways to memorialize and stay connected to ${deceased} as time goes on. `,
              "active": false
            },
            "q34a4": {
              "value": `Finding a new "normal" and peace does not mean that I'm forgetting my loved one.  `,
              "active": false
            }
          }
        }
      ]
}
  
class CopingCards extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = { 
            disable: false,
            filteredAnswers: {}
        }
    }

    async componentWillMount()
    {
        const self = this

        try {
          // first get all the coping cards stored in the MySQL database
          // const upsert = await DH.upsert(initProps.db.copingCards, {
          //     user_id: this.props.user.id
          // }, {
          //     user_id: this.props.user.id
          // }).then(r => r).catch(e => e)

          // if(upsert.success && upsert.data.length > 0)
          // {
          //     const json = await JSON.parse(upsert.data[0].json) || {}
          //     const updateCopingCards = await this.props.manageCopingCards('load', json)
          // }
          // then get the BCIAnswers stored in the database
          // let BCIData = await SH.getLastSurvey(initProps.survey.BCI, this.props.user.id)

          // check if BCIAnswers include all of the data
          // if(BCIData === undefined)
          // {
            // check if the reducer has answers
            if(this.props.BCIAnswers && Object.keys(this.props.BCIAnswers).length > 0)
            {
              const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.BCI, this.props.BCIAnswers)
              if(checkComplete)
              {
                // let toStore = await SH.cleanDataBeforeStore(initProps.survey.BCI, this.props.BCIAnswers)
                // store the survey in the database
                // const storeSurvey = await SH.storeSurveyAnswers(this.props.user.id, initProps.survey.BCI, toStore)
                // if(storeSurvey.success && storeSurvey.data.length > 0)
                // {
                  // console.log(initProps.survey.BCI)
                  // console.log(this.props.BCIAnswers)
                  const filteredAnswers = await SH.filterAnswers(initProps.survey.BCI, this.props.BCIAnswers, 3, '>=')
                  // console.log(filteredAnswers)
                  return this.setState({ filteredAnswers })
                // }
                
              }
            }
            // there was no data, disable the component
            return self.setState({
              disable:true
            })
          // }
          // const containNullValues = await SH.checkNullColumns(BCIData)
          // if(containNullValues)
          // {
          //   // answer is not complete, prompt user to return to the questionnaire
          //   return self.setState({
          //     disable: true
          //   })
          // }
          // else
          // {
          //   // score the data and load the questionnaire in the reducer
          //   const scoreBCI = await SH.BCIAnalysis(BCIData, false)
          //   BCIData.feedback = scoreBCI
          //   await this.props.loadQuestionnaireAnswer(initProps.survey.BCI, BCIData)
          //   const filteredAnswers = await SH.filterAnswers(initProps.survey.BCI, this.props.BCIAnswers, 3, '>=')

          //   return self.setState({ filteredAnswers })
          // }
        }
        catch(e)
        {
            // console.log(e)
        }
    }

    render() {
      // console.log(this.state.filteredAnswers)
      // console.log(this.props.copingCards)
      if(!this.props.copingCards) return null
      // check if the Bereavement Challenges Inventory is completed, if not, redirect users to complete it before coming
      if(this.state.disable) // the scale is not completed
      {
        return <Grid item xs={12} className="mt-5">
          <Paragraph className='fyw-font-dark fyw-font-bold text-center'>{this.props.lang.copingCards.title}</Paragraph>
          <Divider />
  
          <Paragraph className='fyw-font-dark text-center'>
          {this.props.lang.copingCards.subtitle}
          </Paragraph>

          <Paragraph className='fyw-font-grey text-center'>{this.props.lang.copingCards.completeBCITip}</Paragraph>

          <Button className='fyw-btn-light my-5 mx-auto' onClick={e => {
            this.props.history.push(paths.bci)
          }}>{this.props.lang.gotoBCI}</Button>

        </Grid>
      }

      return(
        <Grid item xs={12} className="mt-5">
          <Paragraph className='fyw-font-dark fyw-font-bold text-center'>{this.props.lang.copingCards.title}</Paragraph>
          <Divider />

          <Paragraph className='fyw-font-dark text-center'>
          {this.props.lang.copingCards.subtitle}
          </Paragraph>

          <CardsComponent {...this.props} filteredAnswers={this.state.filteredAnswers} />
          
          <CollectedCards inputObj={this.props.copingCards} />

          {Object.keys(this.props.copingCards).length >= Object.keys(this.state.filteredAnswers).length ?
            <Button className='fyw-btn-light my-5 mx-auto' onClick={e => {
              this.props.switchComponent(initProps.modules.connecting, initProps[initProps.modules.connecting].components.copingCards, initProps[initProps.modules.connecting].components.identifyCompanions, { completed: true })
            }}>{this.props.lang.next}</Button>
          :null}

        </Grid>
        )
      }
}

export default CopingCards