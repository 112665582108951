import { connect } from 'react-redux'
import ConnectingModule from '../ui/ConnectingModule'
import { withRouter } from 'react-router-dom'
import initLang from '../../lang/initLang'
import { manageAccountInfo } from '../../actions/index'
import { updateCurrentStep, manageCopingCards, manageSCContacts, loadActiveWheels } from '../../actions/modules'
import { addSystemFeedback, initConfirmMessage, storePayloadBeforeConfirm } from '../../actions/config'
import { addAnswer, clearAnswer, loadQuestionnaireAnswer } from '../../actions/survey'
// import withAuth from './withAuth'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['connectingModule'],
    user: state.userReducer.auth,
    userInfo: state.userReducer.accountInfo,
    session: state.userReducer.session,
    progress: state.modulesReducer.progress,
    copingCards: state.modulesReducer.copingCards,
    socialCompassSupportTypes: state.modulesReducer.socialCompassSupportTypes,
    systemFeedback: state.configReducer.systemFeedback,
    confirmMessage: state.configReducer.confirmMessage,
    toConfirmPayload: state.configReducer.toConfirmPayload,
    socialCompassContacts: state.modulesReducer.socialCompassContacts,
    SCFeedbackAnswers: state.surveyReducer.SCFeedbackAnswers,
    BCIAnswers: state.surveyReducer.BCIAnswers
})

const mapDispatchToProps = dispatch => ({
    updateCurrentStep: (progress, userId) => dispatch(updateCurrentStep(progress, userId)),
    manageCopingCards: (type, obj) => dispatch(manageCopingCards(type, obj)),
    addSystemFeedback: feedback => dispatch(addSystemFeedback(feedback)),
    initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
    storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload)),
    manageAccountInfo: (userId, params) => dispatch(manageAccountInfo(userId, params)),
    manageSCContacts: (type, obj) => dispatch(manageSCContacts(type, obj)),
    addAnswer: (qid, answerObj) => dispatch(addAnswer(qid, answerObj)),
    clearAnswer: qid => dispatch(clearAnswer(qid)),
    loadQuestionnaireAnswer: (qid, answerObj) => dispatch(loadQuestionnaireAnswer(qid, answerObj)),
    loadActiveWheels: (activeWheels = '') => dispatch(loadActiveWheels(activeWheels))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(ConnectingModule)
// export default withAuth(withRouter(Container))
export default withRouter(Container)