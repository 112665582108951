import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Resources from '../ui/Resources'
import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['resources'],
})

const mapDispatchToProps = dispatch => ({
})

const Cont = connect(mapStateToProps, null)(Resources)
export default withRouter(Cont)