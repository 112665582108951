const methods = {
    LOAD_SESSION: 'LOAD_SESSION',
    CLEAR_SESSION: 'CLEAR_SESSION',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    LOGIN_ERR: 'LOGIN_ERR',
    CLEAR_STORE: 'CLEAR_STORE',
    CLEAR_LOGIN_ERR: 'CLEAR_LOGIN_ERR',
    REGISTER_MSG: 'REGISTER_MSG',
    CLEAR_REGISTER_ERR: 'CLEAR_REGISTER_ERR',
    LOAD_ACCOUNT_INFO: 'LOAD_ACCOUNT_INFO',
    LOAD_LANGUAGE: 'LOAD_LANGUAGE',
    USER_LOGOUT: 'USER_LOGOUT',
    ADD_PG13_ANSWER: 'ADD_PG13_ANSWER',
    ADD_DEMO_ANSWER: 'ADD_DEMO_ANSWER',
    ADD_BCI_ANSWER: 'ADD_BCI_ANSWER',
    ADD_SCF_ANSWER: 'ADD_SCF_ANSWER',
    REMOVE_PG13_ANSWER: 'REMOVE_PG13_ANSWER',
    REMOVE_DEMO_ANSWER: 'REMOVE_DEMO_ANSWER',
    REMOVE_BCI_ANSWER: 'REMOVE_BCI_ANSWER',
    CLEAR_SCF_ANSWER: 'CLEAR_SCF_ANSWER',
    LOAD_PG13_ANSWER: 'LOAD_PG13_ANSWER',
    LOAD_DEMO_ANSWER: 'LOAD_DEMO_ANSWER',
    LOAD_BCI_ANSWER: 'LOAD_BCI_ANSWER',
    LOAD_SCF_ANSWER: 'LOAD_SCF_ANSWER',
    ADD_COPING_CARD: 'ADD_COPING_CARD',
    REMOVE_COPING_CARD: 'REMOVE_COPING_CARD',
    LOAD_COPING_CARD: 'LOAD_COPING_CARD',
    CLEAR_COPING_CARD: 'CLEAR_COPING_CARD',
    ADD_SYSTEM_FEEDBACK: 'ADD_SYSTEM_FEEDBACK',
    INIT_CONFIRM: 'INIT_CONFIRM',
    STORE_PAYLOAD_BEFORE_CONFIRM: 'STORE_PAYLOAD_BEFORE_CONFIRM',
    REMOVE_SC_CONTACTS: 'REMOVE_SC_CONTACTS',
    LOAD_SC_CONTACTS: 'LOAD_SC_CONTACTS',
    LOAD_ACTIVE_WHEELS: 'LOAD_ACTIVE_WHEELS',
    UPDATE_MODULE_PROGRESS: 'UPDATE_MODULE_PROGRESS',

    SWITCH_PAGE: 'SWITCH_PAGE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_HUGS: 'UPDATE_HUGS',
    UPDATE_MSGS: 'UPDATE_MSGS',
    UPDATE_MATCHES: 'UPDATE_MATCHES',
    UPDATE_CONNECT_USERS: 'UPDATE_CONNECT_USERS'
}

export default methods