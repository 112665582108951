import { connect } from 'react-redux'
import Auth from '../ui/Auth'
import { switchPage, updateProfile } from '../../actions/match'
import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['matchmaking']["auth"],
    page: state.matchReducer.page
})

const mapDispatchToProps = dispatch => ({
    switchPage: page => dispatch(switchPage(page)),
    updateProfile: profile => dispatch(updateProfile(profile))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(Auth)
// export default withAuth(withRouter(Container))
export default Container