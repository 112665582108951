import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Index from '../ui/Index'
import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['index']
})


const Cont = connect(mapStateToProps, null)(Index)
export default withRouter(Cont)