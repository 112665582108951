import React, { Component } from 'react'
import { VelocityComponent } from 'velocity-react'
import SH from '../../helpers/SurveyHelper'
import { SVGFigure } from '../../assets/Figure'
import { Paragraph } from '../../assets/Text'

/**
 * usage:
    <GriefMap BCIScore={this.state.BCIScore} />
 */
// after user has completed the questionnaire

const categories = {
    connecting: 'Connecting with Others',
    uncertainty: 'Living with Change and Uncertainty',
    hopeful: 'Creating a Hopeful Future',
    guilt: 'Resolving Guilt',
    accepting: 'Accepting the Loss'
}

class GriefMap extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            active: {
                a_group: false,
                b_group: false,
                c_group: false,
                d_group: false,
                e_group: false,
            },
            feedback: ''
        }
        this.handleBubbleHover = this.handleBubbleHover.bind(this)
        this.handleBubbleClick = this.handleBubbleClick.bind(this)
    }
    componentDidMount()
    {
        // console.log(this.props)
        let newActive = Object.assign({}, this.state.active)
        Object.keys(this.state.active).map(key => {
            newActive[key] = true
        })
        this.setState({
            active: newActive
        })
    }

    handleBubbleHover(e, t, s, active = false)
    {
        // console.log(e)
        // console.log(t)
        // console.log(s)
        let activeStatus = active
        // console.log(e)
        let newActiveGroup = Object.assign({}, this.state.active), feedback = ''
        newActiveGroup[e] = activeStatus
        switch(t)
        {
            case this.props.dimensions.connecting:
                feedback = this.props.feedbacks.connecting.replace('[score]', s)
            break
            case this.props.dimensions.change:
                feedback = this.props.feedbacks.change.replace('[score]', s)
            break
            case this.props.dimensions.imagining:
                feedback = this.props.feedbacks.imagining.replace('[score]', s)
            break
            case this.props.dimensions.accepting:
                feedback = this.props.feedbacks.accepting.replace('[score]', s)
            break
            case this.props.dimensions.guilt:
                feedback = this.props.feedbacks.guilt.replace('[score]', s)
            break
        }
        // console.log(newActiveGroup)
        this.setState({
            active: newActiveGroup,
            feedback
        })
    }

    handleBubbleClick(e)
    {
        switch(e)
        {
            case this.props.dimensions.connecting:
                return this.props.redirectConnect()
                // return window.location.replace('/')
        }
        let activeStatus = !this.state.active[e]
        // console.log(e)
        let newActiveGroup = Object.assign({}, this.state.active)
        newActiveGroup[e] = activeStatus
        // console.log(newActiveGroup)
        this.setState({
            active: newActiveGroup
        })
    }

    render()
    {
        // console.log(this.props)
        let a = 0, b = 0, c = 0, d = 0, e = 0
        let a_title = this.props.dimensions.connecting, b_title = this.props.dimensions.change, c_title = this.props.dimensions.imagining,
            d_title = this.props.dimensions.accepting, e_title = this.props.dimensions.guilt
        
        // compare the score
        let sorted = SH.sortScore(this.props.BCIScore)
        // console.log(sorted)
        sorted.forEach(item => {
            switch(item[0])
            {
                case 'connecting':
                    item[0] = a_title
                    break

                case 'change':
                        item[0] = b_title
                    break

                case 'imagining':
                        item[0] = c_title
                    break

                case 'guilt':
                        item[0] = e_title
                    break
                
                case 'accepting':
                        item[0] = d_title
                    break
            }
        })

        return (
        <div className="mx-auto w-100 d-flex justify-content-center flex-column">
            <div className='w-100'>
            <Paragraph className='fyw-font-grey'>{this.state.feedback || ''}</Paragraph>
            </div>
            <div className='w-100'>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1024" height="677" viewBox="0 0 1024 677">
  
            <SVGFigure src="map-background-md.jpeg" coord={{x:0, y:0}} width="1024" height="677" />

            <Bubble 
                id="a_group"
                active={this.state.active["a_group"]}
                handleBubbleHover={this.handleBubbleHover}
                handleClick={this.handleBubbleClick}
                transform={[423, 47]}
                title={sorted[0][0] || a_title}
                score={sorted[0][1] || a}
            />

            <Bubble 
                id="b_group"
                active={this.state.active["b_group"]}
                handleBubbleHover={this.handleBubbleHover}
                handleClick={this.handleBubbleClick}
                transform={[615, 149]}
                title={sorted[1][0] || b_title}
                score={sorted[1][1] || b}
            />

            <Bubble 
                id="c_group"
                active={this.state.active["c_group"]}
                handleBubbleHover={this.handleBubbleHover}
                handleClick={this.handleBubbleClick}
                transform={[88, 221]}
                title={sorted[2][0] || c_title}
                score={sorted[2][1] || c}
            />

            <Bubble 
                id="d_group"
                active={this.state.active["d_group"]}
                handleBubbleHover={this.handleBubbleHover}
                handleClick={this.handleBubbleClick}
                transform={[642, 293]}
                title={sorted[3][0] || d_title}
                score={sorted[3][1] || d}
            />

            <Bubble 
                id="e_group"
                active={this.state.active["e_group"]}
                handleBubbleHover={this.handleBubbleHover}
                handleClick={this.handleBubbleClick}
                transform={[174, 381]}
                title={sorted[4][0] || e_title}
                score={sorted[4][1] || e}
            />

            </svg>
            </div>
        </div>
        )
    }
}

/**
 * usage:
    <Bubble 
        id="a_group"
        active={true || false}
        handleClick={function}
        transform="translate(423 47)"
        title={sorted[0][0] || a_title}
        score={sorted[0][1] || a}
    />
 */
const Bubble = (props) => {
    return (
        <VelocityComponent animation={ props.active ? {
            translateX: props.transform[0],
            translateY: props.transform[1],
        } : {
            translateY: props.transform[1]+10
        }} duration={500} easing="easeInSine">
            <g className="fyw-BCI-bubble cursor-pointer" id={props.id} onClick={() => {
                props.handleClick(props.title)
            }}
            onMouseEnter={() => props.handleBubbleHover(props.id, props.title, props.score, true)}
            onMouseLeave={() => props.handleBubbleHover(props.id, props.title, props.score, false)}
            >
                <path id={`${props.id}_p1`} fill="#FEFEFE" d="M238.489,-1.42108547e-14 L10,-1.42108547e-14 C4.5,-1.42108547e-14 1.42108547e-14,4.5 1.42108547e-14,10 L1.42108547e-14,46.997 C1.42108547e-14,52.497 4.5,56.997 10,56.997 L114.856,56.997 L118.453,63.228 L122.512,70.259 C123.282,71.592 125.207,71.592 125.976,70.259 L130.036,63.228 L133.633,56.997 L238.489,56.997 C243.989,56.997 248.489,52.497 248.489,46.997 L248.489,10 C248.489,4.5 243.989,-1.42108547e-14 238.489,-1.42108547e-14" mask="url(#grief-map-svg-f)"/>
                <path id={`${props.id}_p2`} fill="#F9BB6D" d="M248.489,30 L248.489,46.997 C248.489,52.497 243.989,56.997 238.489,56.997 L238.489,56.997 L133.633,56.997 L130.036,63.228 L125.976,70.259 C125.207,71.592 123.282,71.592 122.512,70.259 L122.512,70.259 L118.453,63.228 L114.856,56.997 L10,56.997 C4.5,56.997 0,52.497 0,46.997 L0,46.997 L0,30 L248.489,30 Z" mask="url(#grief-map-svg-h)"/>
                <text fill="#475C7A" fontFamily="Asap-SemiBold, Asap" fontSize="12" fontWeight="500">
                <tspan x="10" dy="20">{props.title.toUpperCase()}</tspan>
                </text>
                <text fill="#475C7A" fontFamily="Asap-SemiBold, Asap" fontSize="12" fontWeight="500" transform="translate(100)">
                <tspan id={`${props.id}_score`} x="5" dy="47">Score: {' '}{props.score}</tspan>
                </text>
            </g>
        </VelocityComponent>
    )
}


export default GriefMap