import DH from './DatabaseHelper'
import GH from './GeneralHelper'
import SH from './SurveyHelper'
import initProps from '../config/init'

const tb = initProps.db.profile

class ProfileHelper {
    constructor()
    {

    }

    async upsertProfile(userId, param = {})
    {

        if(!userId) return null

        let toUpdateParam = {...param}
        
        try
        {
            if(Object.keys(param).length <= 0)
            {
                toUpdateParam = { user_id: userId }
            }
            // console.log(toUpdateParam)
            const result = await DH.upsert(tb, toUpdateParam, { user_id: userId }).then(r => r).catch(e => {
                throw e
            })
            // console.log(result)
            if(result.success && result.data.length > 0)
            {
                return result.data[0]
            }

            throw false
            // return result
        }
        catch(e)
        {
            console.log(e)
            return e
        }
    }

    /**
     * fetch the contacts by type or use all to fetch all the contacts of a user
     * @param {int} userId 
     * @param {str} type 
     * usage:
        const allContacts = await PH.getAllContacts(this.props.user.id, 'validator-panel')
     */
    async getAllContacts(userId, type = 'all')
    {
        if(!userId) return false

        // const contactWayTB = initProps.db.socialCompassContactWay
        const contactDB = initProps.db.socialCompassContactPerson
        let contacts = {}
        let whereObj = {
            user_id: userId
        }
        if(type !== 'all')
        {
            whereObj['type'] = type
        }
        try
        {
            const upsert = await DH.upsert(contactDB, {}, whereObj)
            .then(r => r)
            .catch(e => { throw e })
            // console.log(upsert)
            if(upsert.success && upsert.data.length > 0)
            {
                let data = upsert.data
                // // const dataK = Object.keys(dataK)
                const loop = await GH.asyncForEach(data, async (d, i) => {
                    let target = {...d}
                    const type = target['type']
                    const contactKey = target['key']

                    contacts[type] = contacts.hasOwnProperty(type) ? {...contacts[type]} : {}
                    
                    // console.log(`query contact key ${contactKey} type ${type}`)
                    // get phone and email of the contact
                    const allContactWay = await this.getContactWay(userId, contactKey, 'all')
                    const allSurveys = await SH.getSurveyGivenContactKey(initProps.db.SCFeedback, userId, contactKey)
                    // console.log(allSurveys)
                    // let target
                    let phones = [], emails = []
                    let contactWayLoop = await GH.asyncForEach(allContactWay, async(c) => {
                        switch(c.type)
                        {
                            case 'phone':
                                phones.push(c)
                                break
                            case 'email':
                                emails.push(c)
                                break
                        }
                        return
                    })
                    target.phone = phones
                    target.email = emails
                    target.surveys = allSurveys || []
                    // console.log(contactWayLoop)
                    // console.log(target)
                    return contacts[type][contactKey] = target
                })
                // console.log(contacts)
                return contacts
            }
            throw upsert
        }
        catch(e)
        {
            // console.log(e)
            //TODO: add error log
            return e
        }
    }
    /**
     * insert/update a contact of a user
     * usage:
        let insert = await PH.manageContact(this.props.user.id, 'existing-contact-key', 'companion-panel', 'Your name', 'email@gmail.com', '000-0000', 'False reason')
     */
    async manageContact(userId, key = '', type = '', name = '', email = '', phone = '', reason = '', image = '')
    {
        // console.log('manage contact called')
        if(!userId) return false
        const queryKey = key !== '' ? key : GH.makeid(10)
        const contactPersonParam = {
            user_id: userId,
            key: queryKey,
            type: type,
            name: name,
            reason: reason
        }

        try
        {
            const upsert = await DH.upsert(initProps.db.socialCompassContactPerson, contactPersonParam, { user_id: userId, key: queryKey })
            .then(r => r)
            .catch(e => { throw e })
            // console.log(upsert)
            if(upsert.success && upsert.data.length > 0)
            {
                let data = upsert.data[0]
                // insert email and phone
                if(email && email !== '')
                {
                    let accEmail = await this.manageContactWay(userId, data['key'], 'email', email)
                    data['email'] = accEmail
                    // console.log(accEmail)
                }
                if(phone && phone !== '')
                {
                    let accPhone = await this.manageContactWay(userId, data['key'], 'phone', phone)
                    data['phone'] = accPhone
                    // console.log(accPhone)
                }
                return data
            }
            throw upsert
        }
        catch(e)
        {
            return e
        }

    }
    /**
     * remove a contact of a user
     * @param {int} userId
     * @param {str} contactKey
     * usage:
        let remove = await PH.removeContact(this.props.user.id, 'contact-key')
     */
    async removeContact(userId, contactKey = '')
    {
        if(!userId || !contactKey || contactKey === '') return false
        const whereObj = {
            user_id: userId,
            key: contactKey
        }
        const personTB = initProps.db.socialCompassContactPerson
        const removePerson = await DH.remove(personTB, whereObj)
        // console.log(removePerson)
        if(removePerson.success)
        {
            // continue remove the contact way
            const removeAll = await this.removeAllContactWay(userId, contactKey, 'all')
            // console.log(removeAll)
            return removeAll
        }
        return false
        // console.log(removePerson)
        // return removePerson
    }

    async removeSingleContactWay(userId, contactWayId)
    {
        if(!userId || !contactWayId) return false

        const contactWayTB = initProps.db.socialCompassContactWay
        const whereObj = {
            id: contactWayId,
            user_id: userId
        }
        const remove = await DH.remove(contactWayTB, whereObj)
        if(remove.success) return true
        //TODO: add err message
        return false
    }

    async removeAllContactWay(userId, contactKey, type = 'all')
    {
        if(!userId || !contactKey) return false
        const contactWayTB = initProps.db.socialCompassContactWay
        if(type === 'all')
        {
            const whereObj = {
                user_id: userId,
                contact_person_key: contactKey,
            }
            const removeAll = await DH.remove(contactWayTB, whereObj)
            // console.log(removeAll)
            if(removeAll.success)
            {
                return true
            }
            //TODO: add err message
            return false
        }
        const whereObj = {
            user_id: userId,
            contact_person_key: contactKey,
            type: type
        }
        const removeAll = await DH.remove(contactWayTB, whereObj)
        if(removeAll.success)
        {
            return true
        }
        //TODO: add err message
        return false
    }

    async getContactWay(userId, contactKey, type = 'all')
    {
        if(!userId || !contactKey || contactKey === '') return false
        try
        {
            if(type === 'all')
            {
                const allData = await DH.upsert(initProps.db.socialCompassContactWay, {}, { contact_person_key: contactKey })
                .then(r => r)
                .catch(e => { throw e })
                // console.log(allData)
                // return upsert.data[0]
                if(allData.success)
                {
                    return allData.data
                }
                throw allData
            }
            const allData = await DH.upsert(initProps.db.socialCompassContactWay, {}, { type: type, contact_person_key: contactKey })
            .then(r => r)
            .catch(e => { throw e })
            // return upsert.data[0]
            if(allData.success)
            {
                return allData.data
            }
            throw allData
        }
        catch(e)
        {
            //TODO: add error message
            return false
        }
    }

    async manageContactWay(userId, contactKey = '', type = '', value = '')
    {
        if(!userId || !contactKey || contactKey === '') return false
        try
        {
            const contactWay = {
                user_id: userId,
                contact_person_key: contactKey,
                type: type || 'email',
                value: value || ''
            }
            if(value !== '')
            {
                const upsert = await DH.upsert(initProps.db.socialCompassContactWay, contactWay, { contact_person_key: contactKey, value: value })
                .then(r => r)
                .catch(e => { throw e })
    
                // if(upsert.success && upsert.data.length > 0)
                // {
                //     throw upsert
                // }
            }
            // if user does not indicate value for email or phone, then return the query result
            const allData = await DH.upsert(initProps.db.socialCompassContactWay, {}, { type: type, contact_person_key: contactKey })
            .then(r => r)
            .catch(e => { throw e })
            // return upsert.data[0]
            if(allData.success && allData.data.length > 0)
            {
                return allData.data
            }
            throw allData
            // throw upsert
        }
        catch(e)
        {
            return e
        }
    }
}

export default new ProfileHelper()