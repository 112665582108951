import AuthRequestHelper from "./AuthRequestHelper"

class UserProfileHelper{
 
    async getProfileById(id){
        try{
            const requestUrl="user/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    async updateProfileById(id, userObj){
        try{
            const requestUrl="user/"+id
            let newObj = {}

            if(userObj.hasOwnProperty('displayName')) newObj['display_name'] = userObj.displayName
            if(userObj.hasOwnProperty('relationship')) newObj['relationship'] = userObj.relationship
            if(userObj.hasOwnProperty('gender')) newObj['gender'] = userObj.gender
            if(userObj.hasOwnProperty('state')) newObj['state_address'] = userObj.state
            if(userObj.hasOwnProperty('lossExperience')) newObj['loss_description'] = userObj.lossExperience

            return await AuthRequestHelper.request("patch",requestUrl, newObj)
        }
        catch(error){
            console.log(error)
            return {}
        }
    }
    

    async getAuthProfileById(id){
        try{
            const requestUrl="userauthinfo/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})     
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    async getRoleById(id){
        try{
            const requestUrl="userrole/"+id
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            return {}
        }       
    }

    async getAllProfiles(){
        try{
            const requestUrl="user"
            return await AuthRequestHelper.request("get",requestUrl,{})            
        }
        catch(error){
            console.log(error)
            return {}
        }
    }


    async registerOneMatchProfile(uuid,displayName,age,relationship,gender,state,lossExperience){
        try{
            const requestUrl="registeruserprofile"
            const registerParams={
                "uuid":uuid,
                "display_name": displayName,
                "age": age,
                "relationship": relationship,
                "gender": gender,
                "state_address": state,
                "loss_description": lossExperience
            }
            return await AuthRequestHelper.requestNoToken("put",requestUrl, registerParams)
               
        }
        catch(error){
            console.log(error)
            return ""
        }
    }


    async registerOneAuthProfile(username,password){
        try{
            const requestUrl="registerauthprofile"
            const registerParams={
                "username": username,
                "password": password,
            }           
            return await AuthRequestHelper.requestNoToken("put",requestUrl,  registerParams)
        }
        catch(error){
            console.log(error)
            return ""
        }

    }

    async registerOneChatProfile(id,username,displayName){
        try{
            const requestUrl="registerchatprofile"
            const registerParams={
                "id": id,
                "username": username,
                "display_name":displayName
            }           
            return await AuthRequestHelper.requestNoToken("put",requestUrl,  registerParams)
        }
        catch(error){
            console.log(error)
            return ""
        }

    }

    /* PARAMETER FORMAT FOR REGISTERATION IS AS FOLLOWS
    {
        "username": <username>,
        "password": <password>, 
        "displayName": <display_name>,
        "age": <age>,
        "relationship": <aunt,uncle etc>,
        "gender":<male,female>,
        "state":<state such as Nevada>,
        "lossExperience": <loss description string>
    }
    */
    async registerUser(params){
        try{
            //first try registering auth profile
            let authRegisterResponse = await this.registerOneAuthProfile(
                params.username,
                params.password
            )
            let uuid=authRegisterResponse.uuid

            //if you faild to create a profile, then do not proceed
            if (!authRegisterResponse){
                throw Error("Failed to create auth profile")
            }
            else{
                console.log("Created auth profile with uuid: "+uuid)
            }

            //then register a match profile with the created uuid
            let matchRegisterResponse = await this.registerOneMatchProfile(
                uuid,
                params.displayName,
                params.age,
                params.relationship,
                params.gender,
                params.state,
                params.lossExperience)
            console.log("Created match profile with uuid: "+matchRegisterResponse.addedData.id)

            //then register a chat profile
            let chatRegisterResponse= await this.registerOneChatProfile(
                uuid,
                params.username,
                params.displayName
            )

            console.log("Created match profile with uuid: "+chatRegisterResponse.uuid)


            return matchRegisterResponse.addedData.id
        }
        catch(error){
            console.log(error)
            return false
        }
    }

    async sendresetPasswordEmail(username){
        try{
            const requestUrl="sendresetpasswordEmail"
            const resetParams={
                "username": username
            }           
            return await AuthRequestHelper.requestNoToken("put", requestUrl,  resetParams)
        }
        catch(error){
            console.log(error)
            return false
        }
    }

    async resetPassword(username,password){
        try{
            const requestUrl="resetpassword"
            const resetParams={
                "username": username,
                "password" : password
            }           
            return await AuthRequestHelper.request("patch", requestUrl,  resetParams)
        }
        catch(error){
            console.log(error)
            return false
        }
    }


}

export default new UserProfileHelper();