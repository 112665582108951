import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput } from '../../../assets/Form'
import { Paragraph, Text } from '../../../assets/Text'
import { Button } from '../../../assets/Buttons'
import { Alert } from '../../../assets/UIElements'
import UserProfileHelper from '../../../helpers/UserProfileHelper'
import AuthValidateHelper from '../../../helpers/AuthValidateHelper'

const ForgotPass = props => {
    const [email, updateEmail] = useState('')
    const [submitError, updateSubmitError] = useState('')
    const [emailSent, updateEmailSent] = useState(false)

    if(!emailSent)
    {
        return (<Grid item xs={12} sm={10} md={8} className='mx-auto'>
            <Grid className='mx-auto d-flex justify-content-center'>
                <Paragraph className='fyw-font-dark fyw-font-bold my-5'>Request a temporary password</Paragraph>
            </Grid>
            <Grid item xs={12} sm={8} md={8} className='mx-auto'>
            <form>
                <TextInput 
                    id='match-username'
                    // ref={pass => this._password = pass}
                    type={"text"}
                    label='Email Address'
                    ariaLabel='email address'
                    icon='envelope'
                    withAdornment={false}
                    placeholder='Your Email Address'
                    onChange={e => updateEmail(e.target.value)}
                />
                <Button className='mx-auto mt-4' onClick={async () => {
                    console.log(email)
                    const validEmail = await AuthValidateHelper.validateEmail(email)
                    if(validEmail)
                    {
                        const emailresetpasswordresult= await UserProfileHelper.sendresetPasswordEmail(email)
                        if(emailresetpasswordresult.hasOwnProperty('status') && emailresetpasswordresult.status === 'success')
                        {
                            updateEmailSent(true)
                            return 
                        }
                        // console.log(emailresetpasswordresult)
                        updateSubmitError('The system has encountered problem to send you the temporary email. Please try again later or contact our system manager.')
                        return
                    }
                    console.log(validEmail)
                    return updateSubmitError('Please double check your email format')


                    // const result = await props.login(email, password)
                    // console.log(result)
                    // if(!result) updateSubmitError('Oops, the system failed to process your login request. Please try again later.')
                    // console.log(result)
                    // console.log(`entered email: ${email} and entered pass: ${password}`)
                }}>Submit</Button>
                {submitError !== '' ? <Grid className='w-100 d-flex justify-content-center'>
                    <Alert><Text className='fyw-font-dark mx-auto'>{submitError}</Text></Alert>
                </Grid>
                :null}
            </form>
            </Grid>
            <Grid className='mx-auto d-flex justify-content-center flex-column mt-4'>
                <Text className='fyw-font-dark mx-auto'>
                <span className='href-link ml-2' tabIndex="0" aria-label='back to login' role='link' onClick={() => {
                    props.switchToLogin(true)
                }}>Back to Login page</span>
                </Text>
            </Grid>
        </Grid>)
    }
    return <Grid container className='mt-3 mx-auto'>
    <Grid item xs={12} sm={10} md={8} className='mx-auto'>
    <Grid className='mx-auto text-center'>
        <Paragraph className='fyw-font-dark fyw-font-bold my-5'>{`An email with your temporary password has been sent to your email address: ${email}. Please use the temporary password to login and change your password.`}</Paragraph>
        <Button className='fyw-btn-dark mx-auto' onClick={() => {
        props.switchToLogin(true)
        }}>Back to login</Button>
    </Grid>
    </Grid>
    </Grid>
}

export default ForgotPass