import methods from '../methods'
import FetchWrapper from '../assets/FetchWrapper'
import initProps from '../config/init'

const Fetch = new FetchWrapper()

export const addAnswer = (questionnaire, answerObj) => dispatch => {
    switch(questionnaire) {
        case initProps.survey.PG13:
            return dispatch({
                type: methods.ADD_PG13_ANSWER,
                payload: answerObj
            })
        case initProps.survey.Demo:
            return dispatch({
                type: methods.ADD_DEMO_ANSWER,
                payload: answerObj
            })
        case initProps.survey.BCI:
            return dispatch({
                type: methods.ADD_BCI_ANSWER,
                payload: answerObj
            })
        case initProps.survey.SCFeedback:
            return dispatch({
                type: methods.ADD_SCF_ANSWER,
                payload: answerObj
            })
        default:
            return null
    }
}

export const clearAnswer = (questionnaire) => dispatch => {
    const defaultAnswerObj = {}
    switch(questionnaire) {
        case initProps.survey.PG13:
            return dispatch({
                type: methods.REMOVE_PG13_ANSWER,
                payload: defaultAnswerObj
            })
        case initProps.survey.Demo:
            return dispatch({
                type: methods.REMOVE_DEMO_ANSWER,
                payload: defaultAnswerObj
            })
        case initProps.survey.BCI:
            return dispatch({
                type: methods.REMOVE_BCI_ANSWER,
                payload: defaultAnswerObj
            })
        case initProps.survey.SCFeedback:
            return dispatch({
                type: methods.CLEAR_SCF_ANSWER,
                payload: defaultAnswerObj
            })
        default:
            return null
    }
}

export const loadQuestionnaireAnswer = (questionnaire, answerObj) => dispatch => {
    // console.log(questionnaire)
    // console.log(answerObj)
    switch(questionnaire) {
        case initProps.survey.PG13:
            return dispatch({
                type: methods.LOAD_PG13_ANSWER,
                payload: answerObj || {}
            })
        case initProps.survey.Demo:
            return dispatch({
                type: methods.LOAD_DEMO_ANSWER,
                payload: answerObj || {}
            })
        case initProps.survey.BCI:
            return dispatch({
                type: methods.LOAD_BCI_ANSWER,
                payload: answerObj || {}
            })
        case initProps.survey.SCFeedback:
            return dispatch({
                type: methods.LOAD_SCF_ANSWER,
                payload: answerObj || {}
            })
        default: return null
    }
}