import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Subtitle, Paragraph } from './Text'
/**
 * usage:
    import { Divider } from '../../assets/UIElements'
    <Divider className='fyw-divider-white' />
 */
const Divider = props => {
    return (
        <div className={props.className ? `fyw-divider w-100 divider ${props.className}` : `fyw-divider w-100 divider`}></div>
    )
}

/**
 * usage:
    import { TitleSection } from '../../assets/UIElements'
    <TitleSecion title='your-title' subtitle='your-subtitle' />
 */
const TitleSecion = props => {
    return <div className='my-5 w-100'>
        <div className="row text-center m-auto">
            <Subtitle className='mx-auto mt-5'>{props.title.toUpperCase() || ''}</Subtitle>
        </div>
        <Divider />
        <Paragraph className="fyw-font-dark mx-auto text-center" style={{width: '80%'}}>
            {props.subtitle || ''}
        </Paragraph>
    </div>
}

const Alert = props => {
    return <Grid item xs={12} sm={8} className={`mx-auto fyw-alert mb-2 ${props.className}`}>
        {props.children}
    </Grid>
}

export { Divider, TitleSecion, Alert }