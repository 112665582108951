import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import STM from '../../helpers/TimeManage'
import SH from '../../helpers/SurveyHelper'
import initProps from '../../config/init'
import Questionnaire from '../../assets/Survey'
import { SystemFeedback, ConfirmBox } from '../../assets/Feedback'
import NavSection from '../containers/NavBar'
import { Paragraph, Subtitle } from '../../assets/Text'
import { Divider, TitleSecion } from '../../assets/UIElements'
import { Button } from '../../assets/Buttons'
import { ImageSlider } from '../../assets/ImageSlider'

const displayQuestionnaire = 0

const tabsArr = [{
    idx: 1,
    label: 'Grief Intensity Scale',
    value: 1
}]

const PG13Arr = SH.getQuestionnaire('PG13')

// deal with questionnaire input and submission
class PG13 extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            survey: tabsArr[0]['idx'],
            submissionFeedback: '',
            inComplete: []
        }
        this.storeDataIfNotExist = this.storeDataIfNotExist.bind(this)
        this.storeQuestionnaire = this.storeQuestionnaire.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.handleDateInput = this.handleDateInput.bind(this)
        this.handleRestart = this.handleRestart.bind(this)
    }

    async componentDidMount()
    {
        // check if user has logged in and store the data in the MySQL database
        // if user has not, then display the data from reducer
        // if user has logged in, then display the data from MySQL database
        if(this.props.auth.hasOwnProperty('accessToken') && this.props.auth.accessToken !== '' && this.props.auth.accessToken !== undefined)
        {
            try
            {
                let getPG9Data = await SH.getLastSurvey(initProps.survey.PG13, this.props.auth.id)

                if(getPG9Data === undefined)
                {
                    // check if the reducer has answers
                    if(this.props.PG13Answers && Object.keys(this.props.PG13Answers).length > 0)
                    {
                      const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.PG13, this.props.PG13Answers)
                      if(checkComplete)
                      {
                        let toStore = await SH.cleanDataBeforeStore(initProps.survey.PG13, this.props.PG13Answers)
                        // store the survey in the database
                        // console.log(this.props.auth)
                        const storeSurvey = await SH.storeSurveyAnswers(this.props.auth.id, initProps.survey.PG13, toStore)

                      }
                    }
                }
                // const scorePG9 = await SH.PG9Analysis(getPG9Data, false)
                // getPG9Data.feedback = scorePG9
                // this.props.loadQuestionnaireAnswer(initProps.survey.PG13, getPG9Data)
            }
            catch(e)
            {
                // console.log(e)
            }
        }
        // const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.BCI, this.props.BCIAnswers)
        // if(checkComplete)
        // {
        //     // store survey

        // }
        // const completedSurvey = Object.keys(this.props.BCIAnswers).length
        // console.log(completedSurvey)
    }

    async storeDataIfNotExist()
    {
        // check if user has logged in and store the data in the MySQL database
        // if user has not, then display the data from reducer
        // if user has logged in, then display the data from MySQL database
        if(this.props.auth.hasOwnProperty('accessToken') && this.props.auth.accessToken !== '' && this.props.auth.accessToken !== undefined)
        {
            try
            {
                let getPG9Data = await SH.getLastSurvey(initProps.survey.PG13, this.props.auth.id)

                if(getPG9Data === undefined)
                {
                    // console.log('no prev data')
                    // check if the reducer has answers
                    if(this.props.PG13Answers && Object.keys(this.props.PG13Answers).length > 0)
                    {
                        // console.log('reducer has answers')
                      const checkComplete = await SH.checkIfSurveyCompleted(initProps.survey.PG13, this.props.PG13Answers)
                      if(checkComplete)
                      {
                        let toStore = await SH.cleanDataBeforeStore(initProps.survey.PG13, this.props.PG13Answers)
                        // store the survey in the database
                        // console.log(this.props.auth)
                        const storeSurvey = await SH.storeSurveyAnswers(this.props.auth.id, initProps.survey.PG13, toStore)
                      }
                    }
                }
                // const scorePG9 = await SH.PG9Analysis(getPG9Data, false)
                // getPG9Data.feedback = scorePG9
                // this.props.loadQuestionnaireAnswer(initProps.survey.PG13, getPG9Data)
                return
            }
            catch(e)
            {
                // console.log(e)
                return
            }
        }
        return
    }

    async storeQuestionnaire(e, qid)
    {
        // console.log('store questionnaire result')
        const self = this
        e.preventDefault()
        const current = STM.getCurrentUTCTimestamp()
        const answerKey = qid + "Answers"
        const answers = this.props[answerKey]
        const inComplete = await SH.checkIfSurveyCompleted(qid, answers, true)
        if(inComplete.length > 0)
        {
            // console.log(inComplete)
            this.props.initConfirmMessage({
                title: this.props.lang.incompleteTitle,
                value: this.props.lang.incompleteNotification,
                onConfirm: () => {
                    self.props.initConfirmMessage({})
                },
                onCancel: () => {
                    self.props.initConfirmMessage({})
                }
            })
            return this.setState({
                inComplete
            })
        }
        const analysisResult = await this.scoreQuestionnaire(qid, answers)
        // console.log(analysisResult)
        const text = analysisResult.meet ? analysisResult.meetDuration ? 
        this.props.lang.feedbackConfirmed.replace('[score]', analysisResult.total)
        :this.props.lang.feedbackConfirmedEarly.replace('[score]', analysisResult.total)
        :this.props.lang.noPDG.replace('[score]', analysisResult.total)
        var newObj = {
            id: 'feedback',
            answer: analysisResult,
            created_at: current,
            answer_text: text
        }
        this.props.addAnswer(qid, newObj)
        await this.storeDataIfNotExist()
        // const storeResult = await SH.storeSurveyAnswers(this.props.user.id, qid, answers)
        // console.log(newObj.answer_text)
        this.setState({
            submissionFeedback: newObj.answer_text || ''
        })

        this.props.initConfirmMessage({
            title: `You have completed ${initProps.questionnaireName[qid]}`,
            value: newObj.answer_text || 'Click confirm to proceed to next questionnaire.',
            onConfirm: () => {
                self.props.initConfirmMessage({})
            },
            onCancel: () => {
                self.props.initConfirmMessage({})
            }
        })
    }

    async scoreQuestionnaire(qid, answers)
    {
        switch(qid)
        {
            case 'PG13':
                const PG13Result = await SH.PG9Analysis(answers)
                // console.log(PG13Result)
                return PG13Result

            case 'BCI':
                const BCIResult = await SH.BCIAnalysis(answers, true)
                return BCIResult
            default: return true
        }
    }

    handleInput(e, questionnaire = '', qid = '')
    {
        const value = e.target.value
        const current = STM.getCurrentUTCTimestamp()
        const survey = SH.getQuestionnaire(questionnaire)
        const answerDisplay = SH.getAnswerGivenValue(survey, qid, value)
        // console.log()
        switch(e.target.type) {
            case 'text':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'radio':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'select-one':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            default:
        }
    }

    handleDateInput(questionnaire, qid = '', value)
    {
        const current = STM.getCurrentUTCTimestamp()
        const date = STM.formatUTCTime(value)
        const newObj = {
            id: qid,
            answer: date,
            created_at: current,
            answer_text: date
        }
        return this.props.addAnswer(questionnaire, newObj)
    }

    handleRestart(qid = initProps.survey.PG13)
    {
        const self = this
        this.props.initConfirmMessage({
            title: `Restart the survey?`,
            value: `You want to restart the survey ${initProps.questionnaireName[qid]}. All of your data will be deleted, do you still want to restart?`,
            onConfirm: () => {
                self.props.initConfirmMessage({})
                self.props.clearAnswer(qid)
            },
            onCancel: () => {
                self.props.initConfirmMessage({})
            }
        })
    }

    render()
    {
        // console.log(this.props.PG13Answers)
        let renderTab = [], targetSurvey = []
        tabsArr.forEach(tab => {
            renderTab.push(<Tab key={tab.idx} label={tab.label} value={tab.value} />)
        })
        if(this.props.PG13Answers && !this.props.PG13Answers.hasOwnProperty('feedback'))
        {
            return (<NavSection>
                <Questionnaire 
                    key={`target-survey-${this.state.survey}`} 
                    title={PG13Arr['title']} 
                    description={PG13Arr['description']} 
                    items={PG13Arr['questions']} 
                    questionnaire={initProps.survey.PG13} 
                    answers={this.props.PG13Answers} 
                    handleInput={this.handleInput} 
                    handleDateInput={this.handleDateInput} 
                    handleStore={this.storeQuestionnaire} 
                    feedback={this.state.submissionFeedback}
                    inComplete={this.state.inComplete}
                />
                <ConfirmBox 
                    confirmTitle={this.props.confirmMessage.title || ''}
                    confirmMsg={this.props.confirmMessage.value || ''}
                    close={() => this.props.addSystemFeedback('')} 
                    confirm={() => this.props.confirmMessage.onConfirm()}
                    cancel={() => this.props.confirmMessage.onCancel()} 
                />
                <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
            </NavSection>)
        }

        return <NavSection>
                <div className='my-5 w-100'>
                    <TitleSecion title={this.props.lang.title.toUpperCase() || ''} subtitle={this.props.lang.subtitle || ''} />
                    {/*<div className="row text-center m-auto">
                        <Subtitle className='mx-auto mt-5'>{this.props.lang.title.toUpperCase() || ''}</Subtitle>
                    </div>
                    <Divider />
                    <Paragraph className="fyw-font-dark mx-auto text-center" style={{width: '80%'}}>
                        {this.props.lang.subtitle || ''}
    </Paragraph>*/}

                    <Grid item xs={12} sm={8} className='mx-auto my-4'>
                        <Paragraph className="fyw-font-dark mx-auto text-left fyw-font-bold">
                        {this.props.lang.feedback || ''}
                        </Paragraph>
                        <Paragraph className="fyw-font-dark mx-auto text-left">
                        {this.props.lang.thankYou || ''}
                        </Paragraph>
                        <Paragraph className="fyw-font-dark mx-auto text-left">
                        {this.props.PG13Answers.feedback.answer_text || ''}
                        </Paragraph>
                        <Grid className='my-4'>
                            <Paragraph className='fyw-font-dark fyw-font-bold'>{this.props.lang.understandPGD}</Paragraph>
                            <ImageSlider source={initProps.imgSlider} />
                        </Grid>
                        <Button className='m-auto' onClick={e => this.handleRestart()}>Restart</Button>
                    </Grid>
                </div>
            <ConfirmBox 
                confirmTitle={this.props.confirmMessage.title || ''}
                confirmMsg={this.props.confirmMessage.value || ''}
                close={() => this.props.addSystemFeedback('')} 
                confirm={() => this.props.confirmMessage.onConfirm()}
                cancel={() => this.props.confirmMessage.onCancel()} 
            />
            <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
        </NavSection>
    }
}

export default PG13