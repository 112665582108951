import config from '../config/config'
import FetchWrapper from '../assets/FetchWrapper'

const Fetch = new FetchWrapper(config.localAuth.apiServiceUrl)

class AuthenticationHelper {
    /*on successful login, return token */
    async login(username,password){
        try{
            const response= await Fetch.postNoCors('login',{
                username: username,
                password: password
            }, (err,result)=>{
                if(err) throw err
                return result
            })
            this.setToken(response.token);
            return true
        }
        catch (error){
            console.log("Authentication Error: "+error)
            return false
        }
    }

    get token() {
        let token = sessionStorage.getItem('xtoken');

        if (token === undefined || token === null || token.length === 0) {
            return null;
        }

        return token;
    }

    get uuid(){
        if (this.token === null) {
            return null;
        }
        return this.claims.sub
    }

    setToken(token) {
        sessionStorage.setItem('xtoken', token);
        return true;
    }

    /*when loggin out */
    resetToken() {
        sessionStorage.removeItem('xtoken');
    }

    /* check if the user is logged in, return true if user is logged in */
    hasAuthenticated(){
        return this.token?true:false
    }

    decodeToken(token) {
        if (token === null) {
            return null;
        }
        return JSON.parse(atob(token.split(/\./)[1]))
    }

    get claims() {
        return this.decodeToken(this.token);
    }

}

export default new AuthenticationHelper();