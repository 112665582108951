import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Contact from '../ui/Contact'
import initLang from '../../lang/initLang'
import { addSystemFeedback, initConfirmMessage, storePayloadBeforeConfirm } from '../../actions/config'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['contact'],
    confirmMessage: state.configReducer.confirmMessage,
    systemFeedback: state.configReducer.systemFeedback,
    toConfirmPayload: state.configReducer.toConfirmPayload,
})

const mapDispatchToProps = dispatch => ({
    addSystemFeedback: feedback => dispatch(addSystemFeedback(feedback)),
    initConfirmMessage: msgObj => dispatch(initConfirmMessage(msgObj)),
    storePayloadBeforeConfirm: payload => dispatch(storePayloadBeforeConfirm(payload))
})

const Cont = connect(mapStateToProps, mapDispatchToProps)(Contact)
export default withRouter(Cont)