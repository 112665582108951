import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import NavSection from '../containers/NavBar'
import { Paragraph } from '../../assets/Text'
import { TitleSecion } from '../../assets/UIElements'
import { TextInput } from '../../assets/Form'
import { Button } from '../../assets/Buttons'
import { SystemFeedback, ConfirmBox } from '../../assets/Feedback'
import ReCAPTCHA from "react-google-recaptcha"
import config from '../../config/config'
import init from '../../config/init'
import DH from '../../helpers/DatabaseHelper'
import GH from '../../helpers/GeneralHelper'
// import FetchWrapper from '../../assets/FetchWrapper'


window.recaptchaOptions = {
    useRecaptchaNet: true,
  };
// const Fetch = new FetchWrapper()

class Contact extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            captcha: null,
            feedback: ''
        }
        this.submitSubscriber = this.submitSubscriber.bind(this)
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this)
        // this.onTextChange = this.onTextChange.bind(this)
    }

    async submitSubscriber(e)
    {
        e.preventDefault()
        // console.log(this._name.value)
        // console.log(this._email.value)
        const name = this._name.value
        const email = this._email.value
        const isEmail = GH.validateEmail(email)
        // console.log(isEmail)
        if(!isEmail)
        {
            return this.props.initConfirmMessage({
                title: this.props.lang.validEmail,
                value: this.props.lang.validEmailContent,
                onConfirm: () => {
                    this.props.initConfirmMessage({})
                    // this.props.clearAnswer(qid)
                },
                onCancel: () => {
                    this.props.initConfirmMessage({})
                }
            })
        }
        if(name !== '' && email !== '')
        {
            try
            {
                const save = await DH.upsert(init.db.subscriber, {
                    name: name,
                    email: email
                }, {}).then(r => r).catch(e => { throw e })
                if(save.success)
                {
                    this.setState({
                        feedback: this.props.lang.thankYou
                    })
                    return this.props.addSystemFeedback(this.props.lang.added)
                }
                else
                {
                    this.props.addSystemFeedback(this.props.lang.failed)
                }
            }
            catch(e)
            {
                this.props.addSystemFeedback(this.props.lang.failed)
                // console.log(e)
            }
        }
        else
        {
            this.props.initConfirmMessage({
                title: this.props.lang.incompleteTitle,
                value: this.props.lang.incompleteSubtitle,
                onConfirm: () => {
                    this.props.initConfirmMessage({})
                    // this.props.clearAnswer(qid)
                },
                onCancel: () => {
                    this.props.initConfirmMessage({})
                }
            })
        }
        // const recaptchaValue = this._captcha.current.getValue();

        // console.log(this._captcha.current.execute())
        // this.props.onSubmit(recaptchaValue);
    }

    handleCaptchaChange(value)
    {
        // console.log(value)
        this.setState({
            captcha: value
        })
    }

    render()
    {
        if(this.state.feedback !== '')
        {
            return             <NavSection >
            
            <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />

            {/*<div className="row text-center m-auto">
                <Subtitle className='mx-auto mt-5'>{this.props.lang.title.toUpperCase() || ''}</Subtitle>
            </div>
            <Divider />
            <Paragraph className="fyw-font-dark mx-auto text-center" style={{width: '80%'}}>
                {this.props.lang.subtitle}
            </Paragraph>*/}

            <Grid item xs={11} sm={6} md={4} className='mx-auto'>

                <Paragraph className='fyw-font-dark'>
                    {this.state.feedback}
                </Paragraph>
            </Grid>
            <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
            </NavSection>
        }
        return (
            <NavSection >
            
            <TitleSecion title={this.props.lang.title.toUpperCase()} subtitle={this.props.lang.subtitle} />

            {/*<div className="row text-center m-auto">
                <Subtitle className='mx-auto mt-5'>{this.props.lang.title.toUpperCase() || ''}</Subtitle>
            </div>
            <Divider />
            <Paragraph className="fyw-font-dark mx-auto text-center" style={{width: '80%'}}>
                {this.props.lang.subtitle}
            </Paragraph>*/}

            <Grid item xs={11} sm={6} md={4} className='mx-auto'>

                <Paragraph className='fyw-font-dark'>
                    {this.props.lang.enterEmail}
                </Paragraph>
                <TextInput 
                    ref={name => this._name = name}
                    type={"text"}
                    label='Name'
                    ariaLabel='name'
                    icon='user'
                    // onChange={this.onTextChange}
                    placeholder='Your name'
                />

                <TextInput 
                    ref={email => this._email = email}
                    type={"email"}
                    label='Email Address'
                    ariaLabel='email address'
                    icon='envelope'
                    // onChange={this.onTextChange}
                    placeholder='Your email address'
                />

                <ReCAPTCHA
                    ref={captcha => this._captcha = captcha}
                    sitekey={config.google.captchaKey}
                    onChange={this.handleCaptchaChange}
                />

                <Button className='fyw-primary-btn mt-4' onClick={this.submitSubscriber}>{this.props.lang.submit}</Button>

            </Grid>
            <ConfirmBox 
                confirmTitle={this.props.confirmMessage.title || ''}
                confirmMsg={this.props.confirmMessage.value || ''}
                close={() => this.props.addSystemFeedback('')} 
                confirm={() => this.props.confirmMessage.onConfirm()}
                cancel={() => this.props.confirmMessage.onCancel()} 
            />
            <SystemFeedback feedback={this.props.systemFeedback || ''} close={() => this.props.addSystemFeedback('')} />
            </NavSection>
        )
    }
}

export default Contact