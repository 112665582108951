import methods from '../methods'
import FetchWrapper from '../assets/FetchWrapper'
import PH from '../helpers/ProfileHelper'
import AH from '../helpers/AmplifyHelper'
import GH from '../helpers/GeneralHelper'
import initProps from '../config/init'

const Fetch = new FetchWrapper()
const tb = initProps.db

export const signup = inputAuth => async dispatch => {
  if(!inputAuth.email || !inputAuth.password)
  {
    const message = "The email or password is missing. Please double check the required fields."
    dispatch({
      type: methods.REGISTER_USER,
      payload: {}
    })
    return dispatch({
      type: methods.REGISTER_MSG,
      payload: message
    })
  }

  try
  {
    const auth = GH.trimText(inputAuth)
    let signUpResult = await AH.signUp(auth)
    if(signUpResult.hasOwnProperty("code")) // an error happened
    {
      throw signUpResult
    }
    // start the validating process
    let newObj = {
      email: auth.email,
      isLoggedIn: false
    }
    const fieldData = {
      nickname: auth.nickname || 'traveler',
      email: auth.email,
      password: auth.password,
      username: signUpResult.userSub
    }
    return Fetch.post('/users/v1/registerUserIfNotExist', {
      fieldData: fieldData
    }, (err, result) => {
      if(err)
      {
        const msg = "The system failed to process your registration. Please try again later. If you have already registered, please try to log in."
        dispatch({
          type: methods.REGISTER_MSG,
          payload: err.message || msg
        })
        throw {
          message: "Internal server is encountering an error to receive registration requests."
        }
      }
      else if(!result.success)
      {
        const msg = "The system failed to process your registration. Please try again later. If you have already registered, please try to log in."
        dispatch({
          type: methods.REGISTER_MSG,
          payload: result.message || msg
        })
        throw {
          message: result.message || msg
        }
      }
      dispatch({
        type: methods.REGISTER_MSG,
        payload: 'You have successfully registered as a new user at Finding Your Way. The system has sent you an email to confirm your address. Please go to your mailbox to activate your membership.'
      })
      return dispatch({
        type: methods.LOGIN,
        payload: newObj
      })
    })

    // const msg = "Congratulation. You have successfully joined Finding Your Way. The system has sent a verification code to your email address. Please confirm before you proceed."
    // dispatch({
    //   type: methods.REGISTER_MSG,
    //   payload: msg
    // })
    // return dispatch({
    //   type: methods.LOGIN,
    //   payload: newObj
    // })
  }
  catch(err)
  {
    const msg = "The system failed to process your registration due to the following error. "+err.message
    return dispatch({
      type: methods.REGISTER_MSG,
      payload: msg
    })
  }
  // AH.signUp(auth)
  // .then(r => {
  //   console.log(r)
  //   // start the validating process
  //   let newObj = {
  //     email: auth.email,
  //     isLoggedIn: false
  //   }
  //   return dispatch({
  //     type: methods.LOGIN,
  //     payload: newObj
  //   })
  // })
  // .catch(err => {

  // })
  // return Fetch.post('/users/v1/registerUserIfNotExist', {
  //   fieldData: fieldData
  // }, (err, result) => {
  //   if(err || !result.success)
  //   {
  //     const msg = "The system failed to process your registration. Please try again later. If you have already registered, please try to log in."
  //     return dispatch({
  //       type: methods.REGISTER_MSG,
  //       payload: result.message || msg
  //     })
  //   }
  //   return dispatch({
  //     type: methods.REGISTER_MSG,
  //     payload: 'You have successfully registered as a new user at Finding Your Way. The system has sent you an email to confirm your address. Please go to your mailbox to activate your membership.'
  //   })
  // })
}

export const addReigsterMsg = msg => ({
  type: methods.REGISTER_MSG,
  payload: msg
})

export const authUser = (userInfo, session) => async dispatch => {
  // get the login recorded
  // console.log(userInfo)
  // console.log(session)
  const r = await Fetch.post('/users/v1/getUserProfile', {
    fieldData: {
      username: userInfo.attributes.sub,
      email: userInfo.attributes.email
    }
  }, (e, r) => {
    if(e) return false
    return r.data[0]
  })
  // console.log(r)
  try {
    const addAuth = await Fetch.get(`/users/v1/recordAuthActivity/${r.id}/1`, [], (e, r) => {
      if(e) throw e
      return r.data[0]
    })
    // console.log(addAuth)
  }
  catch(err)
  {
    // console.log('an error happened')
    // console.log(err)
  }
  const newObj = {
    id: r.id,
    email: userInfo.attributes.email,
    username: userInfo.username,
    accessToken: session.accessToken.jwtToken,
    isLoggedIn: true
  }
  // const sessionObj = r.signInUserSession
  const newAuth = Object.assign({}, newObj)
    dispatch({
      type: methods.CLEAR_LOGIN_ERR,
      payload: {}
    })
    // dispatch({
    //   type: methods.LOAD_SESSION,
    //   payload: sessionObj
    // })
    return dispatch({
        type: methods.LOGIN,
        payload: newAuth
    })

  // const auth = GH.trimText(inputAuth)
  // try
  // {
  //   const r = await AH.signIn(auth)
  //   if(r !== 'string') // Cognito auth successful
  //   {

  //     return Fetch.post('/users/v1/getUserProfile', {
  //       fieldData: {
  //         username: r.attributes.sub,
  //         email: r.attributes.email
  //       }
  //     }, async (err, result) => {
  //       if(err)
  //       {
  //         if(err.code === "UserNotConfirmedException")
  //         {
  //           // redirect to the confirmation page
  //           return dispatch({
  //             type: methods.LOGIN_ERR,
  //             payload: {
  //               code: err.code,
  //               message: err.message || 'The system failed to log you in currently. Please try again later.'
  //             }
  //           })
  //         }
  //         else if(err.code === "UserNotFoundException")
  //         {
  //           // redirect to the confirmation page
  //           const msg = "The system fail to identify the credential you provided. Maybe you want to register as a member first."
  //           return dispatch({
  //             type: methods.LOGIN_ERR,
  //             payload: {
  //               code: err.code,
  //               message: msg || 'The system failed to log you in currently. Please try again later.'
  //             }
  //           })
  //         }
  //         return dispatch({
  //           type: methods.LOGIN_ERR,
  //           payload: {
  //             code: err.code,
  //             message: err.message || 'The system failed to log you in currently. Please try again later.'
  //           }
  //         })
  //       }
  //       if(!result.success)
  //       {
  //         if(result.code === "UserNotConfirmedException")
  //         {
  //           // redirect to the confirmation page
  //           return dispatch({
  //             type: methods.LOGIN_ERR,
  //             payload: {
  //               code: result.code,
  //               message: result.message || 'The system failed to log you in currently. Please try again later.'
  //             }
  //           })
  //         }
  //         else if(result.code === "UserNotFoundException")
  //         {
  //           // redirect to the confirmation page
  //           const msg = "The system fail to identify the credential you provided. Maybe you want to register as a member first."
  //           return dispatch({
  //             type: methods.LOGIN_ERR,
  //             payload: {
  //               code: result.code,
  //               message: msg || 'The system failed to log you in currently. Please try again later.'
  //             }
  //           })
  //         }
  //         else if(result.code === "USER_NOT_FOUND")
  //         {
  //           // redirect to the confirmation page
  //           const msg = "The system fail to identify the credential you provided. Maybe you want to register as a member first."
  //           return dispatch({
  //             type: methods.LOGIN_ERR,
  //             payload: {
  //               code: result.code,
  //               message: msg || 'The system failed to log you in currently. Please try again later.'
  //             }
  //           })
  //         }
  //         return dispatch({
  //           type: methods.LOGIN_ERR,
  //           payload: {
  //             code: result.code,
  //             message: result.message || 'The system failed to log you in currently. Please try again later.'
  //           }
  //         })
  //       }
  //       // get the login recorded
  //       try {
  //         const addAuth = await Fetch.get(`/users/v1/recordAuthActivity/${result.data[0].id}/1`)
  //       }
  //       catch(err)
  //       {

  //       }
  //       const newObj = {
  //         id: result.data[0].id,
  //         email: r.attributes.email,
  //         username: r.username,
  //         accessToken: r.signInUserSession.accessToken.jwtToken,
  //         isLoggedIn: true
  //       }
  //       const sessionObj = r.signInUserSession
  //       const newAuth = Object.assign({}, newObj)
  //         dispatch({
  //           type: methods.CLEAR_LOGIN_ERR,
  //           payload: {}
  //         })
  //         dispatch({
  //           type: methods.LOAD_SESSION,
  //           payload: sessionObj
  //         })
  //         return dispatch({
  //             type: methods.LOGIN,
  //             payload: newAuth
  //         })
  //     })
  //   }
  //   return dispatch({
  //     type: methods.LOGIN_ERR,
  //     payload: {
  //       code: "LoginFailed",
  //       message: 'The system failed to log you in currently. Please try again later.'
  //     }
  //   })
  // }
  // catch(err)
  // {
  //   if(err.code === "UserNotConfirmedException")
  //   {
  //     // redirect to the page
  //     dispatch({
  //       type: methods.LOGIN,
  //       payload: {
  //         email: auth.username,
  //         isLoggedIn: false
  //       }
  //     })
  //     return dispatch({
  //       type: methods.LOGIN_ERR,
  //       payload: {
  //         code: err.code,
  //         message: 'Oops. It looks like you have not confirmed your email address using the verification code.'
  //       }
  //     })
  //   }
  //   return dispatch({
  //     type: methods.LOGIN_ERR,
  //     payload: {
  //       code: err.code,
  //       message: err.message || 'The system failed to log you in currently. Please try again later.'
  //     }
  //   })
  // }

  // })
  // return Fetch.post('/users/v1/login', {
  //     email: auth.username,
  //     password: auth.password
  //   }, (err, result) => {
  //     if(err || !result.success)
  //     {
  //       // add error
  //       return dispatch({
  //         type: methods.LOGIN_ERR,
  //         payload: err || result.message
  //       })
  //     }
  //     const newObj = {
  //     //   username: auth.username || '',
  //       accessToken: result.data,
  //       isLoggedIn: true
  //     }
  //     const newAuth = Object.assign({}, newObj)
  //     dispatch({
  //       type: methods.CLEAR_LOGIN_ERR,
  //       payload: ''
  //     })
  //     return dispatch({
  //         type: methods.LOGIN,
  //         payload: newAuth
  //     })
  // })
}

export const loadSession = session => dispatch => {
  return AH.getSession()
  .then(session => {
    if(typeof session !== 'string')
    {
      const newObj = {
        username: session.accessToken.payload.email || '',
        accessToken: session.accessToken.jwtToken,
        isLoggedIn: true
      }
      dispatch({
        type: methods.LOGIN,
        payload: newObj
      })
      return dispatch({
        type: methods.LOAD_SESSION,
        payload: session
      })
    }
  })
  .catch(err => {

  })
}

export const clearAuth = auth => dispatch => {

  return AH.signOut(false)
  .then(async () => {

    // get the login recorded
      return Fetch.get(`/users/v1/recordAuthActivity/${auth.id}/0`, [], (err, result) => {
        if(err)
        {

          dispatch({
            type: methods.LOGIN_ERR,
            payload: {
              code: "LogoutFailed",
              message: 'The system failed to log you out currently. Please try again later.'
            }
          })
          
          return false
        }

        dispatch({
          type: methods.CLEAR_STORE,
          payload: undefined
        })
        dispatch({
          type: methods.LOGOUT,
          auth
        })
        return true
      })
  })
  .catch(err => {
    dispatch({
      type: methods.LOGIN_ERR,
      payload: {
        code: "LogoutFailed",
        message: 'The system failed to log you out currently. Please try again later.'
      }
    })
    return false
  })

}

export const clearLoginErr = err => ({
  type: methods.CLEAR_LOGIN_ERR,
  payload: {}
})

export const clearRegisterFeedback = feedback => ({
  type: methods.CLEAR_REGISTER_ERR,
  payload: ''
})

// load the account information including nickname, deceased's name and progress
export const manageAccountInfo = (userId, param = {}) => async dispatch => {
    // console.log(userId)
    if(!userId) return null
    try
    {
      // console.log(param)
      const profile = await PH.upsertProfile(userId, param)
      .then(r => r).catch(e => false)
      // console.log(profile)
  
      if(profile)
      {
        return dispatch({
          type: methods.LOAD_ACCOUNT_INFO,
          payload: profile
        })
      }
  
      throw profile
    }
    catch(e)
    {
      // console.log(e)
      return dispatch({
        type: methods.LOAD_ACCOUNT_INFO,
        payload: null
      })
    }
  }