class AuthValidateHelper {

    validateEmail(email = '')
    {
        if(email === '') return false
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        // const email = this._email.value
        if(re.test(String(email).toLowerCase()))
        {
            return true
        }
        return false
    }

    confirmPassword(password = '', confirmPassword = '')
    {
        if(password === '' || confirmPassword === '') return false
        if(password !== confirmPassword)
        {
            return false
        }
        return true
    }
    
    validatePassword(myInput = '')
    {
        // const myInput = this._password.value
        let lowercase = false, uppercase = false, number = false, special = false, length = false
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g
        if(myInput.match(lowerCaseLetters)) {
            // this._lowercase.classList.remove("invalid")
            // this._lowercase.classList.add("valid")
            lowercase = true            
        } else {
            // this._lowercase.classList.remove("valid")
            // this._lowercase.classList.add("invalid")
            // this._validsection.classList.add('show')
            lowercase = false            
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(myInput.match(upperCaseLetters)) {
            // this._uppercase.classList.remove("invalid")
            // this._uppercase.classList.add("valid")
            uppercase = true            
        } else {
            // this._uppercase.classList.remove("valid")
            // this._uppercase.classList.add("invalid")
            // this._validsection.classList.add('show')
            uppercase = false            
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if(myInput.match(numbers)) {
            // this._number.classList.remove("invalid")
            // this._number.classList.add("valid")
            number = true
        } else {
            // this._number.classList.remove("valid")
            // this._number.classList.add("invalid")
            // this._validsection.classList.add('show')
            number = false
        }

        var specialChar = /(?=.[!@#\$%\^&*])/;
        if(myInput.match(specialChar)) {
            // this._special.classList.remove("invalid")
            // this._special.classList.add("valid")
            special = true
        } else {
            // this._special.classList.remove("valid")
            // this._special.classList.add("invalid")
            // this._validsection.classList.add('show')
            special = false
        }

        // Validate length
        if(myInput.length >= 8) {
            // this._eight.classList.remove("invalid")
            // this._eight.classList.add("valid")
            length = true
        } else {
            // this._eight.classList.remove("valid")
            // this._eight.classList.add("invalid")
            // this._validsection.classList.add('show')
            length = false
        }


        if(lowercase && uppercase && number && length && special)
        {
            return true
            // this._validsection.classList.remove('show')
            // this.setState({
            //     passValidated: "true"
            // })
        }
        return { lowercase, uppercase, number, length, special }
    }
}

export default new AuthValidateHelper()