import config from "../config/config"

class ChatSocketHelper{

    static ChatSocketObj=null

    logout()
    {
        if(ChatSocketHelper.ChatSocketObj !== null)
        {
            ChatSocketHelper.ChatSocketObj.close()
        }
        return ChatSocketHelper.ChatSocketObj = null
    }

    connectToSocket(webSocketUrl,chatToken,passwordDict){
        return new Promise( (resolve, reject) => {
            const server = new WebSocket(webSocketUrl);
            server.onopen = () => {

                //try sending the connect message
                this.sendConnectionMessage(server)

                //try logging in with username and token after establishing connection
                if(chatToken)
                    this.loginWithToken(server,chatToken)
                else if(passwordDict.username && passwordDict.password)
                    this.loginWithPassword(server,passwordDict.username, passwordDict.password)

                resolve(server);
            };
            server.onerror = (err) => {
                console.log('chat socket connection error:'+ err)
                reject(err);
            };
    
        });
    }

    async connectToChatWithPassword(passwordDict,handleUpdateMessage){

        if (ChatSocketHelper.ChatSocketObj)
        {
            console.log("chat socket object already exists")
            //subscribe to the event handlers also
            ChatSocketHelper.ChatSocketObj.onmessage = (event) => this.messageHandler(event,handleUpdateMessage)
            ChatSocketHelper.ChatSocketObj.onclose = () => this.onCloseHandler()

            return true
        }

        //create a new socket connection if it does not exist
        try
        {
            ChatSocketHelper.ChatSocketObj = await this.connectToSocket(config.chatServer.url,null,passwordDict)
        }
        catch(error)
        {
            return error
        }

        //log all the event handlers
        ChatSocketHelper.ChatSocketObj.onmessage = (event) => this.messageHandler(event,handleUpdateMessage)
        ChatSocketHelper.ChatSocketObj.onclose = () => this.onCloseHandler()

        //connected successfully, return true
        return true

    }

    async connectToChatWithToken(chatToken,handleUpdateMessage){

        if (ChatSocketHelper.ChatSocketObj)
        {
            console.log("chat socket object already exists")
            //subscribe to the event handlers also
            ChatSocketHelper.ChatSocketObj.onmessage = (event) => this.messageHandler(event,handleUpdateMessage)
            ChatSocketHelper.ChatSocketObj.onclose = () => this.onCloseHandler()

            return true
        }

        //create a new socket connection if it does not exist
        try
        {
            ChatSocketHelper.ChatSocketObj = await this.connectToSocket(config.chatServer.url,chatToken,null)
        }
        catch(error)
        {
            return error
        }

        //log all the event handlers
        ChatSocketHelper.ChatSocketObj.onmessage = (event) => this.messageHandler(event,handleUpdateMessage)
        ChatSocketHelper.ChatSocketObj.onclose = () => this.onCloseHandler()

        //connected successfully, return true
        return true

    }


    /************************************* */
    /*   CHAT HANDLERS                     */
    /***************************************/

    messageHandler(event,handleUpdateMessage){
        // on receiving a message, add it to the list of messages
        let response = JSON.parse(event.data);
        console.log('response', response);

        //if the system sends a ping, respond back
        if (response.msg == 'ping') {
            console.log('pong!');
            ChatSocketHelper.ChatSocketObj.send(JSON.stringify({msg: 'pong'}))
        }

        if (response.msg== 'result' && response.id=='history-request')
        {
            if(response.error)
            {
                console.log(response.error.error)
            }
            else
            {
                handleUpdateMessage(response.result.messages)
            }
        }

        // if the other user has sent the message
        if (response.msg === 'changed' && response.collection === 'stream-room-messages') {
            if(response.error)
            {
                console.log(response.error.error)
            }
            else
            {
                handleUpdateMessage(response.fields.args)
            }
        }
    }

    sortMessageByDates(msgArr = [])
    {
        let newMsgArr = msgArr.sort(function(a,b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // console.log(new Date(b.ts.$date) - new Date(a.ts.$date))
            return new Date(a.ts.$date) - new Date(b.ts.$date)
        })
        return newMsgArr
    }


    onCloseHandler(){
        console.log('disconnected by server')
    }

    /************************************* */
    /*   VARIOUS CONNECT METHODS           */
    /***************************************/

    //send connection message
    sendConnectionMessage(socket){
        const connectRequest = {
            "msg": "connect",
            "version": "1",
            "support": ["1"]
        }
        console.log("Send connect request")
        socket.send(JSON.stringify(connectRequest));
    }

    //login using token
    loginWithToken(server,chatToken){
        const loginRequest = {
            "msg": "method",
            "method": "login",
            "id": "try-login",
            "params": [
                { "resume": chatToken}
              ]
        }
        
        server.send(JSON.stringify(loginRequest));
    }
  
    loginWithPassword(server,username,password){
        const loginRequest = {
            "msg": "method",
            "method": "login",
            "id": "try-login",
            "params": [
                {
                "user":{"username":username},
                "password":password
                }
              ]
        }
        console.log("Logging in with password")
        server.send(JSON.stringify(loginRequest));        
    }

    //subscribe to room and stream the messages
    subscribeToOneRoomMessages(roomId){
        const subscribeRequest = {
            "msg": "sub",
            "id": "sub-room-"+roomId,
            "name": "stream-room-messages",
            "params":[
                roomId,
                false
            ]
        }
        console.log("Sending sub to room messages for:"+roomId)
        ChatSocketHelper.ChatSocketObj.send(JSON.stringify(subscribeRequest));
    }

    //get all the messages from the room
    getMessagesFromOneRoom(roomId){
        const getHistoryRequest = {
            "msg": "method",
            "method": "loadHistory",
            "id": "history-request",
            "params":[
                roomId,
                null,
                50
            ]
        }
        console.log("Getting history of messages for:"+roomId)
        ChatSocketHelper.ChatSocketObj.send(JSON.stringify(getHistoryRequest));
    } 


    //call to subscribe to a list of rooms
    subscribeToRooms(roomIdList){
        roomIdList.forEach( (eachRoomId) => {
            this.subscribeToOneRoomMessages(eachRoomId.roomId)
            this.getMessagesFromOneRoom(eachRoomId.roomId)
            }
        )
    }
    

    sendMessageToRoom(roomId,msg,username){
        const request={
            "msg": "method",
            "method": "sendMessage",
            "id": "send-message-"+username+"-room-"+roomId,
            "params": 
            [
               {
                 "_id": this.create_UUID(),
                 "rid": roomId,
                 "msg": msg
               }
            ]
         }
         console.log("Sending Message To room:"+roomId)
         ChatSocketHelper.ChatSocketObj.send(JSON.stringify(request));
    }

    /**************************/
    /*  Useful functions      */
    /**************************/
    create_UUID(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }


}

export default new ChatSocketHelper()