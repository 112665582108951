import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Icon } from '../../../assets/Buttons'
import { Paragraph } from '../../../assets/Text'

/**
 * Pass the coping cards object to render the list of coping cards collected
 * usage:
    const copingCardsObj = {
        q1: {
            key: "q1",
            aKey: "q1a4",
            answer: "your answer",
            question: "your question"
        }
    }
    <CollectedCards inputObj={copingCardsObj} />
 */
const CollectedCards = props => {
    // console.log(props.inputObj)
    if(props.inputObj === null || props.inputObj === undefined || typeof props.inputObj !== 'object') return null
    let cardUnits = []
    Object.keys(props.inputObj).map((k, i) => {
        cardUnits.push(<Grid key={`collected-card-unit-${i}`} item xs={12} sm={6}>
            <CopingCardUnit question={props.inputObj[k]['question']} answer={props.inputObj[k]['answer']} />
        </Grid>)
    })
    return (<Grid container spacing={2}>
        {cardUnits}
    </Grid>)
}

const CopingCardUnit = props => {
    return (<Grid container className='coping-card-unit'>
        <Grid item className='left-unit' xs={6}>
            <Paragraph>
            <Icon className='fas fa-heart-broken' />
            </Paragraph>
            <Paragraph>
            {props.question}
            </Paragraph>
        </Grid>
        <Grid item className='right-unit' xs={6}>
            <Paragraph>
            <Icon className='fas fa-heart' />
            </Paragraph>
            
            <Paragraph>
            {props.answer}
            </Paragraph>
        </Grid>
    </Grid>)
}

export default CollectedCards