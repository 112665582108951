import React, { Component, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
// import PH from '../../../helpers/ProfileHelper'
import GH from '../../../helpers/GeneralHelper'
import SH from '../../../helpers/SurveyHelper'
import STM from '../../../helpers/TimeManage'
import { Tip, Paragraph, Text } from '../../../assets/Text'
// import { TextInput } from '../../assets/Form'
import { Button, Icon } from '../../../assets/Buttons'
import { Divider, Alert } from '../../../assets/UIElements'
import { RadioSet, Select, FormGroup, FormTextInput, DatePicker } from '../../../assets/Form'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CompassProfilePhoto } from '../../../assets/SVGIcons'
import initProps from '../../../config/init'

const targetSurvey = initProps.survey.SCFeedback
const SCFeedback = SH.getQuestionnaire(targetSurvey)
const answerKeys = `${targetSurvey}Answers`

const m = initProps.modules.connecting
const connectingComponents = initProps[m]['components']
const targetComponent = connectingComponents.rehearseWhatToSay

const panelObj = initProps.connectingModule.identifyCompanions.panelObj
const panels = Object.keys(panelObj)
const prompts = initProps.connectingModule.identifyCompanions.conversationPrompts

class RehearseWhatToSay extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            selectedContact: '',
            selectedType: '',
            // contactSurveys: []
        }

        this.initNewSession = this.initNewSession.bind(this)
        this.selectContact = this.selectContact.bind(this)
    }

    // async componentWillMount()
    // {
        // console.log(this.props)
        // const allContacts = await PH.getAllContacts(this.props.user.id, 'all')
        // let load = await this.props.manageSCContacts('load', allContacts)
    // }

    initNewSession()
    {
        let newProgress = {...this.props.progress}
        newProgress[m][targetComponent] = {}
        newProgress[m][targetComponent]['details'] = {}
        // this.props.updateCurrentStep(newProgress, this.props.user.id)
        this.props.updateCurrentStep(newProgress)

    }

    async selectContact(contactKey = '', type = '')
    {
        if(!contactKey || contactKey === '' || !type || type === '') return false
        // console.log(this.props.socialCompassContacts[type][contactKey])
        if(this.props.socialCompassContacts[type][contactKey].hasOwnProperty('surveys'))
        {
            const surveys = this.props.socialCompassContacts[type][contactKey]['surveys'] || []
            const latestSurvey = surveys.length > 0 ? surveys[surveys.length-1] : []
            // console.log(this.props.socialCompassContacts[type][contactKey]['surveys'])
            this.props.loadQuestionnaireAnswer(initProps.survey.SCFeedback, latestSurvey)
        }
        else
        {
            this.props.clearAnswer(initProps.survey.SCFeedback)
        }
        
        return this.setState({
            selectedContact: contactKey,
            selectedType: type
        })
    }

    render()
    {
        // console.log(this.props.progress)
        if(!this.props.progress.hasOwnProperty(m) || !this.props.progress[m].hasOwnProperty(targetComponent)
            || !this.props.progress[m][targetComponent].hasOwnProperty('details') 
        )
        {
            this.initNewSession()
        }

        return(<Grid>
            <Intro lang={this.props.lang.rehearseWhatToSay} />

            {this.props.progress.hasOwnProperty(m) && this.props.progress[m].hasOwnProperty(targetComponent)
                && this.props.progress[m][targetComponent].hasOwnProperty('details') 
                && this.props.progress[m][targetComponent]['details'].hasOwnProperty('completed')
                && this.props.progress[m][targetComponent]['details']['completed'] ?
                <Alert>
                    <Paragraph className='p-0'>Congratulation on completing the Rehearse What to Say component. You can review your result below.</Paragraph>
                </Alert>
                :
                <Button className='fyw-primary-btn mx-auto' onClick={() => {
                    this.props.switchComponent(m, connectingComponents.rehearseWhatToSay, connectingComponents.rehearseWhatToSay, { completed: true })
                }}>{this.props.lang.rehearseWhatToSay.completed}</Button>
            }

            <Divider />
            <ContactList 
                contacts={this.props.socialCompassContacts}
                selectedType={this.state.selectedType}
                selectedContact={this.state.selectedContact}
                selectContact={this.selectContact}
                questionComponent={<QuestionnairePanel 
                    {...this.props} 
                    selectedType={this.state.selectedType}
                    selectedContact={this.state.selectedContact}
                />}
            />
        </Grid>)
    }
}

const Intro = props => {
    let lists = [], items = props.lang.tips.split('<br/>')
    items.forEach((item, i) => {
        lists.push(<li key={`rehearse-tip-${i}`}>{item}</li>)
    })
    // console.log(items)
    return (<Grid>

        <Paragraph className='fyw-font-dark fyw-font-bold text-center'>{props.lang.title}</Paragraph>
        <Divider />

        <Paragraph className='fyw-font-dark'>
            {props.lang.firstP}
        </Paragraph>

        <Paragraph className='fyw-font-dark'>
            {props.lang.secondP}
        </Paragraph>
        <ul className='ml-4 fyw-font-dark'>
        {lists}
        </ul>     

    </Grid>)
}
const first = SCFeedback.questions.slice(0,2)
const second = SCFeedback.questions.slice(2, 5)
const third = SCFeedback.questions.slice(5, 8)
// console.log(third)
// const anticipate = {
//     title: SCFeedback.title,
//     description: SCFeedback.description,
//     questions: first
// }
// const postAssess = {
//     title: SCFeedback.title,
//     description: SCFeedback.description,
//     questions: second
// }

class QuestionnairePanel extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            anticipate: false,
            postAssess: false,
            openAnswer: false,
            submissionFeedback: ''
        }
        this.enableStep = this.enableStep.bind(this)
        this.storeQuestionnaire = this.storeQuestionnaire.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.handleDateInput = this.handleDateInput.bind(this)
        this.handleTabSwitch = this.handleTabSwitch.bind(this)
    }

    async enableStep(step = 'anticipate')
    {
        // console.log('anticipate')
        // console.log(this.props.selectedContact)
        // console.log(this.props.selectedType)
        const hash = await GH.hash(10)
        switch(step)
        {
            case 'anticipate':
                let answers = {
                    submission_hash: hash,
                    contact_person_key: this.props.selectedContact
                }
                try
                {
                    // const generateNewSurvey = await SH.storeSurveyAnswers(this.props.user.id, initProps.survey.SCFeedback, answers)
                    // console.log(generateNewSurvey)
                    // if(generateNewSurvey.success && generateNewSurvey.data)
                    // {
                        // update the reducer
                        let newContacts = {...this.props.socialCompassContacts}

                        // console.log(newContacts)
                        // console.log(newContacts[this.props.selectedType])

                        // console.log(newContacts[this.props.selectedType][this.props.selectedContact])

                        // console.log(newContacts[this.props.selectedType][this.props.selectedContact]['surveys'])
                        newContacts[this.props.selectedType][this.props.selectedContact]['surveys'] = []
                        newContacts[this.props.selectedType][this.props.selectedContact]['surveys'].push(answers)
                        let load = await this.props.manageSCContacts('load', newContacts)
                        return this.props.addSystemFeedback(`New conversation feedback initiated. Good luck!`)
                    // }
                    // throw generateNewSurvey
                }
                catch(e)
                {
                    console.log(e)
                    return this.props.addSystemFeedback(`Could not initiate the new conversation feedback. Please try again later.`)
                    //TODO: add error record
                }
                break
        }

    }

    async storeQuestionnaire(e, qid, submissionHash)
    {
        e.preventDefault()

        try
        {
            const answerKey = qid + "Answers"
            let answers = this.props[answerKey]

            answers['contact_person_key'] = this.props.selectedContact
            answers['submission_hash'] = submissionHash
            answers = SH.removeNullValues(answers)
            
            // console.log(answers)

            const storeResult = await SH.updateSurveyAnswers(this.props.user.id, qid, submissionHash, answers)

            if(storeResult.hasOwnProperty('id'))
            {
                // also update the socialCompassContact
                let newContacts = {...this.props.socialCompassContacts}

                newContacts[this.props.selectedType][this.props.selectedContact]['surveys'].push(storeResult)

                let load = await this.props.manageSCContacts('load', newContacts)
                return this.props.addSystemFeedback(`Answer is stored. Please go ahead.`)
            }

            throw storeResult
        }
        catch(e)
        {
            return this.props.addSystemFeedback(`Failed to store the answer. Please try again later.`)
            //TODO: add error record
        }
        
    }

    async scoreQuestionnaire(qid, answers)
    {
        switch(qid)
        {
            case 'PG13':
                const PG13Result = await SH.PG13Analysis(answers)
                return PG13Result ? 'You meet the criteria of PG-13' : 'You do not meet the criteria of PG-13'

            case 'BCI':
                const BCIResult = await SH.BCIAnalysis(answers, true)
                return BCIResult
        }
    }

    handleInput(e, questionnaire = '', qid = '')
    {
        // console.log(`the qid is ${qid} and questionnaire is ${questionnaire}`)
        const value = e.target.value
        const current = STM.getCurrentUTCTimestamp()
        const survey = SH.getQuestionnaire(questionnaire)
        const answerDisplay = SH.getAnswerGivenValue(survey, qid, value)
        switch(e.target.type) {
            case 'text':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'radio':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            case 'select-one':
                var newObj = {
                    id: qid,
                    answer: value,
                    created_at: current,
                    answer_text: answerDisplay
                }
                this.props.addAnswer(questionnaire, newObj)
            break
            default:
        }
    }

    handleDateInput(questionnaire, qid = '', value)
    {
        const current = STM.getCurrentUTCTimestamp()
        const date = STM.formatUTCTime(value)
        const newObj = {
            id: qid,
            answer: date,
            created_at: current,
            answer_text: date
        }
        return this.props.addAnswer(questionnaire, newObj)
    }

    handleTabSwitch(e, value)
    {
        this.setState({
            survey: value
        })
    }

    render()
    {
        if(Object.keys(this.props.socialCompassContacts).length <= 0) return null
        if(this.props.selectedType === '' || !this.props.selectedType || this.props.selectedContact === '' 
            || !this.props.selectedContact
            || !this.props.socialCompassContacts.hasOwnProperty(this.props.selectedType) 
            || !this.props.socialCompassContacts[this.props.selectedType].hasOwnProperty(this.props.selectedContact)
        ) return null
        const type = this.props.selectedType
        const contactKey = this.props.selectedContact
        const name = this.props.socialCompassContacts[type][contactKey].name || `your ${panelObj[type].toLowerCase()}`
        const surveys = this.props.socialCompassContacts[type][contactKey].surveys || []
        const latestSurvey = surveys.length > 0 ? surveys[surveys.length-1] : surveys
        // console.log(surveys)
        return(<>
            {/* first part of the survey */}
            {surveys.length <= 0 ? 
            <div className='d-flex justify-content-center row mt-2'>
                <div className='col-sm-12 mx-auto text-center'><Paragraph className='fyw-font-dark fyw-font-bold'>Step 1: Anticipate your conversation</Paragraph></div>
                <Tip className='fyw-font-grey'>Before reaching out to {name}, how do you think it will go?</Tip>
                <div className='col-sm-12'><Button className='fyw-primary-btn' onClick={() => this.enableStep('anticipate')}>Click to start</Button></div>
            </div>
            :
            latestSurvey.hasOwnProperty('SC-01') && latestSurvey.hasOwnProperty('SC-02') && latestSurvey['SC-01'] !== null && latestSurvey['SC-02'] !== null ?
            <div className='d-flex justify-content-center row mt-2'>
                <div className='col-sm-12 mx-auto text-center'><Paragraph className='fyw-font-dark fyw-font-bold'>Step 1: Anticipate your conversation</Paragraph></div>
                <Tip className='fyw-font-grey'>Before reaching out to {name}, how do you think it will go?</Tip>
                <div className='col-sm-12'>
                    <QuestionnaireFeedback survey={JSON.parse(latestSurvey['SC-01'])} />
                    <QuestionnaireFeedback survey={JSON.parse(latestSurvey['SC-02'])} />
                </div>
                {/*<div className='col-sm-12'><Button onClick={() => this.enableStep('anticipate')}>Click to start</Button></div>*/}
            </div>
            :
            <><div className='d-flex justify-content-center row mt-2'>
                <div className='col-sm-12 mx-auto text-center'>
                <Paragraph className='fyw-font-dark fyw-font-bold'>Step 1: Anticipate your conversation</Paragraph>
                <Tip className='fyw-font-grey'>Before reaching out to {name}, how do you think it will go?</Tip>
                </div>
            </div>
            <Questionnaire 
                // key={`target-survey-${this.state.survey}`} 
                title={SCFeedback['title']} 
                description={SCFeedback['description']} 
                items={first} 
                questionnaire={initProps.survey.SCFeedback}
                answers={this.props[answerKeys]}
                handleInput={this.handleInput}
                handleDateInput={this.handleDateInput}
                handleStore={this.storeQuestionnaire}
                feedback={this.state.submissionFeedback || ''}
                survey={latestSurvey}
            />
            </>
            }
            <Divider />
            {/* second part of the survey */}
            {surveys.length <= 0 || latestSurvey['SC-01'] === null || latestSurvey['SC-02'] === null 
            ? null :
            latestSurvey.hasOwnProperty('SC-03') && latestSurvey.hasOwnProperty('SC-04') && latestSurvey['SC-03'] !== null && latestSurvey['SC-04'] !== null 
            && latestSurvey.hasOwnProperty('SC-05') && latestSurvey['SC-05'] !== null ?
            <div className='d-flex justify-content-center row mt-2'>
                <div className='col-sm-12 mx-auto text-center'><Paragraph className='fyw-font-dark fyw-font-bold'>Step 2: Rate your conversation</Paragraph></div>
                <Tip className='fyw-font-grey'>After reaching out to {name}, how did you think it went?</Tip>
                <div className='col-sm-12'>
                    <QuestionnaireFeedback survey={JSON.parse(latestSurvey['SC-03'])} />
                    <QuestionnaireFeedback survey={JSON.parse(latestSurvey['SC-04'])} />
                    <QuestionnaireFeedback survey={JSON.parse(latestSurvey['SC-05'])} />
                </div>
                {/*<div className='col-sm-12'><Button onClick={() => this.enableStep('anticipate')}>Click to start</Button></div>*/}
            </div>
            :
            <><div className='d-flex justify-content-center row mt-2'>
                <div className='col-sm-12 mx-auto text-center'>
                <Paragraph className='fyw-font-dark fyw-font-bold'>Step 2: Rate your conversation</Paragraph>
                <Tip className='fyw-font-grey'>After reaching out to {name}, how did you think it went?</Tip>
                </div>
            </div>
            <Questionnaire 
                // key={`target-survey-${this.state.survey}`} 
                title={SCFeedback['title']} 
                description={SCFeedback['description']} 
                items={second} 
                questionnaire={initProps.survey.SCFeedback} 
                answers={this.props[answerKeys]} 
                handleInput={this.handleInput} 
                handleDateInput={this.handleDateInput} 
                handleStore={this.storeQuestionnaire} 
                feedback={this.state.submissionFeedback || ''} 
                survey={latestSurvey}
            />
            </>}
            {/*this.state.postAssess ? 
            <Questionnaire 
                // key={`target-survey-${this.state.survey}`} 
                title={SCFeedback['title']} 
                description={SCFeedback['description']} 
                items={second} 
                questionnaire={initProps.survey.SCFeedback} 
                answers={this.props[answerKeys]} 
                handleInput={this.handleInput} 
                handleDateInput={this.handleDateInput} 
                handleStore={this.storeQuestionnaire} 
                feedback={this.state.submissionFeedback || ''} 
                survey={latestSurvey}
            />
            :null*/}
            {/*this.state.openAnswer ?
            <Questionnaire 
                // key={`target-survey-${this.state.survey}`} 
                title={SCFeedback['title']} 
                description={SCFeedback['description']} 
                items={third} 
                questionnaire={initProps.survey.SCFeedback} 
                answers={this.props[answerKeys]} 
                handleInput={this.handleInput} 
                handleDateInput={this.handleDateInput} 
                handleStore={this.storeQuestionnaire} 
                feedback={this.state.submissionFeedback || ''}
                survey={latestSurvey}
            />
            :null*/}

        </>)
    }
}

const ContactList = props => {
    // console.log(props)
    const cat = Object.keys(props.contacts)
    const panelCat = []
    cat.forEach((c, i) => {
        const contacts = props.contacts[c]
        if(Object.keys(contacts).length > 0)
        {
            // console.log(panelObj[c])
            panelCat.push(<ContactCategory 
                key={`${c}-${i}`}
                title={panelObj[c]}
                contacts={contacts}
                selectContact={props.selectContact}
            />)
        }
    })

    return(<div className='row mt-4 py-2'>
        <div className='col-sm-4'>
            {!props.selectedContact || props.selectedContact === '' ? 
            <div className='w-100'><Text className='fyw-font-green'>Select a contact to get started</Text></div>
            :null}
            {panelCat}
        </div>

        <div className='col-sm-8'>
            <Paper elevation={6} className='py-4'>
                <ContactUnit {...props} />
            </Paper>
        </div>


    </div>)
}

const ContactCategory = props => {
    // console.log(props)
    const contact = []
    Object.keys(props.contacts).map((k, i) => {
        const targetContact = props.contacts[k]
        contact.push(<div 
            className='col-sm-12 sc-contact-accordion-children py-2' 
            key={`talk-to-${k}-${i}`}
            onClick={() => props.selectContact(targetContact.key, targetContact.type)}
        >
            <Text className='fyw-font-dark'>
                {`Talk to ${targetContact.name}`}
                <Icon className='ml-2 fas fa-check-circle sc-contact-accordion-children-selected' />
            </Text>
        </div>)
    })
    return(<ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className='w-100'
            // aria-controls="panel2a-content"
            // id="panel2a-header"
        >
        <Text className='fyw-font-dark'>{props.title || 'Supporter group'}</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className='w-100'>
            <div className='d-flex row'>
                {contact}
            </div>
        </ExpansionPanelDetails>
    </ExpansionPanel>)
}

const ContactUnit = props => {
    // console.log(props)
    if(!props.selectedContact || props.selectedContact === '') return null
    // if(!props.selectedContact || props.selectedContact === '') return (<div className='row'>
    //     <div className='col-sm-12'><Text className='fyw-font-green'>Select a contact to get started</Text></div>
    // </div>)
    const type = props.selectedType
    const contactKey = props.selectedContact
    const target = props.contacts[type][contactKey]
    // console.log(target.email[0])
    const email = target.hasOwnProperty('email') && target.email !== null && target.email[0] !== undefined ? target.email[0].value : ''
    const phone = target.hasOwnProperty('phone') && target.phone !== null && target.phone[0] !== undefined ? target.phone[0].value : ''
    let promptsArr = []
    prompts[type].forEach((p, i) => {
        promptsArr.push(<div key={`prompts-cont-${type}-${i}`} className='pinched py-2 text-left'><Text key={`prompt-${type}-${i}`} className='fyw-font-dark'>{p}</Text></div>)
    })
    return(<div className='col-sm-12 mt-2'>
        <div className='d-flex'>
            <div className='col-sm-2 py-4'><CompassProfilePhoto className='' style={{ maxHeight: '100px', color: initProps.css.colors.midGrey }} /></div>

            <div className='col-sm-10'>
                <Paragraph className='fyw-font-dark'><span className='fyw-font-bold'>Name (nickname): </span>{target.name}</Paragraph>
                <Paragraph className='fyw-font-dark'><span className='fyw-font-bold'>Email: </span>{email}</Paragraph>
                <Paragraph className='fyw-font-dark'><span className='fyw-font-bold'>Phone: </span>{phone}</Paragraph>
                <Text className='fyw-font-bold fyw-font-white'><span className='p-2' style={{ backgroundColor: initProps.connectingModule.identifyCompanions.companionColor[type] }}>{panelObj[type]}</span></Text>

            </div>
        </div>

        <div className='col-sm-12 my-2 sc-reason-block mx-auto'>
            <Paragraph className='fyw-font-dark'>{`What makes ${target.name} a ${panelObj[type].toLowerCase()}: ${target.reason}`}</Paragraph>
        </div>
        <Divider />

        <div className='col-sm-12'>
            <Text className='fyw-font-dark'>{`When approaching a ‘`}<span className='fyw-font-dark fyw-font-bold'>{panelObj[type]}</span>{`’ try starting the conversation along these lines…`}</Text>
            {promptsArr}
        </div>
        <Divider />

        {props.questionComponent}
    </div>)
}

const Questionnaire = props => {
    // console.log(props)
    // console.log('questionnaire rendered')
    let renderArr = []
    const [loading, setLoading] = useState(false)

    if(props.answers === undefined) return null

    const submissionHash = props.survey.submission_hash || ''
    props.items.forEach((item, idx) => {
        switch(item.type)
        {
            case 'radio-inline':
                return renderArr.push(<FormGroup className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} key={`${idx}-${item.name}`}>
                <RadioSet 
                    key={`${item.id}-${item.name}-${idx}`}
                    id={item.id}
                    qid={item.id}
                    value={item.value}
                    title={item.title}
                    display={item.display}
                    checked={false}
                    name={item.name}
                    inline={true}
                    options={item.options}
                    handleInput={props.handleInput}
                    inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                    questionnaire={props.questionnaire}
                />
                </FormGroup>)
            case 'radio':
                return renderArr.push(<FormGroup className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} key={`${idx}-${item.name}`}>
                    <RadioSet 
                        key={`${item.id}-${item.name}-${idx}`}
                        id={item.id}
                        qid={item.id}
                        value={item.value}
                        title={item.title}
                        display={item.display}
                        checked={false}
                        name={item.name}
                        options={item.options}
                        handleInput={props.handleInput}
                        inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                        questionnaire={props.questionnaire}
                    />
                </FormGroup>)
            case 'select':
                return renderArr.push(<FormGroup className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} key={`${idx}-${item.name}`}>
                    <Select 
                        key={`${item.id}-${item.name}-${idx}`}
                        options={item.options}
                        id={item.id}
                        qid={item.id}
                        title={item.title}
                        name={item.name}
                        handleInput={props.handleInput}
                        inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                        questionnaire={props.questionnaire}
                    />
                </FormGroup>)
            case 'input':
                return renderArr.push(<FormGroup className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} key={`${idx}-${item.name}`}>
                <FormTextInput 
                    key={`${item.id}-${item.name}-${idx}`}
                    options={item.options}
                    id={item.id}
                    qid={item.id}
                    title={item.title}
                    name={item.name}
                    handleInput={props.handleInput}
                    label={item.display}
                    placeholder={item.display}
                    inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                    questionnaire={props.questionnaire}
                />
            </FormGroup>)
            case 'datepicker':
                return renderArr.push(<FormGroup className={props.answers.hasOwnProperty(item.id) ? 'my-5 fyw-form-selected' : 'my-5'} key={`${idx}-${item.name}`}>
                <DatePicker 
                    key={`${item.id}-${item.name}-${idx}`}
                    options={item.options}
                    id={item.id}
                    qid={item.id}
                    title={item.title}
                    name={item.name}
                    handleInput={props.handleDateInput}
                    inputValue={props.answers.hasOwnProperty(item.id) && props.answers[item.id] !== null ? props.answers[item.id]['answer'] : undefined}
                    questionnaire={props.questionnaire}
                />
            </FormGroup>)
        }
    })
    // console.log("render questionnaire")
    return(<div className="mb-5">
        {/*<div className="row text-center m-auto">
            <Text className='mx-auto mt-5'>{props.title.toUpperCase()}</Text>
        </div>
        <Divider />*/}
        {/*<Paragraph className="fyw-font-dark mx-auto text-left" style={{width: '80%'}}>
            {props.description}
        </Paragraph>*/}
        <form>
            {renderArr}
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 secondary-btn-container mx-auto d-flex justify-content-center align-items-center'>
                <Button className="btn btn-lg btn-block fyw-primary-btn fyw-btn-dark" type="submit" onClick={(e) => {
                    props.handleStore(e, props.questionnaire, submissionHash)
                    setLoading(true)
                }}>Save</Button>
                {loading && !props.feedback ? <CircularProgress size={24} className="btn-circular-loader" /> : null}
            </div>
            {props.feedback ? <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto d-flex justify-content-center align-items-center'><Text>{props.feedback}</Text></div> : null}
        </form>
    </div>)
}

const QuestionnaireFeedback = props => {
    // console.log(props)
    // console.log(props.survey.id)
    if(!props.hasOwnProperty('survey') || !props.survey.id || !props.survey.answer) return null
    // console.log('passed test')
    const id = props.survey.id
    const value = parseInt(props.survey.answer,10)
    // console.log(`the value is ${value}`)
    const question = SH.getQuestionGivenQid(initProps.survey.SCFeedback, id)
    const answer = SH.getAnswerGivenValue(initProps.survey.SCFeedback, id, value)
    // console.log(SH.getQuestionGivenQid(initProps.survey.SCFeedback, id))
    // console.log(SH.getAnswerGivenValue(initProps.survey.SCFeedback, id, value))
    // const q = 'Q1: '
    // const a = 'A1'

    return <Text className='fyw-font-dark'><span className='fyw-font-bold mr-2'>{question.title}</span>{answer}</Text>
}

export default RehearseWhatToSay