import GH from '../helpers/GeneralHelper'
import paths from '../config/paths'

const init = {
    serverUrl: 'https://firebasestorage.googleapis.com/v0/b/matchp2s.appspot.com/o/img%2F',//'127.0.0.1',
    authState: {
        signIn: 'signIn',
        signUp: 'signUp',
        confirmSignIn: 'confirmSignIn',
        confirmSignUp: 'confirmSignUp',
        forgotPassword: 'forgotPassword',
        requireNewPassword: 'requireNewPassword',
        verifyContact: 'verifyContact',
        signedIn: 'signedIn',
        signedUp: 'signedUp'
    },
    navItem: {
        index: {
            langKey: 'index',
            url: paths.index,
            icon: 'fas fa-home'
        },
        pg13: {
            langKey: 'pg13',
            url: paths.pg13,
            icon: 'fas fa-notes-medical'
        },
        bci: {
            langKey: 'bci',
            url: paths.bci,
            icon: 'fas fa-notes-medical'
        },
        matchmaking: {
            langKey: 'matchmaking',
            url: paths.connecting,
            icon: 'fas fa-comment'
        },
        connectModule: {
            langKey: 'connectModule',
            url: paths.coping,
            icon: 'fas fa-people-arrows'
        },
        resources: {
            langKey: 'resources',
            url: paths.resources,
            icon: 'fas fa-medkit'
        },
        // contact: {
        //     langKey: 'contact',
        //     url: paths.contact,
        //     icon: 'fas fa-envelope-open-text'
        // }
    },
    imgSlider: {
        'yarning': {
            srcSet: {
                sm: 'yearning-sm.png',
                md: 'yearning-md.png',
                lg: 'yearning-lg.png',
                xl: 'yearning-xl.png'
            },
            alt: 'yearning'
        },
        'detachment': {
            srcSet: {
                sm: 'detachment-sm.png',
                md: 'detachment-md.png',
                lg: 'detachment-lg.png',
                xl: 'detachment-xl.png'
            },
            alt: 'feeling of detachment'
        },
        "meaning": {
            srcSet: {
                sm: 'meaning-sm.png',
                md: 'meaning-md.png',
                lg: 'meaning-lg.png',
                xl: 'meaning-xl.png'
            },
            alt: 'loss of meaning and purpose'
        },
        'numbness': {
            srcSet: {
                sm: 'numbness-sm.png',
                md: 'numbness-md.png',
                lg: 'numbness-lg.png',
                xl: 'numbness-xl.png'
            },
            alt: 'emotional numbness'
        },
        'stuck': {
            srcSet: {
                sm: 'stuck-sm.png',
                md: 'stuck-md.png',
                lg: 'stuck-lg.png',
                xl: 'stuck-xl.png'
            },
            alt: 'feeling stuck in grief'
        }
    },
    lang: {
        en: 'en',
        zh: 'zh'
    },
    survey: {
        PG13: 'PG13',
        BCI: 'BCI',
        Demo: 'Demo',
        SCFeedback: 'SCFeedback'
    },
    questionnaireName: {
        PG13: 'Grief Intensity Scale',
        BCI: 'Bereavement Challenges Inventory',
        Demo: 'Demographic information',
        SCFeedback: 'Social Compass Conversation Feedback'
    },
    griefResources: {
        online: [
            {
                title: 'MedlinePlus',
                value: 'Provides introduction to bereavement and its symptoms',
                url: 'https://medlineplus.gov/bereavement.html'
            },
            {
                title: 'Mantal Health America',
                value: 'Coping with Loss: Bereavement and Grief',
                url: 'http://www.mentalhealthamerica.net/conditions/coping-loss-bereavement-and-grief'
            },
            {
                title: 'National Cancer Institute',
                value: 'Grief, Bereavement, and Coping With Loss (PDQ®)–Patient Version',
                url: 'https://www.cancer.gov/about-cancer/advanced-cancer/caregivers/planning/bereavement-pdq '
            },
            {
                title: 'American Cancer Society',
                value: 'Coping With the Loss of a Loved One',
                url: 'http://www.cancer.org/docroot/MBC/MBC_4x_CopingGrief.asp?sitearea=MBC '
            },
            {
                title: 'HelpGuide',
                value: 'Grief & Loss',
                url: 'https://www.helpguide.org/home-pages/grief.htm '
            },
            {
                title: 'Center for Young Women\'s Health',
                value: 'Coping with Loss',
                url: 'https://youngwomenshealth.org/2013/06/10/coping-with-loss/ '
            },
            {
                title: 'Joy of Hearts',
                value: 'An online healing place for anyone grieving a loss',
                url: 'http://journeyofhearts.org/ '
            },
            {
                title: 'The California Maritime Academy',
                value: 'How to Cope with Loss, Grief, Death & Dying - Professionally & Personally',
                url: 'http://www.journeyofhearts.org/jofh/about/way_cope'
            },
            {
                title: 'AARP',
                value: 'Grief & Loss for caregiving',
                url: 'http://www.aarp.org/home-family/caregiving/grief-and-loss/ '
            },
                    {
                title: 'HelpGuide',
                value: 'Grief & Loss',
                url: 'https://www.helpguide.org/home-pages/grief.htm '
            },
            {
                title: 'Center for Young Women\'s Health',
                value: 'Coping with Loss',
                url: 'https://youngwomenshealth.org/2013/06/10/coping-with-loss/ '
            },
            {
                title: 'Joy of Hearts',
                value: 'An online healing place for anyone grieving a loss',
                url: 'http://journeyofhearts.org/ '
            },
            {
                title: 'The California Maritime Academy',
                value: 'How to Cope with Loss, Grief, Death & Dying - Professionally & Personally',
                url: 'http://www.journeyofhearts.org/jofh/about/way_cope'
            },
            {
                title: 'AARP',
                value: 'Grief & Loss for caregiving',
                url: 'http://www.aarp.org/home-family/caregiving/grief-and-loss/ '
            },
            {
                title: 'What\'s your grief',
                value: 'A list of paid grief courses',
                url: 'https://whatsyourgrief.com/school/ '
            },
            {
                title: 'Psychology Today',
                value: 'The grief support groups around New York',
                url: 'https://groups.psychologytoday.com/rms/prof_results.php?state=NY&spec=14'
            },
            {
                title: 'Memorial Sloan Kettering Cancer Center',
                value: 'Counseling & Support',
                url: 'https://www.mskcc.org/experience/patient-support/counseling'
            },
            {
                title: 'Grief Share',
                value: 'Find a grief group near you',
                url: 'https://www.griefshare.org/ '
            }
        ],
        covid19: [
            {
                title: 'Good-Grief.org COVID-19 resources',
                value: '',
                url: 'https://good-grief.org/covid-19/'
            },
            {
                title: 'Substance Abuse and Mental Health Service Administration national helpline',
                value: '',
                url: 'https://www.samhsa.gov/find-help/national-helpline'
            },
            {
                title: 'Compassionate Friends',
                value: '',
                url: 'https://www.compassionatefriends.org/ '
            },
            {
                title: 'Grief In Common support groups',
                value: '',
                url: 'https://www.griefshare.org/ '
            },
            {
                title: 'Grief Share support groups',
                value: '',
                url: 'https://www.griefshare.org/'
            }
        ],
        hotline: [
            {
                title: 'National Suicide Prevention Lifeline Phone Number',
                value: 'You can always talk to someone by calling the Lifeline number, 1-800-273-8255 (TALK).'
            }
        ]
    },
    modules: {
        connecting: 'connectingModule'
    },
    connectingModule: {
        components: {
            intenseFeelings: 'intenseFeelings',
            copingCards: 'copingCards',
            identifyCompanions: 'identifyCompanions',
            rehearseWhatToSay: 'rehearseWhatToSay'
        },
        identifyCompanions: {
            panelObj: {
                'companion-panel': 'Companion',
                'listener-panel': 'Listener',
                'validator-panel': 'Validator',
                'practical-supporter-panel': 'Practical supporter',
                'problem-solver-panel': 'Problem solver',
                'cheerleaders-panel': 'Cheerleader'
            },
            companionColor: {
                'companion-panel': '#F25A3D',
                'listener-panel': '#FFA014',
                'validator-panel': '#6E3054',
                'practical-supporter-panel': '#FF7040',
                'problem-solver-panel': '#DE3E59',
                'cheerleaders-panel': '#9C3A68'
            },
            conversationPrompts: {
                'companion-panel': [
                    'I was wondering if you wanted to come with me to this [specific event] at/on [specific date and/or time]. It is always so nice to do things and spend time with you.'
                ],
                'listener-panel': [
                    'I was wondering if you had some time to chat [suggest date and/or time]. I just need to vent, and you’re so good at just listening and not trying to fix things.'
                ],
                'validator-panel': [
                    'I was wondering if you had some time to chat [suggest date and/or time]. I just need someone to tell me that I’m not crazy, that what I’m feeling is normal, and you’re always so good at telling me that what I feel makes sense.'
                ],
                'practical-supporter-panel': [
                    'I was wondering if you might be able to come with me to [specific event/appointment]. It is hard to go alone and you are so good at [specific ability e.g. communicating with doctors].',
                    'I was wondering if you might be able to help me with [specific task]. It’s hard to do by myself and you’re so good at [X].'
                ],
                'problem-solver-panel': [
                    'I was wondering if you had some time to chat [suggest date and/or time]. I could really use some help figuring out [x] problem, and you’re always so good at problem-solving.'
                ],
                'cheerleaders-panel': [
                    'I was wondering if you had some time to chat [suggest date and/or time]. I’m feeling like I need some encouragement, and you’re always so good at supporting me.'
                ]
            }
        }
    },
    db: {
        error: 'error',
        copingCards: 'coping_cards',
        BCI: 'BCI',
        PG13: 'PG13',
        profile: 'profile',
        user: 'user',
        socialCompassContactPerson: 'social_compass_contact_person',
        socialCompassContactWay: 'social_compass_contact_way',
        SCFeedback: 'SCFeedback',
        subscriber: 'subscriber'
    },
    css: {
        colors: {
            darkBlue: "#31605b",
            darkGrey: "#36454F",
            white: "#FFFFFF",
            yellow: "#ffc107",
            lightGrey: "#efefef",
            midGrey: '#6A6C6',
            midGreen: '#5d7437'
        },
        font: {
            title: 'Asap, sans-serif',
            subtitle: 'Asap, sans-serif',
            paragraph: 'Asap, sans-serif',
            tip: 'Asap, sans-serif'
        }
    },

    error: {
        UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        DEBUG_ERROR: 'DEBUG_ERROR',
        AWS_FAILED_TO_UPLOAD_IMAGE: 'AWS_FAILED_TO_UPLOAD_IMAGE',
        FAILED_TO_CONVERT_HEIC: 'FAILED_TO_CONVERT_HEIC'
    }
}

export default init