import { connect } from 'react-redux'
// import withAuth from './withAuth'
import Register from '../ui/Register'
import { withRouter } from "react-router"
import { switchPage } from '../../actions/match'
import initLang from '../../lang/initLang'

const mapStateToProps = state => ({
    lang: initLang[state.langReducer.initLang]['matchmaking'],
    page: state.matchReducer.page,
    profile: state.matchReducer.profile
})

const mapDispatchToProps = dispatch => ({
    switchPage: page => dispatch(switchPage(page))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(Register)
// export default withAuth(withRouter(Container))
export default withRouter(Container)