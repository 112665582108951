import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Login from './Auth/Login'
import Register from './Auth/Register'
import ForgotPass from './Auth/ForgotPass'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import UserProfileHelper from '../../helpers/UserProfileHelper'
import matchConfig from '../../config/matchConfig'

// import testAPI from '../../tester/apiTester'

class Auth extends Component {
    
    constructor(props)
    {
        super(props)
        // this.state = {
        //     login: this.props.page === matchConfig.pages.login
        // }
        this.switchToLogin = this.switchToLogin.bind(this)
        this.login = this.login.bind(this)
    }

    async componentDidMount() {
        const loggedIn = await AuthenticationHelper.hasAuthenticated()
        if(!loggedIn) this.props.switchPage(matchConfig.pages.login)
        
        //updates to lavender May 5
        /*
        to see if the user is in setpassword mode, added a roles table.
        After loggin, getRoleById(id) will return a role. 
        role =0 is inactive, role=1 is active, role=2 is setpassword
        see sample in runTestIfUserIsResetingPassword()
        */

        //run user test suite 
        // await testAPI.runUserProfileTestSuite()
        //await testAPI.runInteractionProfileTestSuite()
        //await testAPI.runSendResetPasswordEmailTest("spanote@gmail.com") 
        //await testAPI.runResetPasswordEmailTest("spanote@gmail.com","tester") 
        //await testAPI.runTestIfUserIsResetingPassword("spanote@gmail.com","1uJ93bEVk11") 
   
    }
    
    switchToLogin(login = false)
    {
        const page = login ? matchConfig.pages.login : matchConfig.pages.register
        this.props.switchPage(page)
    }

    async login(email = '', password = '')
    {
        try
        {
            const result = await AuthenticationHelper.login(email, password)
            // console.log(result)
            if(result)
            {
                await this.props.updateProfile({
                    uuid: AuthenticationHelper.uuid
                })
                return this.props.switchPage(matchConfig.pages.profile)
            }
            throw false
        }
        catch(e)
        {
            return false
        }
    }

    render()
    {
        if(this.props.page === matchConfig.pages.login)
        {
            return (<Grid>
                <Login lang={this.props.lang} switchToLogin={this.switchToLogin} login={this.login} switchToForgotPass={() => this.props.switchPage(matchConfig.pages.forgotPass)} />
            </Grid>)
        }
        else if (this.props.page === matchConfig.pages.register)
        {
            return <Grid>
                <Register lang={this.props.lang} switchToLogin={this.switchToLogin} />
            </Grid>
        }
        else if (this.props.page === matchConfig.pages.forgotPass)
        {
            return <Grid>
                <ForgotPass lang={this.props.lang} switchToLogin={this.switchToLogin} />
            </Grid>
        }
        return null
    }
}

export default Auth