import React, { useState } from 'react'
import GetImg from './GetImg'

const Button = (props) => {
    return(
        <button className={props.className ? `fyw-button px-4 ${props.className}` : `fyw-button px-4`} type={props.type || 'button'} onClick={props.onClick ? (e) => props.onClick(e) : null} style={props.style || {}}>{props.children}</button>
    )
}

const Icon = (props) => {
    return (
        <i {...props} ></i>
    )
}

const IconButton = (props) => {
    return (
        <button className={props.className ? `fyw-button ${props.className}`:`fyw-button`} type={props.type} onClick={(e) => props.onClick(e)} style={props.style || null}>
            <i className={props.btnClassName ? `fyw-button-icon ${props.btnClassName}` : `fyw-button-icon`}></i>
            {props.children}
        </button>
    )
}


//<ImageButton onClick={() => props.switchPage(matchConfig.pages.profile)} className='w-100' src={'profile-icon.svg'} activeSrc={'profile-icon-active.svg'}>MY BOARD</ImageButton>
const ImageButton = (props) => {
    let [active, updateActive] = useState(false)
    if(!props.landscape)
    {
        return (
            <button onMouseEnter={() => updateActive(true)} onMouseLeave={() => updateActive(false)} className={props.className ? `fyw-imagebutton ${props.className}`:`fyw-imagebutton`} type={props.type} onClick={(e) => props.onClick(e)} style={props.style}>
                <GetImg
                    className={props.imgClassName ? ['fyw-imagebutton-image', 'fyw-image-icon', 'mb-1', ...props.imgClassName]:['fyw-imagebutton-image', 'fyw-image-icon', 'mb-1']}
                    onClick={null}
                    imageUrl={props.activeSrc ? active || props.active ? props.activeSrc : props.src : props.src} // "name-of-the-img.jpg"
                    srcSet={[]}
                    alt={props.alt}
                />
                {props.children}
            </button>
        )
    }
    return (
        <button className={props.className ? `fyw-imagebutton-landscape ${props.className}`:`fyw-imagebutton-landscape`} type={props.type} onClick={(e) => props.onClick(e)} style={props.style}>
            <GetImg
                className={props.imgClassName ? ['fyw-imagebutton-icon', 'mb-1', ...props.imgClassName]:['fyw-imagebutton-icon', 'mb-1']}
                onClick={null}
                imageUrl={props.activeSrc ? active || props.active ? props.activeSrc : props.src : props.src} // "name-of-the-img.jpg"
                srcSet={[]}
                alt={props.alt}
            />
            {props.children}
        </button>
    )
}

export { Button, Icon, IconButton, ImageButton }